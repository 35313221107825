import React, { useState, useEffect, useContext } from "react";
import {
  Text,
  View,
  StyleSheet,
  Button,
  ActivityIndicator,
} from "react-native";
import { Box } from "native-base";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";

import { BarCodeScanner } from "expo-barcode-scanner";
import * as ScreenOrientation from "expo-screen-orientation";
import {
  BarCodeButton,
  BarCodeButtonText,
  BarCodeScannerContainer,
  BarCodeScannerFooter,
  BarCodeScannerHeader,
  BarCodeScannerHeaderText,
} from "./styles";
import {
  PAYMENT_BARCODE_DATA,
  PAYMENT_BARCODE_MANUAL,
} from "../../constants/routes";
import { GlobalContext } from "../../contexts/Provider";
import { ContainerBaseCenter } from "../../styles/default";

const BarcodeScanner = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [loading, setLoading] = useState(true);
  const { paymentBarcode, setPaymentBarcode } = useContext(GlobalContext);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => initScanner());

    return () => {
      unsubscribe();
      resetScreenOrientation();
      setScanned(false);
    };
  }, []);

  const initScanner = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
    await changeScreenOrientation();
    setLoading(false);
  };

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    resetScreenOrientation();
    setPaymentBarcode(data);
    setScanned(false);
    navigate(PAYMENT_BARCODE_DATA);
  };

  const changeScreenOrientation = async () => {
    await ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT
    );
    return new Promise((resolve) => setTimeout(resolve, 500));
  };

  const resetScreenOrientation = async () => {
    await ScreenOrientation.unlockAsync();
  };

  const barcodeStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    borderWidth: 5,
    borderColor: "blue",
  };

  if (hasPermission === null) {
    return (
      <ContainerBaseCenter>
        <Text style={{ flex: 1, textAlign: "center" }}>
          Solicitando permissão de câmera ...
        </Text>
      </ContainerBaseCenter>
    );
  }
  if (hasPermission === false) {
    return (
      <ContainerBaseCenter>
        <Text style={{ flex: 1, textAlign: "center" }}>
          Sem acesso à câmera ...
        </Text>
      </ContainerBaseCenter>
    );
  }

  return loading ? (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="large" color="#000" />
    </View>
  ) : (
    <Box style={{ flex: 1, backgroundColor: "black" }} safeAreaBottom>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={barcodeStyle}
      />

      <BarCodeScannerContainer>
        <BarCodeScannerHeader>
          <ButtonBack
            onPress={() => {
              resetScreenOrientation();
              navigation.goBack();
            }}
            color="white"
            size={24}
          />
          <BarCodeScannerHeaderText>
            Escaneie o código de barra do boleto
          </BarCodeScannerHeaderText>
        </BarCodeScannerHeader>
        <View style={{ flex: 1 }}></View>
        <BarCodeScannerFooter>
          <BarCodeButton
            onPress={() => {
              resetScreenOrientation();
              navigate(PAYMENT_BARCODE_MANUAL);
            }}
          >
            <BarCodeButtonText>INSERIR CÓDIGO DO BOLETO</BarCodeButtonText>
          </BarCodeButton>
        </BarCodeScannerFooter>
      </BarCodeScannerContainer>
    </Box>
  );
};

export default BarcodeScanner;
