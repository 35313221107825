import {
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  BREAKPOINT_XXL,
} from "../constants/breakpoints";

export const getBreakpointWidth = (breakpoint) => {
  switch (breakpoint) {
    case "sm":
      return BREAKPOINT_SM;
    case "md":
      return BREAKPOINT_MD;
    case "lg":
      return BREAKPOINT_LG;
    case "xl":
      return BREAKPOINT_XL;
    case "XXL":
      return BREAKPOINT_XXL;
    default:
      return 0;
  }
};

export const breakpointUp = (size, breakpoint) => {
  return size >= getBreakpointWidth(breakpoint);
};

export const breakpointDown = (size, breakpoint) => {
  return size < getBreakpointWidth(breakpoint);
};
