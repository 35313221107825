import React, { useState, useContext, useRef, useEffect } from "react";
import { ModalBase } from "./modals";
import { ButtonBase } from "./buttons";
import { TextInputBase } from "./inputs";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import { useToast, View } from "native-base";
import { secondsToTime } from "../helpers/format";
import {
  TRANSACTION_FORGOT_PASSWORD,
  TRANSACTION_SUCCESS,
} from "../constants/routes";
import axiosInstance from "../helpers/axiosInstance";
import { TextPage } from "../styles/typograph";
import { GlobalContext } from "../contexts/Provider";

const TransactionPassword = ({
  modal,
  setModal,
  transaction,
  transactionData,
}) => {
  const { navigate } = useNavigation();
  const [invalid, setInvalid] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasPassword, setHasPassword] = useState(true);
  const [loading, setLoading] = useState(true);
  const transactionCode = useRef("");
  const [smsValidation, setSmsValidation] = useState(false);
  const [code, setCode] = useState("");
  const initTransactionSmsRef = useRef();
  const [resend, setResend] = useState(false);
  const [counterLocal, setCounterLocal] = useState(60);
  const isMounted = useRef(true);
  const toast = useToast();

  const timeToWait = 60;
  const {
    authState: { authData },
    transactionSmsCounter,
    resetTransactionSmsCounter,
    initTransactionSmsCounter,
  } = useContext(GlobalContext);

  useEffect(() => {
    checkPassword();

    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (modal) {
      transactionSmsCounter.current <= 0
        ? resetTimer()
        : setCounterLocal(transactionSmsCounter.current);
    }
  }, [modal]);

  const initTransactionSmsCounterLocal = () => {
    if (initTransactionSmsRef.current == undefined && isMounted.current) {
      initTransactionSmsRef.current = setInterval(() => {
        setCounterLocal((prev) => prev - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (counterLocal <= 0) {
      setResend(true);
      clearInterval(initTransactionSmsRef.current);
      initTransactionSmsRef.current = undefined;
    }
  }, [counterLocal]);

  const initTransactionSms = () => {
    setLoading(false);
    setSmsValidation(true);
    initTransactionSmsCounterLocal();
    if (counterLocal >= timeToWait - 1) {
      sendSms();
      initTransactionSmsCounter();
    }
  };

  const stopTimer = () => {
    resetTransactionSmsCounter(true);
  };

  const sendSms = () => {
    axiosInstance
      .post("user/send-sms")
      .then((res) => {
        toast.show({
          description: "Sms enviado",
          placement: "top",
        });
      })
      .catch((err) => {
        setResend(true);
        toast.show({
          description: "Erro ao enviar o sms",
          placement: "top",
        });
      });
  };

  const resendSms = () => {
    resetTimer();
    initTransactionSmsCounterLocal();
    sendSms();
    setResend(false);
  };

  const resetTimer = () => {
    resetTransactionSmsCounter();
    setCounterLocal(timeToWait);
  };

  const validateSms = () => {
    setLoading(true);
    axiosInstance
      .post("user/validate-sms", {
        code: code,
        transactionCode: transactionCode.current,
      })
      .then((res) => {
        stopTimer();
        makeTransaction();
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: "Erro ao validar o sms",
          placement: "top",
        });
      });
  };

  const checkPassword = () => {
    setLoading(true);
    axiosInstance
      .get("user/check-transaction-password")
      .then((res) => {
        setLoading(false);
        setHasPassword(res?.data == "0" ? false : true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const createPassword = () => {
    setLoading(true);
    if (newPassword == confirmPassword) {
      axiosInstance
        .post("user/transaction-password", { password: newPassword })
        .then((res) => {
          setLoading(false);
          toast.show({
            description: "Senha criada com sucesso!",
            placement: "top",
          });
          setHasPassword(true);
          setPassword("");
        })
        .catch((err) => {
          toast.show({
            description: "Erro ao criar senha, contate o suporte",
            placement: "top",
          });
          setLoading(false);
        });
    } else {
      toast.show({
        description: "As senhas devem ser iguais",
        placement: "top",
      });
      setLoading(false);
    }
  };

  const saveContact = () => {
    axiosInstance
      .post("account/save-contact", {
        ...transactionData,
        digit: 5,
        account_type_id: transactionData?.type?.id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const makeTransaction = () =>
    transaction(transactionCode.current)
      .then((res) => {
        transactionData?.save && saveContact();
        setLoading(false);
        setModal(false);
        navigate(TRANSACTION_SUCCESS, {
          ...transactionData,
          code: res.data?.code,
          voucher: res.data?.voucher,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description:
            err.message == undefined
              ? "Erro ao efetuar a transação"
              : err?.message,
          placement: "top",
        });
      });

  const validatePassword = () => {
    setLoading(true);
    axiosInstance
      .post("user/validate-transaction-password", {
        password: password,
        amount: transactionData.amount,
        transaction_type_id: transactionData.transaction_type_id,
        description: transactionData.description,
        scheduled: transactionData.scheduled ? 1 : 0,
      })
      .then((res) => {
        transactionCode.current = res.data?.code;
        !res.data?.validated ? initTransactionSms() : makeTransaction();
      })
      .catch((err) => {
        setLoading(false);
        if (err?.data != undefined) {
          setInvalid(true);
          setAttempts(err?.data);
        }
        toast.show({
          description: "Senha inválida",
          placement: "top",
        });
      });
  };

  const headerMessage = () => {
    if (smsValidation) {
      return "CÓDIGO DE SEGURANÇA";
    }
    if (invalid) {
      return "SENHA INVÁLIDA";
    }
    if (!hasPassword) {
      return "CRIE A SUA SENHA";
    }

    return "DIGITE A SUA SENHA";
  };

  const ContentSms = (
    <>
      <TextPage style={{ marginBottom: 20 }}>
        Insira o código que enviamos para você por SMS
      </TextPage>
      <TextInputBase
        label={"Digite o código"}
        onChangeText={setCode}
        value={code}
        keyboardType="numeric"
      />
      <View style={{ marginTop: 20, marginBottom: 20 }}>
        <TextPage fontWeight="medium" style={{ textAlign: "center" }}>
          {secondsToTime(counterLocal)}
        </TextPage>
        {resend && (
          <ButtonBase
            tiny
            onPress={() => resendSms()}
            title="Não recebeu o código?"
            color={"link-grey"}
          />
        )}
      </View>
      <ButtonBase
        loading={loading}
        disabled={code === ""}
        title="CONFIMAR"
        onPress={() => validateSms()}
        color={"orange"}
      />
    </>
  );

  const ContentAttempts =
    attempts == "0" ? (
      <>
        <TextPage center style={{ marginBottom: 25 }}>
          Senha inválida. Após 3 tentativas sua senha foi bloqueada para
          realizar transações, faça a recuperação da senha abaixo para
          prosseguir.
        </TextPage>
        <ButtonBase
          loading={loading}
          onPress={() => setInvalid(false)}
          title="RECUPERAR SENHA"
          color="outline-orange"
        />
      </>
    ) : (
      <>
        <TextPage center style={{ marginBottom: 25 }}>
          Senha inválida. Você tem mais {attempts} tentativas, após a terceira
          tentativa, sua senha será bloqueada.
        </TextPage>
        <ButtonBase
          loading={loading}
          onPress={() => {
            setInvalid(false);
            setPassword("");
          }}
          title="CONTINUAR"
          color="outline-orange"
        />
      </>
    );

  const ContentPassword = (
    <>
      <TextPage style={{ marginBottom: 20 }}>
        Crie sua senha para transações, ela deve ser de 4 digítos e apenas
        números
      </TextPage>
      <TextInputBase
        keyboardType="numeric"
        label={"Digite a senha"}
        onChangeText={setNewPassword}
        secureTextEntry={true}
        value={newPassword}
        maxLength={4}
      />
      <TextInputBase
        keyboardType="numeric"
        label={"Confirme a senha"}
        onChangeText={setConfirmPassword}
        secureTextEntry={true}
        value={confirmPassword}
        maxLength={4}
      />
      <ButtonBase
        loading={loading}
        onPress={() => createPassword()}
        title="CONFIRMAR"
        color="orange"
      />
    </>
  );

  const ContentDefault = (
    <>
      <TextInputBase
        label={"Digite a senha de transações"}
        onChangeText={setPassword}
        buttonIcon={
          passwordVisible ? (
            <Ionicons name="eye" size={24} color="black" />
          ) : (
            <Ionicons name="eye-off" size={24} color="black" />
          )
        }
        buttonOnPress={() => setPasswordVisible(!passwordVisible)}
        secureTextEntry={!passwordVisible}
        value={password}
        maxLength={4}
      />
      <ButtonBase
        textLeft
        onPress={() => {
          setModal(false);
          navigate(TRANSACTION_FORGOT_PASSWORD);
        }}
        style={{ marginBottom: 15 }}
        title="Esqueci a senha"
        color="link"
      />
      <ButtonBase
        loading={loading}
        onPress={() => validatePassword()}
        title="CONFIRMAR"
        color="orange"
      />
    </>
  );

  const modalBodyContent = () => {
    if (smsValidation) {
      return ContentSms;
    }
    if (invalid) {
      return ContentAttempts;
    }
    if (!hasPassword) {
      return ContentPassword;
    }

    return ContentDefault;
  };

  return (
    <ModalBase
      header={headerMessage()}
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      {modalBodyContent()}
    </ModalBase>
  );
};

export default TransactionPassword;
