import React, { useState } from "react";

import { ModalBase } from "../../components/modals";
import { ButtonBase } from "../../components/buttons";
import { Radio } from "native-base";

const RegisterPersonType = ({
  modal,
  setModal,
  value,
  setValue,
  updateCustomer,
}) => {
  return (
    <ModalBase
      header={"ABRIR CONTA"}
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <Radio.Group
        name="myRadioGroup"
        accessibilityLabel="person type"
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
        }}
      >
        <Radio value="pf" my={1}>
          Pessoa Física
        </Radio>
        <Radio value="pj" my={1}>
          Pessoa Jurídica
        </Radio>
      </Radio.Group>
      <ButtonBase
        style={{ marginTop: 25 }}
        onPress={() => {
          setModal(false);
          updateCustomer({ person_type: value }, [
            {
              text: value == "pf" ? "Pessoa Física" : "Pessoa Jurídica",
              type: "client",
              fade: 500,
            },
          ]);
        }}
        title="CONFIRMAR"
        color="orange"
      />
    </ModalBase>
  );
};

export default RegisterPersonType;
