import axiosInstance from "../helpers/axiosInstance";

export default (setState) => {
  axiosInstance
    .get("notification/count")
    .then((res) => {
      res.data && setState(res.data);
    })
    .catch((err) => {});
};
