import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import { auth, authInitialState } from "../reducers/auth";

export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [balance, setBalance] = useState();
  const balanceLastRefresh = useRef(0);
  const transactionPasswordCounter = useRef(60);
  const transactionSmsCounter = useRef(60);
  const [bankList, setBankList] = useState();
  const [settings, setSettings] = useState();
  const [accountTypeList, setAccountTypeList] = useState();
  const [transactionTypes, setTransactionTypes] = useState();
  const [countNotification, setCountNotification] = useState();
  const [paymentBarcode, setPaymentBarcode] = useState();
  const [paymentPhotos, setPaymentPhotos] = useState([]);
  const [paymentDocument, setPaymentDocument] = useState();

  const transactionPasswordTimer = useRef(null);
  const transactionSmsTimer = useRef(null);
  const balanceLastRefreshTimer = useRef(null);

  const initBalanceLastRefresh = () => {
    if (balanceLastRefreshTimer.current == null) {
      balanceLastRefreshTimer.current = setInterval(() => {
        balanceLastRefresh.current = balanceLastRefresh.current + 1;
      }, 1000);
    }
  };

  const resetBalanceLastRefresh = () => {
    balanceLastRefresh.current = 0;
  };

  const initTransactionSmsCounter = () => {
    transactionSmsTimer.current = setInterval(() => {
      transactionSmsCounter.current = transactionSmsCounter.current - 1;
    }, 1000);
  };

  const resetTransactionSmsCounter = (stop = false) => {
    transactionSmsCounter.current = 60;
    stop && clearInterval(transactionSmsTimer.current);
  };

  const initTransactionPasswordCounter = () => {
    if (transactionPasswordTimer.current == null) {
      transactionPasswordTimer.current = setInterval(() => {
        transactionPasswordCounter.current =
          transactionPasswordCounter.current - 1;
      }, 1000);
    }
  };

  const resetTransactionPasswordCounter = (stop = false) => {
    transactionPasswordCounter.current = 60;
    stop && clearInterval(transactionPasswordTimer.current);
  };

  useEffect(() => {
    initBalanceLastRefresh();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        balance,
        setBalance,
        bankList,
        setBankList,
        settings,
        setSettings,
        accountTypeList,
        setAccountTypeList,
        transactionTypes,
        setTransactionTypes,
        countNotification,
        setCountNotification,
        paymentDocument,
        setPaymentDocument,
        paymentPhotos,
        setPaymentPhotos,
        paymentBarcode,
        setPaymentBarcode,
        transactionSmsCounter,
        resetTransactionSmsCounter,
        initTransactionSmsCounter,
        transactionPasswordCounter,
        initTransactionPasswordCounter,
        resetTransactionPasswordCounter,
        balanceLastRefresh,
        resetBalanceLastRefresh,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
