import React, { useEffect, useState, useRef } from "react";
import { Box } from "native-base";
import { Formik } from "formik";
import { maskRegex } from "../../helpers/masks";
import { TextInputBase } from "../../components/inputs";
import * as yup from "yup";
import { TitlePage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { REGISTER } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "native-base";
import { YUP_MESSAGES } from "../../constants/validationMessages";

const RegisterAddressData = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    zipcode: "",
    address: "",
    numb: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  });
  const customer = useRef();
  const zipcode = useRef();
  const toast = useToast();

  const onSubmitForm = (values, navigate) => {
    setLoading(true);
    axiosInstance
      .post("customer/update-address", {
        customerId: customer.current.id,
        ...values,
      })
      .then((res) => {
        setLoading(false);
        AsyncStorage.setItem(
          "customer",
          JSON.stringify({
            ...res.data,
          })
        );
        navigate(REGISTER, { addressData: { ...values } });
      })
      .catch((err) => {
        toast.show({
          description: "Preencha os todos os campos",
          placement: "top",
        });
        setLoading(false);
      });
  };

  const loginValidationSchema = yup.object().shape({
    zipcode: yup.string().required(YUP_MESSAGES.required),
    address: yup.string().required(YUP_MESSAGES.required),
    numb: yup.string().required(YUP_MESSAGES.required),
    neighborhood: yup.string().required(YUP_MESSAGES.required),
    city: yup.string().required(YUP_MESSAGES.required),
    state: yup.string().required(YUP_MESSAGES.required),
  });

  const setData = (addressData) => {
    let initV = {};

    initV.zipcode = zipcode.current;
    initV.address = addressData.logradouro;
    initV.complement = addressData.complemento;
    initV.neighborhood = addressData.bairro;
    initV.city = addressData.localidade;
    initV.state = addressData.uf;

    setInitialValues(initV);
  };

  const getData = () => {
    setLoadingData(true);
    axiosInstance
      .post("customer/zipcode", {
        zipcode: zipcode.current,
      })
      .then((res) => {
        setData(res.data);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingData(false);
      });
  };

  const getCustomer = async () => {
    try {
      customer.current = JSON.parse(await AsyncStorage.getItem("customer"));
    } catch (error) {}
  };

  useEffect(() => {
    if (route.params?.cep) {
      zipcode.current = route.params?.cep;
      getCustomer();
      getData();
    }
  }, [route.params?.cep]);

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Endereço Residencial</TitlePage>
        <Formik
          enableReinitialize
          validateOnMount={true}
          validationSchema={loginValidationSchema}
          initialValues={initialValues}
          onSubmit={(values) => onSubmitForm(values, navigate)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <TextInputBase
                name={"zipcode"}
                label={"CEP"}
                onChangeText={handleChange("zipcode")}
                onBlur={handleBlur("zipcode")}
                value={values.zipcode}
                errors={errors.zipcode}
                touched={touched.zipcode}
                loading={loadingData}
              />

              <TextInputBase
                name={"address"}
                label={"Endereço"}
                onChangeText={handleChange("address")}
                onBlur={handleBlur("address")}
                value={values.address}
                errors={errors.address}
                touched={touched.address}
                loading={loadingData}
              />

              <TextInputBase
                name={"numb"}
                label={"Número"}
                onChangeText={handleChange("numb")}
                onBlur={handleBlur("numb")}
                value={values.numb}
                errors={errors.numb}
                touched={touched.numb}
                loading={loadingData}
              />

              <TextInputBase
                name={"complement"}
                label={"Complemento"}
                onChangeText={handleChange("complement")}
                onBlur={handleBlur("complement")}
                value={values.complement}
                errors={errors.complement}
                touched={touched.complement}
                loading={loadingData}
              />

              <TextInputBase
                name={"neighborhood"}
                label={"Bairro"}
                onChangeText={handleChange("neighborhood")}
                onBlur={handleBlur("neighborhood")}
                value={values.neighborhood}
                errors={errors.neighborhood}
                touched={touched.neighborhood}
                loading={loadingData}
              />

              <TextInputBase
                editable={false}
                name={"city"}
                label={"Cidade"}
                onChangeText={handleChange("city")}
                onBlur={handleBlur("city")}
                value={values.city}
                errors={errors.city}
                touched={touched.city}
                loading={loadingData}
              />

              <TextInputBase
                editable={false}
                name={"state"}
                label={"Estado"}
                onChangeText={handleChange("state")}
                onBlur={handleBlur("state")}
                value={values.state}
                errors={errors.state}
                touched={touched.state}
                loading={loadingData}
              />

              <ButtonBase
                style={{ marginTop: 25, marginBottom: 50 }}
                title="CONFIRMAR"
                color="orange"
                onPress={handleSubmit}
                loading={loading}
                disabled={!isValid}
              />
            </>
          )}
        </Formik>
      </ContainerBase>
    </Box>
  );
};

export default RegisterAddressData;
