import styled, { css } from "styled-components/native";

export const TitlePage = styled.Text`
  color: #000000;
  font-size: 22px;
  font-family: "Montserrat_500Medium";
  ${({ color }) => color == "white" && "color:#FFF"};
  ${({ color }) => color == "orange" && "color:#ff671d"};
  ${({ center }) => center && "text-align:center"};
  ${({ tiny }) => tiny && "font-size: 18px;"};
`;

export const TitleOrange = styled.Text`
  color: #ff671d;
  font-size: 15px;
  font-family: "Montserrat_500Medium";
`;

export const TextPage = styled.Text`
  color: #000000;
  font-size: 14px;
  font-family: "Montserrat_400Regular";
  ${({ color }) => color == "white" && "color:#FFF"};
  ${({ color }) => color == "red" && "color:#CA054D"};
  ${({ color }) => color == "green" && "color:#006400"};
  ${({ color }) => color == "orange" && "color:#ff671d"};
  ${({ tiny }) => tiny && "font-size: 12px;"};
  ${({ big }) => big && "font-size: 16px;"};
  ${({ fontWeight }) =>
    fontWeight == "medium" && "font-family: Montserrat_500Medium"};
  ${({ center }) => center && "text-align:center"};
`;

export const TextPageDot = styled.Text`
  color: #000000;
  font-size: 12px;
  padding-left: 10px;
  font-family: "Montserrat_400Regular";
`;
