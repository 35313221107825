import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGIN_FAIL,
} from "../constants/actionTypes";
import { CLIENT_ID, CLIENT_SECRET } from "../constants/configs";
import axiosInstance from "../helpers/axiosInstance";

export default ({ password, username }, dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });

  axiosInstance
    .post("user/login", {
      password,
      username: username.replace(/\.|-|\//g, ""),
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
    })
    .then((res) => {
      AsyncStorage.setItem("user", JSON.stringify(res));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.message
          ? { error: err.message }
          : { error: "Alguma coisa deu errada, tente novamente" },
      });
    });
};
