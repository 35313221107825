import React, { useEffect, useState } from "react";
import { Image, Text } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { useNavigation } from "@react-navigation/core";
import {
  CARDS_NEW,
  DASHBOARD,
  PAYMENT_BARCODE_MANUAL,
  PAYMENT_BARCODE_SCANNER,
} from "../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import {
  ButtonIconsContainer,
  ButtonIconsLeft,
  ButtonIconsRight,
  ButtonIconsContent,
  ButtonContainer,
} from "../../styles/buttons";
import { TemplatePage } from "../../components/templates";
import { Carousel } from "../../components/carousel";
import { CardContainer } from "./styles";
import CardsList from "../../components/cardsList";

const Cards = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards([
      { id: 1, name: "Cartão 80395415", status: "Cartão em processamento" },
      { id: 2, name: "Cartão 80395416", status: "Cartão em processamento" },
      { id: 3, name: "Cartão 80395417", status: "Cartão em processamento" },
      { id: 4, name: "Cartão 80395418", status: "Cartão em processamento" },
    ]);
  }, []);

  const pageContent = (
    <>
      <TitlePage style={{ marginBottom: 20, marginLeft: 15 }}>
        Meus cartões
      </TitlePage>

      <CardsList navigate={navigate} cards={cards} />

      <ContainerBase>
        <ButtonIconsContainer
          style={{ marginBottom: 20 }}
          onPress={() => navigate(CARDS_NEW)}
        >
          <ButtonIconsLeft>
            <Image
              size={30}
              resizeMode="contain"
              source={require("../../assets/images/icon-payment.png")}
              alt="payment icon"
            />
          </ButtonIconsLeft>
          <ButtonIconsContent>
            <TextPage fontWeight="medium" big>
              Solicitar cartão
            </TextPage>
            <TextPage tiny>
              Precisa de um novo cartão físico? Solicite aqui
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>

        <ButtonIconsContainer
          style={{ marginBottom: 20 }}
          onPress={() => console.log("clicou")}
        >
          <ButtonIconsLeft>
            <Image
              size={30}
              resizeMode="contain"
              source={require("../../assets/images/icon-keyboard.png")}
              alt="payment icon"
            />
          </ButtonIconsLeft>
          <ButtonIconsContent>
            <TextPage fontWeight="medium" big>
              Gerar cartão virtual
            </TextPage>
            <TextPage tiny>
              Compre com mais segurança em lojas online, aplicativos
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
      </ContainerBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
    />
  );
};

export default Cards;
