import styled, { css } from "styled-components/native";
import { Dimensions } from "react-native";

const headerSize = 40;
const footerSize = 80;
const containerSize =
  Dimensions.get("window").height - (headerSize + footerSize);

export const HeaderContainer = styled.View`
  height: ${headerSize}px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
`;

export const FooterContainer = styled.View`
  height: ${footerSize}px;
  padding: 0px 15px;
  justify-content: center;
`;

export const MainContainer = styled.ScrollView`
  height: ${containerSize}px;
  padding: 15px;
  font-family: "Montserrat_400Regular";
`;

export const WrapChat = styled.View`
  position: relative;
`;

export const InputChat = styled.TextInput`
  font-family: "Montserrat_400Regular";
  border-width: 1px;
  border-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 35px;
  height: 50px;
  border-radius: 25px;
  color: #fff;
`;

export const SendChat = styled.TouchableOpacity`
  position: absolute;
  padding: 5px;
  right: 10px;
  top: 12.5px;
  ${({ disabled }) => disabled && "opacity: 0"};
`;

export const ChatBotContainer = styled.View`
  display: flex;
  align-items: flex-start;
`;

export const ChatClientContainer = styled.View`
  display: flex;
  align-items: flex-end;
`;

const Chat = css`
  border-radius: 10px;
  padding: 10px;
  max-width: 75%;
  margin-bottom: 15px;
`;

export const ChatBotBox = styled.View`
  ${Chat}
  background-color:#FFF;
  border-bottom-left-radius: 0px;
`;

export const ChatClientBox = styled.View`
  ${Chat}
  background-color:#333333;
  border-bottom-right-radius: 0px;
`;

export const TextBot = styled.Text`
  color: #000;
  display: flex;
  font-family: "Montserrat_400Regular";
`;

export const TextClient = styled.Text`
  color: #fff;
  font-family: "Montserrat_400Regular";
`;

export const ChatEdit = styled.TouchableOpacity`
  padding: 5px;
`;

export const GreyBox = styled.View`
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 25px;
  background-color: #eeeeee;
  font-family: "Montserrat_400Regular";
`;

export const DivisorMessage = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: #fff;
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
  align-items: center;
  justify-content: center;
`;

export const DivisorIcon = styled.View`
  background-color: #ff671d;
  padding: 5px;
  flex: 0 1 auto;
  width: 70px;
  margin-bottom: -25px;
  justify-content: center;
  align-items: center;
`;

export const TextEndMessage = styled.Text`
  color: #fff;
  font-family: "Montserrat_500Medium";
  font-size: 15px;
  margin-bottom: 25px;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
`;

export const ChatImageBox = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
