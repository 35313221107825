import React, { useState, useEffect, useRef } from "react";
import { Box, useToast, Image } from "native-base";
import { TextPage, TitleOrange, TitlePage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import {
  AccountListContainer,
  AccountListContent,
  AccountListImage,
} from "./styles";
import logoutUser from "../../actions/logoutUser";
import { GlobalContext } from "../../contexts/Provider";
import { DASHBOARD, LOGIN } from "../../constants/routes";
import PasswordModal from "./passwordModal";
import axiosInstance from "../../helpers/axiosInstance";
import { Fade, Placeholder, PlaceholderLine } from "rn-placeholder";
import { PlaceholderAccounts, PlaceholderTitle } from "./components";

const AccountList = ({ navigation, route }) => {
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [username, setUsername] = useState(false);
  const [account, setAccount] = useState(false);
  const { navigate } = navigation;
  const toast = useToast();

  const handlePassword = (username) => {
    setShowModalPassword(true);
    setUsername(username);
  };

  useEffect(() => {
    if (route.params?.username) {
      getAccounts();
    } else {
      navigate(LOGIN);
    }
  }, [route.params?.username]);

  const getAccounts = () => {
    axiosInstance
      .post("account/get", {
        cpf_cnpj: route.params?.username,
      })
      .then((res) => {
        if (res.data?.account == "null" || res.data?.account == undefined) {
          toast.show({
            description: "Conta inativa ou inexistente",
            placement: "top",
          });
          navigate(LOGIN);
        }
        setAccount(res.data);
      })

      .catch((err) => {
        toast.show({
          description: "Erro ao buscar conta",
          placement: "top",
        });
        navigate(LOGIN);
      });
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={() => navigate(LOGIN)} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        {account ? (
          <TitlePage style={{ marginBottom: 30 }}>{account?.name}</TitlePage>
        ) : (
          <PlaceholderTitle />
        )}
        <TextPage style={{ marginBottom: 30 }}>
          Essas são as contas que você tem acesso
        </TextPage>
        {account ? (
          <AccountListContainer
            onPress={() => handlePassword(account?.cpf_cnpj)}
          >
            <AccountListImage>
              <Image
                source={require("../../assets/images/default-photo.png")}
                size="50"
                borderRadius="100"
                alt="photo"
              />
            </AccountListImage>
            <AccountListContent>
              <TitleOrange>{account?.name}</TitleOrange>
              <TextPage fontWeight="medium">
                {account?.customer?.person_type == "pj"
                  ? "Conta pessoa Jurídica"
                  : "Conta pessoa Física"}
              </TextPage>
              <TextPage>
                Cc. {account?.account?.account + "-" + account?.account?.digit}
              </TextPage>
            </AccountListContent>
          </AccountListContainer>
        ) : (
          <PlaceholderAccounts />
        )}
      </ContainerBase>
      <PasswordModal
        username={username}
        modal={showModalPassword}
        setModal={setShowModalPassword}
      />
    </Box>
  );
};

export default AccountList;
