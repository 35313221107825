import React from "react";
import { View } from "react-native";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";

export const PlaceholderTitle = () => (
  <View style={{ marginBottom: 30 }}>
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{ height: 17.5 }} />
    </Placeholder>
  </View>
);

export const PlaceholderAccounts = () => (
  <>
    <View style={{ marginBottom: 15, marginTop: 15 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine />
        <PlaceholderLine width={50} />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine />
        <PlaceholderLine width={50} />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
  </>
);
