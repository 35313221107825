import React, { useContext, useEffect } from "react";
import { View, Flex, useToast } from "native-base";
import { ModalBase } from "./modals";
import { ButtonBase } from "./buttons";
import { TextPage } from "../styles/typograph";
import { Divisor } from "../styles/default";
import { GlobalContext } from "../contexts/Provider";
import { formatMoney, formatDecimal } from "../helpers/format";

const TransactionValueConfirm = ({
  modal,
  setModal,
  setConfirmValue,
  amount,
}) => {
  const { balance } = useContext(GlobalContext);

  return (
    <ModalBase
      header="CONFIRMAR A TRANSAÇÃO"
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
        <View style={{ flex: 1 }}>
          <TextPage>Saldo em conta</TextPage>
        </View>
        <View style={{ flex: 1 }}>
          <TextPage color="orange">
            {balance && " R$" + formatMoney(balance)}
          </TextPage>
        </View>
      </Flex>

      <Flex direction="row" space={3}>
        <View style={{ flex: 1 }}>
          <TextPage>Valor da transação</TextPage>
        </View>
        <View style={{ flex: 1 }}>
          <TextPage color="orange">
            {amount && " R$" + formatMoney(amount)}
          </TextPage>
        </View>
      </Flex>

      <Divisor style={{ marginBottom: 15, marginTop: 15 }} />

      <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
        <View style={{ flex: 1 }}>
          <TextPage>Saldo após a transação</TextPage>
        </View>
        <View style={{ flex: 1 }}>
          <TextPage color="orange">
            {balance && " R$" + formatMoney(balance - amount)}
          </TextPage>
        </View>
      </Flex>

      <ButtonBase
        style={{ marginBottom: 10 }}
        onPress={() => setModal(false)}
        title="VOLTAR"
        color="outline-black"
      />
      <ButtonBase
        onPress={() => {
          setConfirmValue(true);
          setModal(false);
        }}
        title="CONFIRMAR"
        color="orange"
      />
    </ModalBase>
  );
};

export default TransactionValueConfirm;
