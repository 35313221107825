import React, { useState, useEffect, useContext } from "react";
import { Box, useToast, Image, Text } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerPad,
  FooterBase,
  GreyContainer,
} from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { TextInputBase, SwitchBase } from "../../components/inputs";
import { TRANSFER } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { moneyMask } from "../../helpers/masks";
import { TemplatePage } from "../../components/templates";

import {
  ContainerBorder,
  ContainerBorderless,
  AccountTypeItem,
} from "./styles";
import {
  clearCaracters,
  formatMoney,
  formatDecimal,
} from "../../helpers/format";
import TransactionPassword from "../../components/transactionPassword";
import { ScrollView } from "react-native";
import TransactionValueConfirm from "../../components/transactionValueConfirm";

const TransferPixKey = ({ navigation, route }) => {
  const { navigate } = navigation;
  const toast = useToast();
  const {
    balance,
    settings,
    authState: { authData },
  } = useContext(GlobalContext);
  const [keyType, setKeyType] = useState({ id: 3, name: "CPF/CNPJ" });
  const [keyValue, setKeyValue] = useState("");
  const [confirmKey, setConfirmKey] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [saveContact, setSaveContact] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmValue, setShowConfirmValue] = useState(false);
  const [validateAmount, setValidateAmount] = useState();

  useEffect(() => {}, []);

  const handleConfirmValue = () => {
    if (balance - formatDecimal(amount) < 0) {
      toast.show({
        description: "Saldo insuficiente",
        placement: "top",
      });
      return;
    }
    if (formatDecimal(amount) < settings?.mv_pix) {
      setValidateAmount("Valor mínimo: R$" + formatMoney(settings?.mv_pix));
      return;
    }

    setShowConfirmValue(true);
  };

  const fakeTransfer = async (code) =>
    axiosInstance.post("transaction/fake-transfer", {
      amount: formatDecimal(amount),
      code: code,
    });

  const keyTypeList = [
    { id: 1, name: "Celular" },
    { id: 2, name: "Email" },
    { id: 3, name: "CPF/CNPJ" },
    { id: 4, name: "Chave aleatória" },
  ];

  const pageContentKey = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Enviar Pix</TitlePage>

        <ScrollView
          style={{ marginBottom: 10 }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {keyTypeList &&
            keyTypeList.map((item) => {
              return (
                <AccountTypeItem key={item.id.toString()}>
                  <ButtonBase
                    tiny
                    onPress={() => setKeyType(item)}
                    title={item.name}
                    color={keyType?.id == item.id ? "orange" : "outline-black"}
                  />
                </AccountTypeItem>
              );
            })}
        </ScrollView>

        <TextInputBase
          name={"keyValue"}
          onChangeText={(v) => {
            setKeyValue(v);
          }}
          value={keyValue}
          bigText
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={keyValue == undefined || keyValue == ""}
          onPress={() => setConfirmKey(true)}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  const pageContentValue = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 5 }}>
          Quanto você quer enviar?
        </TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          Seu saldo disponível em conta
          <TextPage color="orange">
            {balance && " R$" + formatMoney(balance)}
          </TextPage>
        </TextPage>

        <TextInputBase
          keyboardType="numeric"
          name={"amount"}
          onChangeText={(v) => {
            setAmount(moneyMask(v));
          }}
          value={amount}
          bigText
          errors={validateAmount}
          touched={true}
        />
        <TextInputBase
          name={"description"}
          label={"Descrição(opcional)"}
          onChangeText={setDescription}
          value={description}
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={amount == "" || amount == undefined || amount.length < 4}
          onPress={() => handleConfirmValue()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
      <TransactionValueConfirm
        modal={showConfirmValue}
        setModal={setShowConfirmValue}
        setConfirmValue={setConfirmValue}
        amount={formatDecimal(amount)}
      />
    </>
  );

  const pageContentData = (
    <>
      <ContainerBase paddingReset={true}>
        <ContainerPad>
          <TitlePage>Enviar Pix</TitlePage>
        </ContainerPad>

        <ContainerBorder>
          <TextPage>Para</TextPage>
          <TextPage fontWeight="medium">João Victor Manuel</TextPage>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">{"R$" + amount}</TextPage>
          <TextPage tiny>
            {"tarifa: " + authData?.user?.fee?.pix_out &&
              "R$" + authData?.user?.fee?.pix_out}
          </TextPage>
        </ContainerBorderless>
        <GreyContainer style={{ marginBottom: 15 }}>
          <TextPage>CPF/CNPJ</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            080.989.589-66
          </TextPage>

          <TextPage>Tipo de transferência</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            Pix
          </TextPage>
        </GreyContainer>
        <ContainerPad>
          <SwitchBase
            style={{ marginBottom: 10 }}
            label="Salvar contato"
            value={saveContact}
            setValue={(v) => setSaveContact(v)}
          />
        </ContainerPad>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => setShowPassword(true)}
          title="CONFIRMAR"
          color="orange"
        />
      </FooterBase>
      <TransactionPassword
        modal={showPassword}
        setModal={setShowPassword}
        transaction={fakeTransfer}
        transactionData={{
          amount: formatDecimal(amount),
          transaction_type_id: 25,
          name: "João Victor Manuel",
          fee: authData?.user?.fee?.pix_out,
          cpf_cnpj: clearCaracters("2142144214214"),
          type: { id: 1, name: "Conta corrente" },
          transfer_type: "PIX",
          save: saveContact,
        }}
      />
    </>
  );

  const pageContent = () => {
    if (confirmValue) return pageContentData;
    if (confirmKey) return pageContentValue;

    return pageContentKey;
  };

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent()}
      backNavigate={TRANSFER}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferPixKey;
