import React from "react";
import { Text, View } from "react-native";
import { ButtonBase } from "../../../components/buttons";
import { PhotoPreviewContainer, ImagePreview } from "./styles";

export const CameraPreview = ({ photo, retakePicture, savePhoto, loading }) => {
  return (
    <PhotoPreviewContainer>
      <View style={{ width: "90%" }}>
        <ImagePreview
          style={{ resizeMode: "contain" }}
          source={{ uri: photo && photo.uri }}
        />
        <Text
          style={{ textAlign: "center", marginTop: 15, fontWeight: "bold" }}
        >
          VERIFIQUE A FOTO
        </Text>
        <Text style={{ textAlign: "center", marginTop: 10 }}>
          Verifique a imagem, caso não esteja boa tire uma nova foto.
        </Text>
        <ButtonBase
          loading={loading}
          style={{ marginTop: 15 }}
          onPress={savePhoto}
          title="USAR ESSA FOTO"
          color="orange"
        />
        <ButtonBase
          style={{ marginTop: 5 }}
          onPress={retakePicture}
          title="TIRAR OUTRA FOTO"
          color="outline-black"
        />
      </View>
    </PhotoPreviewContainer>
  );
};
