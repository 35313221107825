import {
  monthNames,
  monthNamesSimplified,
  weekDayNamesSimplified,
} from "../constants/arrayConstants";

export const getExtensionByName = (fileName) => {
  return fileName.split(".").pop();
};

export const secondsToMinutes = (seconds) => {
  return Math.floor(seconds / 60);
};

export const secondsToTime = (seconds) => {
  return new Date(seconds * 1000).toISOString().substr(14, 5);
};

export const clearCaracters = (value) => {
  return value ? value.replace(/[-&\/\\#,+()$~%.'":*?<>{}\s]/g, "") : "";
};

export const formatMoney = (value) => {
  return Number(value)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
};

export const formatDecimal = (value) => {
  return Number(value.toString().replace(/\./g, "").replace(",", "."));
};

export const formatDateInput = (date) => {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const formatDateShow = (date) => {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const formatDate = (date) => {
  let dateSplit = date.split(" ");
  dateSplit = dateSplit[0].split("-");

  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formatDateFitFormat = (date) => {
  let dateToday = new Date();
  let dateSplit = date.split(" ");

  dateSplit = dateSplit[0].split("/");
  if (dateSplit.length > 2) {
    dateToday.setFullYear(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    return dateToday;
  } else {
    return new Date();
  }
};

export const formatDateDayMonth = (date) => {
  let dateObj = new Date(date);
  return dateObj.getDate() + " " + monthNamesSimplified[dateObj.getMonth()];
};

export const formatDateMonthYear = (date) => {
  let dateObj = new Date(date);
  return monthNames[dateObj.getMonth()] + " " + dateObj.getFullYear();
};

export const formatDateWeekDayMonth = (date) => {
  let dateObj = new Date(date);
  return (
    weekDayNamesSimplified[dateObj.getDay()] +
    ", " +
    dateObj.getDate() +
    " de " +
    monthNamesSimplified[dateObj.getMonth()].toLowerCase() +
    "."
  );
};
