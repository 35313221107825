import styled from "styled-components/native";

export const BenefitsButtons = styled.View`
  flex-direction: row;
`;

export const BenefitsContainer = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 50px;
`;

export const BenefitsImage = styled.TouchableOpacity`
  flex: 50%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;
