import React, { useState, useContext, useEffect } from "react";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { ButtonBase, ButtonClose } from "../../components/buttons";
import { TitlePage, TextPage } from "../../styles/typograph";
import {
  ContainerBase,
  FooterBase,
  GreyContainer,
  HeaderBaseClose,
} from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import {
  ContainerBorder,
  ContainerBorderless,
  ProfileContainer,
  ProfileContent,
} from "./styles";
import { DASHBOARD } from "../../constants/routes";
import { GlobalContext } from "../../contexts/Provider";
import { formatMoney } from "../../helpers/format";
import { Flex, useToast, View } from "native-base";
import axiosInstance from "../../helpers/axiosInstance";

const TransactionSuccess = ({ navigation, route }) => {
  const { navigate } = navigation;
  const {
    authState: { authData },
  } = useContext(GlobalContext);
  const [code, setCode] = useState();
  const [transactionData, setTransactionData] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (route.params?.code) {
      setTransactionData(route.params);
      setCode(route.params?.code);
    } else {
      navigate(DASHBOARD);
    }
  }, [route.params?.code]);

  const generateVoucher = () => {
    setLoading(true);
    axiosInstance
      .post("transaction/voucher", {
        code: route.params?.code,
      })
      .then((res) => {
        onShare(res.data?.voucher);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: "Erro ao compartilhar",
          placement: "top",
        });
      });
  };

  const onShare = async (voucher) => {
    var fileShare = "";
    try {
      const { uri: localUrl } = await FileSystem.downloadAsync(
        voucher,
        FileSystem.documentDirectory + "comprovantetimepay.pdf"
      );
      fileShare = localUrl;
    } catch (error) {
      fileShare = voucher;
    }

    if (!(await Sharing.isAvailableAsync())) {
      toast.show({
        description: "Conceda permissão para compartilhar",
        placement: "top",
      });
      setLoading(false);
      return;
    }
    await Sharing.shareAsync(fileShare);
    setLoading(false);
  };

  const pageContent = (
    <>
      <ContainerBase paddingReset>
        <TitlePage center style={{ marginBottom: 5, marginLeft: 15 }}>
          Transação realizada com sucesso
        </TitlePage>
        <ContainerBorder>
          <TextPage>De</TextPage>
          <ProfileContainer>
            <ProfileContent>
              <TextPage fontWeight="medium">{authData?.user?.name}</TextPage>
              <TextPage>CPF: {authData?.user?.cpf_cnpj}</TextPage>
            </ProfileContent>
          </ProfileContainer>
        </ContainerBorder>
        {transactionData?.name && (
          <ContainerBorder>
            <TextPage>Para</TextPage>
            <TextPage fontWeight="medium">{transactionData?.name}</TextPage>
          </ContainerBorder>
        )}
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">
            {"R$" + formatMoney(transactionData?.amount)}
          </TextPage>
          <TextPage tiny>tarifa {" R$" + transactionData?.fee}</TextPage>
        </ContainerBorderless>

        <GreyContainer>
          {transactionData?.barcode && (
            <>
              <TextPage>Código de Barras</TextPage>
              <TextPage style={{ marginBottom: 15 }} TextPage="medium">
                {transactionData?.barcode}
              </TextPage>
            </>
          )}

          {transactionData?.due_date && (
            <>
              <TextPage>Data de Vencimento</TextPage>
              <TextPage style={{ marginBottom: 15 }} TextPage="medium">
                {transactionData?.due_date}
              </TextPage>
            </>
          )}

          {transactionData?.cpf_cnpj && (
            <>
              <TextPage>CPF/CNPJ</TextPage>
              <TextPage style={{ marginBottom: 15 }} TextPage="medium">
                {transactionData?.cpf_cnpj}
              </TextPage>
            </>
          )}

          {transactionData?.bank_name && (
            <>
              <TextPage>Banco</TextPage>
              <TextPage style={{ marginBottom: 15 }} TextPage="medium">
                {transactionData?.bank_name}
              </TextPage>
            </>
          )}

          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Tipo de transferência</TextPage>
              <TextPage TextPage="medium">
                {transactionData?.transfer_type
                  ? transactionData?.transfer_type
                  : "TED"}
              </TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>Tipo de conta</TextPage>
              <TextPage TextPage="medium">
                {transactionData?.type?.name}
              </TextPage>
            </View>
          </Flex>
          <Flex direction="row" space={3}>
            {transactionData?.branch && (
              <View style={{ flex: 1 }}>
                <TextPage>Agência</TextPage>
                <TextPage TextPage="medium">{transactionData?.branch}</TextPage>
              </View>
            )}
            {transactionData?.account && (
              <View style={{ flex: 1 }}>
                <TextPage>Conta</TextPage>
                <TextPage TextPage="medium">
                  {transactionData?.account}
                </TextPage>
              </View>
            )}
          </Flex>
        </GreyContainer>

        {transactionData?.description != undefined && (
          <ContainerBorder>
            <TextPage>Descrição</TextPage>
            <TextPage fontWeight="medium">
              {transactionData?.description}
            </TextPage>
          </ContainerBorder>
        )}
        <ContainerBorderless>
          <TextPage>Código de autênticação</TextPage>
          <TextPage fontWeight="medium">{code}</TextPage>
        </ContainerBorderless>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          onPress={() => generateVoucher()}
          title="COMPARTILHAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      dataNavigate={{ transaction_update: code }}
      paddingResetMobile
      fullScreen={true}
      closeIcon={true}
      updateDashboard={true}
    />
  );
};

export default TransactionSuccess;
