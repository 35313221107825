export const LOGIN = "Login";
export const LOGOUT = "Logout";
export const ACCOUNT_LIST = "Account list";
export const BENEFITS = "Benefits";
export const BENEFITS_DETAIL = "Benefits Detail";
export const FORGOT_PASSWORD = "Forgot Password";
export const FORGOT_PASSWORD_SUCCESS = "Forgot Password Success";

export const HOME_NAVIGATOR = "Home";
export const REGISTER = "Register";
export const REGISTER_TERM = "Register Term";
export const REGISTER_INSTRUCTIONS_FACE = "Register Instructions Face";
export const REGISTER_INSTRUCTIONS = "Register Instructions";
export const REGISTER_PHOTO = "Register Photo";
export const REGISTER_PHOTO_FACE = "Register Photo Face";
export const REGISTER_PERSONAL_DATA = "Register Personal Data";
export const REGISTER_ADDRESS_DATA = "Register Address Data";
export const REGISTER_PHONE_CONFIRMATION = "Register Phone Confirmation";
export const REGISTER_PASSWORD = "Register Password";
export const REGISTER_PASSWORD_CONFIRMATION = "Register Password Confirmation";

export const DASHBOARD = "Dashboard";

export const TRANSACTION_FORGOT_PASSWORD = "Transaction Forgot Password";

export const PAYMENT = "Payment";
export const PAYMENT_BARCODE_SCANNER = "Payment Barcode Scanner";
export const PAYMENT_BARCODE_MANUAL = "Payment Barcode Manual";
export const PAYMENT_BARCODE_DATA = "Payment Barcode Data";
export const PAYMENT_BARCODE_PHOTO_LIST = "Payment Barcode Photo List";

export const DEPOSIT = "Deposit";
export const DEPOSIT_BANK_SLIP = "Deposit Bank Slip";
export const DEPOSIT_BANK_SLIP_SUCCESS = "Deposit Bank Slip Success";
export const DEPOSIT_TED = "Deposit Ted";

export const TRANSFER = "Transfer";
export const TRANSFER_ANOTHERBANK = "Transfer Another Bank";
export const TRANSFER_FITBANK_OPTIONS = "Transfer Fit Bank Options";
export const TRANSFER_FITBANK = "Transfer Fit Bank";
export const TRANSFER_FITBANK_DOCUMENT = "Transfer Fit Document";
export const TRANSFER_PIX = "Transfer Pix";
export const TRANSFER_PIX_COPY_PASTE = "Transfer Pix Copy Paste";
export const TRANSFER_PIX_KEY = "Transfer Pix Key";
export const TRANSFER_PIX_CONFIRM = "Transfer Pix Confirm";
export const TRANSFER_PIX_QR = "Transfer Pix Qr";
export const TRANSFER_SUCCESS = "Transfer Success";
export const TRANSFER_ACCOUNTS = "Transfer Accounts";
export const TRANSFER_MY_ACCOUNTS = "Transfer My Accounts";

export const HISTORY = "History";

export const WITHDRAW = "Withdraw";
export const WITHDRAW_BANK_INFO = "WithDraw Bank Info";
export const WITHDRAW_BANK_LIST = "WithDraw Bank List";

export const NOTIFICATION = "Notification";

export const TRANSACTION_SUCCESS = "Transaction Success";

export const CARDS = "Cards";
export const CARDS_NEW = "Cards New";
export const CARDS_NEW_CONFIRM = "Cards New Confirm";
export const CARDS_NEW_SUCCESS = "Cards New Success";
export const CARDS_DETAILS = "Cards Details";
export const CARDS_FACE = "Cards Face";
export const CARDS_SUCCESS = "Cards Success";
export const CARDS_PASSWORD = "Cards Password";
export const CARDS_PASSWORD_SUCCESS = "Cards Password Success";
