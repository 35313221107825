import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Box } from "native-base";
import { TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerFull,
  FooterBase,
  HeaderBase,
} from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { TextInputBase } from "../../components/inputs";
import { PAYMENT, PAYMENT_BARCODE_DATA } from "../../constants/routes";
import { barCodeMask } from "../../helpers/masks";
import { GlobalContext } from "../../contexts/Provider";
import { TemplatePage } from "../../components/templates";

const barcodeManual = ({ navigation }) => {
  const { navigate } = navigation;
  const { paymentBarcode, setPaymentBarcode } = useContext(GlobalContext);

  const pageContent = (
    <>
      <ContainerBase>
        <TitlePage center style={{ marginBottom: 30 }}>
          Insira o código do boleto que quer pagar
        </TitlePage>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextInputBase
            style={{ fontSize: 18 }}
            name="barcode"
            keyboardType="numeric"
            onChangeText={(value) => {
              setPaymentBarcode(barCodeMask(value));
            }}
            value={paymentBarcode}
            bigText
            multiline
            numberOfLines={4}
          />
        </View>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={paymentBarcode == "" || paymentBarcode == undefined}
          title="CONTINUAR"
          color="orange"
          onPress={() => navigate(PAYMENT_BARCODE_DATA)}
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={PAYMENT}
      fullScreen={true}
      paddingResetMobile
    />
  );
};

export default barcodeManual;
