import axiosInstance from "../helpers/axiosInstance";

export default (setSettings, setAccountTypes, setTransactionTypes) => {
  axiosInstance
    .get("setting/get-data")
    .then((res) => {
      setSettings(res.data?.settings);
      setAccountTypes(res.data?.account_types);
      setTransactionTypes(res.data?.transaction_types);
    })
    .catch((err) => {});
};
