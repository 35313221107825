import React from "react";

import { ModalBase } from "../../components/modals";
import { ButtonBase } from "../../components/buttons";
import { SwitchBase, TextInputBase } from "../../components/inputs";
import { cpfCnpjMask } from "../../helpers/masks";
import { ACCOUNT_LIST } from "../../constants/routes";
import { clearCaracters } from "../../helpers/format";

const CpfModal = ({
  modal,
  setModal,
  navigate,
  remember,
  setRemember,
  username,
  setUsername,
}) => {
  return (
    <ModalBase
      header="DIGITE O SEU CPF OU CNPJ"
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <TextInputBase
        name="username"
        onChangeText={(value) => {
          setUsername(cpfCnpjMask(value));
        }}
        value={username}
        keyboardType="numeric"
        bigText
      />

      <SwitchBase
        style={{ marginTop: 10, marginBottom: 25 }}
        label="Lembrar CPF"
        value={remember}
        setValue={setRemember}
      />

      <ButtonBase
        disabled={username == ""}
        onPress={() => {
          setModal(false);
          navigate(ACCOUNT_LIST, {
            username: clearCaracters(username),
            remember: remember,
          });
        }}
        title="ENTRAR"
        color="orange"
      />
    </ModalBase>
  );
};

export default CpfModal;
