import React, { useEffect, useState, useRef } from "react";
import { Box, useToast } from "native-base";
import { Formik } from "formik";
import {
  DateInput,
  PickerInput,
  SwitchBase,
  TextInputBase,
} from "../../../components/inputs";
import * as yup from "yup";
import { TitlePage } from "../../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../../styles/default";
import { ButtonBack, ButtonBase } from "../../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { REGISTER } from "../../../constants/routes";
import axiosInstance from "../../../helpers/axiosInstance";
import { YUP_MESSAGES } from "../../../constants/validationMessages";

const loginValidationSchema = yup.object().shape({
  name: yup.string().required(YUP_MESSAGES.required),
  birthDate: yup.string().required(YUP_MESSAGES.required),
  motherName: yup.string().required(YUP_MESSAGES.required),
  fatherName: yup.string().required(YUP_MESSAGES.required),
  nationality: yup.string().required(YUP_MESSAGES.required),
  gender: yup.string().required(YUP_MESSAGES.required),
  maritalStatus: yup.string().required(YUP_MESSAGES.required),
});

const RegisterPersonalData = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [documentType, setDocumentType] = useState("rg");
  const [nacionalities, setNacionalities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    cpf: "",
    rg: "",
    cnh: "",
    name: "",
    birthDate: "",
    motherName: "",
    fatherName: "",
    nationality: "",
    gender: "",
    maritalStatus: "",
    publiclyExposed: false,
  });
  const customer = useRef();
  const toast = useToast();

  useEffect(() => {
    if (route.params?.customer) {
      customer.current = route.params?.customer;
      getData();
      getNacionalities();
    } else {
      navigate(REGISTER);
    }
  }, [route.params?.customer]);

  const onSubmitForm = (values, navigate) => {
    setLoading(true);
    axiosInstance
      .post("customer/update-pd", {
        customerId: customer.current.id,
        documentType: customer.current.document_type,
        ...values,
        birthDate: formatDateSave(values.birthDate),
      })
      .then((res) => {
        setLoading(false);

        navigate(REGISTER, {
          personalData: {
            ...values,
            birthDate: formatDateSave(values.birthDate),
          },
          photoData: res.data.customer_document,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: "Preencha todos os campos",
          placement: "top",
        });
      });
  };

  const setOcrData = (data) => {
    let initV = {};

    if (data.cpf_cnpj !== undefined) {
      initV = {
        ...initV,
        cpf: data.cpf_cnpj,
      };
    }
    if (data.rg !== undefined) {
      initV = {
        ...initV,
        rg: data.rg,
      };
    }
    if (data.cnh !== undefined) {
      initV = {
        ...initV,
        cnh: data.cnh,
      };
    }
    if (data.name !== undefined) {
      initV = {
        ...initV,
        name: data.name,
      };
    }

    if (data.birth_date !== undefined) {
      initV = {
        ...initV,
        birthDate: convertFromStringToDate(data.birth_date),
      };
    }
    if (data.mother_name !== undefined) {
      initV = {
        ...initV,
        motherName: data.mother_name,
      };
    }
    if (data.father_name !== undefined) {
      initV = {
        ...initV,
        fatherName: data.father_name,
      };
    }
    setInitialValues(initV);
  };

  const convertFromStringToDate = (responseDate) => {
    if (responseDate == undefined || responseDate == "") {
      return new Date();
    }
    let dateComponents = responseDate.split(" ");
    let datePieces = dateComponents[0].split("-");
    let timePieces = dateComponents[1].split(":");
    return new Date(
      datePieces[0],
      datePieces[1] - 1,
      datePieces[2],
      timePieces[0],
      timePieces[1],
      timePieces[2]
    );
  };

  const getData = () => {
    setDocumentType(customer.current.document_type);
    setLoadingData(true);

    axiosInstance
      .post("customer/cpf-cnpj", {
        cpf_cnpj: customer.current.cpf_cnpj,
      })
      .then((res) => {
        setOcrData(res.data);
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  const getNacionalities = () => {
    axiosInstance
      .get("nationalities")
      .then((res) => {
        formatNationalityData(res?.data);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  const formatNationalityData = (data) => {
    let natData = [];
    if (data != undefined) {
      for (let i = 0; i < data.length; i++) {
        natData.push({ label: data[i].name, value: data[i].id });
      }
      setNacionalities(natData);
    }
  };

  const genderData = [
    {
      label: "Masculino",
      value: 0,
    },
    {
      label: "Feminino",
      value: 1,
    },
    {
      label: "Outros",
      value: 2,
    },
  ];

  const maritalData = [
    {
      label: "União Estável",
      value: 0,
    },
    {
      label: "Casado",
      value: 1,
    },
    {
      label: "Divorciado",
      value: 2,
    },
    {
      label: "Separado",
      value: 3,
    },
    {
      label: "Viúvo",
      value: 4,
    },
    {
      label: "Solteiro",
      value: 5,
    },
    {
      label: "Outros",
      value: 6,
    },
  ];

  const formatDate = (dateToFormat) => {
    if (dateToFormat == undefined || dateToFormat == "") {
      return "Carregando...";
    }

    return (
      dateToFormat.getDate() +
      "/" +
      (dateToFormat.getMonth() + 1) +
      "/" +
      dateToFormat.getFullYear()
    );
  };

  const formatDateSave = (dateToFormat) => {
    return (
      dateToFormat.getFullYear() +
      "-" +
      (dateToFormat.getMonth() + 1) +
      "-" +
      dateToFormat.getDate()
    );
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Dados Pessoais</TitlePage>
        <Formik
          enableReinitialize
          validateOnMount={true}
          validationSchema={loginValidationSchema}
          initialValues={initialValues}
          onSubmit={(values) => onSubmitForm(values, navigate)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <TextInputBase
                editable={false}
                name={"cpf"}
                label={"CPF"}
                onChangeText={handleChange("cpf")}
                onBlur={handleBlur("cpf")}
                value={values.cpf}
                errors={errors.cpf}
                touched={touched.cpf}
                loading={loadingData}
              />
              {documentType == "rg" ? (
                <TextInputBase
                  editable={false}
                  name={"rg"}
                  label={"RG"}
                  onChangeText={handleChange("rg")}
                  onBlur={handleBlur("rg")}
                  value={values.rg}
                  errors={errors.rg}
                  touched={touched.rg}
                  loading={loadingData}
                />
              ) : (
                <TextInputBase
                  editable={false}
                  name={"cnh"}
                  label={"CNH"}
                  onChangeText={handleChange("cnh")}
                  onBlur={handleBlur("cnh")}
                  value={values.cnh}
                  errors={errors.cnh}
                  touched={touched.cnh}
                  loading={loadingData}
                />
              )}
              <TextInputBase
                name={"name"}
                label={"Nome"}
                onChangeText={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                errors={errors.name}
                touched={touched.name}
                loading={loadingData}
              />
              <DateInput
                label="Data de Nascimento"
                valueFormated={formatDate(values.birthDate)}
                value={values.birthDate}
                errors={errors.birthDate}
                touched={touched.birthDate}
                setValue={(itemValue) => {
                  setFieldValue("birthDate", itemValue);
                  setFieldTouched("birthDate", true, false);
                }}
              />
              <TextInputBase
                name={"motherName"}
                label={"Nome da Mãe"}
                onChangeText={handleChange("motherName")}
                onBlur={handleBlur("motherName")}
                value={values.motherName}
                errors={errors.motherName}
                touched={touched.motherName}
                loading={loadingData}
              />
              <TextInputBase
                name={"fatherName"}
                label={"Nome do Pai"}
                onChangeText={handleChange("fatherName")}
                onBlur={handleBlur("fatherName")}
                value={values.fatherName}
                errors={errors.fatherName}
                touched={touched.fatherName}
                loading={loadingData}
              />
              <PickerInput
                selectedValue={values.nationality}
                onValueChange={(itemValue, itemIndex) => {
                  setFieldValue("nationality", itemValue);
                  setFieldTouched("nationality", true, false);
                }}
                errors={errors.nationality}
                touched={touched.nationality}
                pickerItens={nacionalities}
                label={"Nacionalidade"}
              />
              <PickerInput
                selectedValue={values.gender}
                onValueChange={(itemValue, itemIndex) => {
                  setFieldValue("gender", itemValue);
                  setFieldTouched("gender", true, false);
                }}
                errors={errors.gender}
                pickerItens={genderData}
                touched={touched.gender}
                label={"Gênero"}
              />
              <PickerInput
                selectedValue={values.maritalStatus}
                onValueChange={(itemValue, itemIndex) => {
                  setFieldValue("maritalStatus", itemValue);
                  setFieldTouched("maritalStatus", true, false);
                }}
                errors={errors.maritalStatus}
                touched={touched.maritalStatus}
                pickerItens={maritalData}
                label={"Estado Cívil"}
              />
              <SwitchBase
                style={{ marginBottom: 10 }}
                label="Pessoa Pública?"
                value={values.publiclyExposed}
                setValue={(value) => {
                  setFieldValue("publiclyExposed", value);
                  setFieldTouched("publiclyExposed", true, false);
                }}
              />
              <ButtonBase
                style={{ marginTop: 25, marginBottom: 50 }}
                title="CONTINUAR"
                color="orange"
                onPress={handleSubmit}
                loading={loading || loadingData}
                disabled={!isValid}
              />
            </>
          )}
        </Formik>
      </ContainerBase>
    </Box>
  );
};

export default RegisterPersonalData;
