import styled from "styled-components/native";

export const TransferOptions = styled.View`
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 25px;
`;

export const TransferOptionsButton = styled.TouchableOpacity`
  text-align: center;
  width: 60px;
  align-items: center;
`;

export const TextButton = styled.Text`
  font-size: 9px;
  font-family: "Montserrat_400Regular";
  margin-top: 5px;
  text-align: center;
`;

export const ContainerBorder = styled.View`
  margin-bottom: 15px;
  padding: 15px;
  border-bottom-color: #dddddd;
  border-bottom-width: 1px;
`;

export const ContainerRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerCol = styled.View``;

export const ContainerBorderless = styled.View`
  margin-bottom: 15px;
  padding: 15px;
`;

export const ProfileContainerBorder = styled.TouchableOpacity`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
  border-color: #dddddd;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px 5px;
  margin-bottom: 5px;
`;

export const ProfileContainer = styled.View`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
`;

export const ProfileImage = styled.View`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContent = styled.View`
  flex: 1;
`;

export const ProfileImageButton = styled.TouchableOpacity`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContentButton = styled.TouchableOpacity`
  flex: 1;
`;

export const ProfileButton = styled.TouchableOpacity`
  flex: 0 0 40px;
  justify-content: center;
  align-items: center;
`;

export const AccountTypeItem = styled.View`
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
`;

export const ContainerFee = styled.View`
  border-top-color: #eeeeee;
  border-top-width: 1px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const BarCodeScannerContainer = styled.View`
  flex: 1;
`;

export const BarCodeScannerHeader = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  height: 30%;
  flex-direction: column;
  padding: 5px;
  padding-top: 15px;
`;

export const BarCodeScannerFooter = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  height: 30%;
  align-items: flex-end;
  flex-direction: row;
`;

export const BarCodeScannerHeaderText = styled.Text`
  color: #fff;
  font-family: "Montserrat_500Medium";
  font-size: 18px;
  flex: 1;
  text-align: center;
`;

export const BarCodeButton = styled.TouchableOpacity`
  padding: 20px;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const BarCodeButtonText = styled.Text`
  font-family: "Montserrat_500Medium";
  color: #ff671d;
`;

export const BarCodeArea = styled.View`
  flex: 1;
  flex-direction: row;
`;

export const BarCodeColumn = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  width: 15%;
`;

export const BarCodeScan = styled.View`
  background-color: rgba(0, 0, 0, 0);
  flex: 1;
`;
