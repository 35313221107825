import React, { useContext } from "react";
import { TextPage, TextPageDot, TitlePage } from "../../styles/typograph";
import { ButtonWrap } from "../../styles/buttons";
import {
  BaseColumn,
  BaseColumnAuto,
  BaseRow,
  ContainerBase,
  ContainerPad,
  GreyContainer,
} from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import { GlobalContext } from "../../contexts/Provider";
import { cpfCnpjMask } from "../../helpers/masks";
import { useToast } from "native-base";
import { Share } from "react-native";
import { ButtonBase } from "../../components/buttons";

const DepositTed = ({ navigation }) => {
  const {
    authState: { authData, loading },
  } = useContext(GlobalContext);
  const { navigate } = navigation;
  const toast = useToast();

  const copyClipboard = (text) => {
    Clipboard.setString(text);
    toast.show({
      description: "Copiado",
      placement: "top",
    });
  };

  const shareAccount = async () => {
    try {
      const result = await Share.share({
        message:
          "450 - Time Pay / Fit Bank, AGÊNCIA:" +
          authData?.user?.account?.branch +
          ", CONTA: " +
          authData?.user?.account?.account +
          "-" +
          authData?.user?.account?.digit,
      });
    } catch (error) {
      toast.show({
        description: "Erro ao compartilhar dados",
        placement: "top",
      });
    }
  };

  const pageContent = (
    <ContainerBase paddingReset>
      <ContainerPad>
        <TitlePage style={{ marginBottom: 10 }}>TED ou DOC</TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          Copie os dados abaixo para fazer uma transferência
        </TextPage>
      </ContainerPad>

      <GreyContainer>
        <BaseRow style={{ marginBottom: 25 }}>
          <BaseColumn>
            <TextPage>Banco</TextPage>
            <TextPage fontWeight="medium">450 - Time Pay / Fit Bank</TextPage>
          </BaseColumn>
          <BaseColumnAuto>
            <ButtonWrap onPress={() => copyClipboard("450")}>
              <Ionicons name="copy-outline" size={24} color="black" />
            </ButtonWrap>
          </BaseColumnAuto>
        </BaseRow>
        <BaseRow style={{ marginBottom: 25 }}>
          <BaseColumn>
            <TextPage>Agência</TextPage>
            <TextPage fontWeight="medium">
              {authData?.user?.account?.branch}
            </TextPage>
          </BaseColumn>
          <BaseColumnAuto>
            <ButtonWrap
              onPress={() => copyClipboard(authData?.user?.account?.branch)}
            >
              <Ionicons name="copy-outline" size={24} color="black" />
            </ButtonWrap>
          </BaseColumnAuto>
        </BaseRow>
        <BaseRow style={{ marginBottom: 25 }}>
          <BaseColumn>
            <TextPage>Conta</TextPage>
            <TextPage fontWeight="medium">
              {authData?.user?.account?.account +
                "-" +
                authData?.user?.account?.digit}
            </TextPage>
          </BaseColumn>
          <BaseColumnAuto>
            <ButtonWrap
              onPress={() =>
                copyClipboard(
                  authData?.user?.account?.account +
                    authData?.user?.account?.digit
                )
              }
            >
              <Ionicons name="copy-outline" size={24} color="black" />
            </ButtonWrap>
          </BaseColumnAuto>
        </BaseRow>
        <BaseRow>
          <BaseColumn>
            <TextPage>Cpf</TextPage>
            <TextPage fontWeight="medium">
              {cpfCnpjMask(authData?.user?.cpf_cnpj)}
            </TextPage>
          </BaseColumn>
          <BaseColumnAuto>
            <ButtonWrap onPress={() => copyClipboard(authData?.user?.cpf_cnpj)}>
              <Ionicons name="copy-outline" size={24} color="black" />
            </ButtonWrap>
          </BaseColumnAuto>
        </BaseRow>
      </GreyContainer>
      <ButtonBase
        style={{ marginTop: 25 }}
        title="Compartilhar conta"
        color="link-orange"
        onPress={() => shareAccount()}
      />
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      paddingResetMobile
    />
  );
};

export default DepositTed;
