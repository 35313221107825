import React, { useState, useEffect, useContext, useRef } from "react";
import { ActivityIndicator } from "react-native";
import { useToast, Image, View } from "native-base";
import { TitlePage, TextPage, TitleOrange } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerLoading,
  FooterBase,
} from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { TextInputBase } from "../../components/inputs";
import { TRANSFER, TRANSFER_ANOTHERBANK } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { cpfCnpjFilterMask } from "../../helpers/masks";
import { clearCaracters } from "../../helpers/format";
import { TemplatePage } from "../../components/templates";
import {
  ProfileContainerBorder,
  ProfileButton,
  ProfileImageButton,
  ProfileContentButton,
} from "./styles";
import { PlaceholderAccounts } from "./components";
import { AntDesign } from "@expo/vector-icons";
import { ModalBase } from "../../components/modals";

const TransferAccounts = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [currentDelete, setCurrentDelete] = useState({});
  const [endData, setEndData] = useState(false);
  const page = useRef(1);
  const toast = useToast();
  const {
    authState: { authData },
  } = useContext(GlobalContext);

  useEffect(() => {
    getAccountList();
  }, []);

  const getAccountList = (next = false) => {
    next ? setLoadingPagination(true) : setLoading(true);
    axiosInstance
      .get("account/get-saved-contacts?page=" + page.current)
      .then((res) => {
        setLoading(false);
        setLoadingPagination(false);
        res.data.length == 0 && setEndData(true);
        setAccountList((oldData) => [...oldData, ...res?.data]);
        page.current += 1;
      })
      .catch((err) => {
        setLoading(false);
        setLoadingPagination(false);
        console.log(err);
      });
  };

  const selectAccount = (account) => {
    navigate(TRANSFER_ANOTHERBANK, { accountSelected: account });
  };

  const removeAccount = (account) => {
    setShowAlert(true);
    setCurrentDelete(account);
  };

  const confirmRemove = () => {
    setShowAlert(false);

    axiosInstance
      .post("account/delete-contact", { id: currentDelete.id })
      .then((res) => {
        let deletedList = accountList.filter(
          (item) => item.id != currentDelete.id
        );
        setAccountList(deletedList);
      })
      .catch((err) => {});
  };

  const viewMore = () => {
    if (!endData && accountList.length >= 15) {
      return (
        <ButtonBase
          onPress={() => getAccountList(true)}
          title="VER MAIS ..."
          color="link"
        />
      );
    }
  };

  const pageContent = (
    <>
      <ContainerBase contentContainerStyle={{ paddingBottom: 25 }}>
        <TitlePage style={{ marginBottom: 25 }}>
          {authData?.user?.name}
        </TitlePage>
        <TextPage style={{ marginBottom: 30 }} fontWeight="medium">
          Essas são todas as suas contas cadastradas
        </TextPage>
        {!loading ? (
          accountList.length == 0 ? (
            <TextPage center>Não foi encontrado nenhuma conta salva</TextPage>
          ) : (
            <>
              {accountList.map((item) => {
                return (
                  <ProfileContainerBorder key={item.id.toString()}>
                    <ProfileImageButton onPress={() => selectAccount(item)}>
                      <Image
                        source={require("../../assets/images/default-photo.png")}
                        size="50"
                        borderRadius="100"
                        alt="photo"
                      />
                    </ProfileImageButton>
                    <ProfileContentButton onPress={() => selectAccount(item)}>
                      <TitleOrange>{item.name}</TitleOrange>
                      <TextPage fontWeight="medium">{item.bank_name}</TextPage>
                      <TextPage>
                        {item.branch +
                          " / " +
                          item.account +
                          " - " +
                          item.digit}
                      </TextPage>
                    </ProfileContentButton>
                    <ProfileButton onPress={() => removeAccount(item)}>
                      <AntDesign name="closecircleo" size={24} color="black" />
                    </ProfileButton>
                  </ProfileContainerBorder>
                );
              })}
              <ContainerLoading>
                {loadingPagination ? (
                  <ActivityIndicator color="#000" />
                ) : (
                  viewMore()
                )}
              </ContainerLoading>
            </>
          )
        ) : (
          <PlaceholderAccounts />
        )}
      </ContainerBase>
      <ModalBase modal={showAlert} setModal={setShowAlert}>
        <TitlePage tiny style={{ marginBottom: 15 }} center>
          Deletar a conta {currentDelete?.account}?
        </TitlePage>
        <View style={{ flexDirection: "row" }}>
          <ButtonBase
            style={{ flex: 1, marginRight: 2.5 }}
            onPress={() => confirmRemove()}
            loading={loading}
            title="Confirmar"
            color="link-orange"
          />
          <ButtonBase
            style={{ flex: 1, marginLeft: 2.5 }}
            onPress={() => setShowAlert(false)}
            loading={loading}
            title="Cancelar"
            color="link"
          />
        </View>
      </ModalBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={TRANSFER}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferAccounts;
