import { View } from "native-base";
import React from "react";

import { ModalBase } from "../../components/modals";
import { TextPage } from "../../styles/typograph";

const TransferAnotherBankAgencyModal = ({ modal, setModal }) => {
  return (
    <ModalBase header="AGÊNCIA" modal={modal} setModal={setModal} type="bottom">
      <TextPage>
        Digite apenas os quatro(4) primeiros números da agência. Você não
        precisa colcar o último dígito
      </TextPage>

      <View style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}>
        <TextPage center>
          <TextPage fontWeight="medium">Exemplo: </TextPage>
          agência 0234-5
        </TextPage>
        <TextPage center>
          você deve digitar
          <TextPage fontWeight="medium"> 0234</TextPage>
        </TextPage>
      </View>
    </ModalBase>
  );
};

export default TransferAnotherBankAgencyModal;
