import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { Text, View } from "react-native";
import { Camera } from "expo-camera";
import { CameraPreview } from "./photoPreview";
import { MaterialIcons } from "@expo/vector-icons";
import {
  PhotoArea,
  PhotoContainer,
  PhotoContentFace,
  PhotoFooter,
  PhotoBordersFace,
  PhotoType,
  ButtonContainer,
  PhotoHeader,
  ButtonHeaderContainer,
} from "./styles";
import { Box, useToast } from "native-base";
import { ButtonBack, ButtonPhoto } from "../../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import {
  REGISTER_INSTRUCTIONS_FACE,
  REGISTER_PERSONAL_DATA,
} from "../../../constants/routes";
import { ButtonBaseContainer } from "../../../styles/buttons";
import { PhotoFaceInstructions } from "./photoInstructions";
import axiosInstance from "../../../helpers/axiosInstance";
import { manipulateAsync } from "expo-image-manipulator";

const RegisterPhotoFace = ({ navigation, route }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [takePicture, setTakePicture] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.front);
  const camera = useRef();
  const [flashMode, setFlashMode] = useState("off");
  const [typePhoto, setTypePhoto] = useState("Registro Facial");
  const [photoInstructionsShow, setPhotoInstructionsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const customer = useRef();
  const { navigate } = useNavigation();
  const toast = useToast();

  useEffect(() => {
    __startCamera();
    startPhotoInstructions();
  }, []);

  useEffect(() => {
    if (route.params?.customer) {
      customer.current = route.params?.customer;
    } else {
      navigate(REGISTER);
    }
  }, [route.params?.customer]);

  const startPhotoInstructions = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setPhotoInstructionsShow(true);
  };

  const __startCamera = async () => {
    const { status } = await Camera.requestPermissionsAsync();

    setHasPermission(status === "granted");
  };

  const __takePicture = async () => {
    setTakePicture(true);
    const photo = await camera.current.takePictureAsync({
      quality: 1,
      base64: true,
    });

    setTakePicture(false);
    setPreviewVisible(true);
    setCapturedImage(photo);
  };

  const __savePhoto = async () => {
    setLoading(true);
    const photoResized = await manipulateAsync(
      capturedImage.uri,
      [{ resize: { height: 1500 } }],
      { base64: true }
    );

    axiosInstance
      .post("customer/face-match", {
        customerId: customer.current.id,
        selfie: photoResized.base64,
      })
      .then((res) => {
        setLoading(false);
        navigate(REGISTER_PERSONAL_DATA, { customer: customer.current });
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };
  const __retakePicture = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    __startCamera();
  };
  const __handleFlashMode = () => {
    if (flashMode === "on") {
      setFlashMode("off");
    } else if (flashMode === "off") {
      setFlashMode("on");
    } else {
      setFlashMode("auto");
    }
  };
  const __switchCamera = () => {
    setCameraType(
      cameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  };

  const boxStyle = { flex: 1, width: "100%", backgroundColor: "transparent" };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <PhotoContainer>
      <PhotoArea>
        {previewVisible && capturedImage ? (
          <CameraPreview
            photo={capturedImage}
            savePhoto={__savePhoto}
            retakePicture={__retakePicture}
            loading={loading}
          />
        ) : (
          <Camera
            type={cameraType}
            flashMode={flashMode}
            style={{ flex: 1 }}
            ref={camera}
          >
            <Box style={boxStyle} safeArea>
              <PhotoHeader>
                <ButtonContainer>
                  <ButtonBack
                    onPress={() => navigate(REGISTER_INSTRUCTIONS_FACE)}
                    color="white"
                    size={24}
                  />
                </ButtonContainer>

                <ButtonHeaderContainer>
                  <ButtonBaseContainer
                    style={{ marginRight: 5 }}
                    onPress={__handleFlashMode}
                  >
                    {flashMode == "on" ? (
                      <MaterialIcons name="flash-on" size={24} color="white" />
                    ) : flashMode == "off" ? (
                      <MaterialIcons name="flash-off" size={24} color="white" />
                    ) : (
                      <MaterialIcons
                        name="flash-auto"
                        size={24}
                        color="white"
                      />
                    )}
                  </ButtonBaseContainer>
                  <ButtonBaseContainer onPress={__switchCamera}>
                    <MaterialIcons
                      name="camera-front"
                      size={24}
                      color="white"
                    />
                  </ButtonBaseContainer>
                </ButtonHeaderContainer>
              </PhotoHeader>
              <PhotoContentFace>
                <PhotoBordersFace></PhotoBordersFace>
              </PhotoContentFace>
              <PhotoFooter>
                <ButtonContainer>
                  <ButtonPhoto disabled={takePicture} onPress={__takePicture} />
                  <PhotoType>{typePhoto}</PhotoType>
                </ButtonContainer>
              </PhotoFooter>
              <PhotoFaceInstructions
                modal={photoInstructionsShow}
                setModal={setPhotoInstructionsShow}
              />
            </Box>
          </Camera>
        )}
      </PhotoArea>
      <StatusBar style="auto" />
    </PhotoContainer>
  );
};

export default RegisterPhotoFace;
