import React, { useEffect } from "react";
import { ActivityIndicator } from "react-native";
import logoutUser from "../../actions/logoutUser";
import { GlobalContext } from "../../contexts/Provider";
import { ContainerBaseCenter } from "../../styles/default";

const Logout = () => {
  const { authDispatch } = React.useContext(GlobalContext);

  useEffect(() => {
    logoutUser(authDispatch);
  }, []);

  return (
    <ContainerBaseCenter>
      <ActivityIndicator />
    </ContainerBaseCenter>
  );
};

export default Logout;
