import React, { useState, useEffect, useContext, useRef } from "react";
import { useToast, Image, View } from "native-base";
import { TitlePage, TextPage, TitleOrange } from "../../styles/typograph";
import { ContainerBase, FooterBase } from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { TextInputBase } from "../../components/inputs";
import {
  DASHBOARD,
  TRANSFER_ACCOUNTS,
  TRANSFER_ANOTHERBANK,
  TRANSFER_FITBANK_DOCUMENT,
  TRANSFER_FITBANK_OPTIONS,
  TRANSFER_MY_ACCOUNTS,
  TRANSFER_PIX,
} from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { cpfCnpjFilterMask } from "../../helpers/masks";
import { clearCaracters } from "../../helpers/format";
import { TemplatePage } from "../../components/templates";
import {
  TransferOptions,
  TransferOptionsButton,
  TextButton,
  ProfileContainerBorder,
  ProfileImage,
  ProfileContent,
  ProfileButton,
} from "./styles";
import { PlaceholderAccounts } from "./components";
import { ModalBase } from "../../components/modals";
import { AntDesign } from "@expo/vector-icons";

const Transfer = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [documentInitial, setDocumentInitial] = useState("");
  const [accountList, setAccountList] = useState();
  const [loading, setLoading] = useState(false);
  const searchTimerRef = useRef(null);
  const [currentDelete, setCurrentDelete] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const toast = useToast();
  const {
    authState: { authData },
  } = useContext(GlobalContext);

  useEffect(() => {
    getAccountList();
  }, []);

  const getAccountList = () => {
    axiosInstance
      .get("account/get-saved-contacts")
      .then((res) => {
        setAccountList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchAccount = (v) => {
    setDocumentInitial(cpfCnpjFilterMask(v));
    searchTimerRef.current && clearTimeout(searchTimerRef.current);
    searchTimerRef.current = setTimeout(() => {
      setAccountList(undefined);
      v == "" || v == undefined
        ? getAccountList()
        : axiosInstance
            .post("account/search-saved-contact", {
              field: clearCaracters(documentInitial),
            })
            .then((res) => {
              setAccountList(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
    }, 1000);
  };

  const selectAccount = (account) => {
    navigate(TRANSFER_ANOTHERBANK, { accountSelected: account });
  };

  const checkAccountExists = () => {
    setLoading(true);
    axiosInstance
      .post("user/find-by-document", {
        cpf_cnpj: clearCaracters(documentInitial),
      })
      .then((res) => {
        setLoading(false);
        res?.data?.cpf_cnpj
          ? navigate(TRANSFER_FITBANK_OPTIONS, {
              ...res?.data,
            })
          : navigate(TRANSFER_ANOTHERBANK);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeAccount = (account) => {
    setShowAlert(true);
    setCurrentDelete(account);
  };

  const confirmRemove = () => {
    setShowAlert(false);

    axiosInstance
      .post("account/delete-contact", { id: currentDelete.id })
      .then((res) => {
        let deletedList = accountList.filter(
          (item) => item.id != currentDelete.id
        );
        setAccountList(deletedList);
      })
      .catch((err) => {});
  };

  const pageContent = (
    <>
      <ContainerBase contentContainerStyle={{ paddingBottom: 25 }}>
        <TransferOptions>
          <TransferOptionsButton onPress={() => navigate(TRANSFER_PIX)}>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={require("../../assets/images/icon-pix-grey.png")}
            />
            <TextButton>Pix</TextButton>
          </TransferOptionsButton>
          <TransferOptionsButton onPress={() => navigate(TRANSFER_MY_ACCOUNTS)}>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={require("../../assets/images/icon-myaccount-grey.png")}
            />
            <TextButton>Minhas contas</TextButton>
          </TransferOptionsButton>
          <TransferOptionsButton
            onPress={() => navigate(TRANSFER_FITBANK_DOCUMENT)}
          >
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={require("../../assets/images/icon-timepay.png")}
            />
            <TextButton>Para Time Pay</TextButton>
          </TransferOptionsButton>
          <TransferOptionsButton onPress={() => navigate(TRANSFER_ANOTHERBANK)}>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={require("../../assets/images/icon-arrow-grey.png")}
            />
            <TextButton>Para outro banco</TextButton>
          </TransferOptionsButton>
          <TransferOptionsButton onPress={() => navigate(TRANSFER_ACCOUNTS)}>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={require("../../assets/images/icon-option-grey.png")}
            />
            <TextButton>Listar contas</TextButton>
          </TransferOptionsButton>
        </TransferOptions>
        <TitlePage style={{ marginBottom: 25 }}>
          Pra quem você quer transferir?
        </TitlePage>
        <TextInputBase
          name="documentInitial"
          label="Nome ou CPF/CNPJ"
          onChangeText={(v) => searchAccount(v)}
          value={documentInitial}
        />
        <TextPage style={{ marginBottom: 10 }} fontWeight="medium">
          Meus contatos
        </TextPage>
        {accountList ? (
          accountList.length == 0 ? (
            <TextPage style={{ marginTop: 25 }} center>
              Não foi encontrado nenhuma conta salva, ao continuar você será
              redirecionado
            </TextPage>
          ) : (
            accountList.map((item) => {
              return (
                <ProfileContainerBorder
                  onPress={() => selectAccount(item)}
                  key={item.id.toString()}
                >
                  <ProfileImage>
                    <Image
                      source={require("../../assets/images/default-photo.png")}
                      size="50"
                      borderRadius="100"
                      alt="photo"
                    />
                  </ProfileImage>
                  <ProfileContent>
                    <TitleOrange>{item.name}</TitleOrange>
                    <TextPage fontWeight="medium">{item.bank_name}</TextPage>
                    <TextPage>
                      {item.branch + " / " + item.account + " - " + item.digit}
                    </TextPage>
                  </ProfileContent>
                  <ProfileButton onPress={() => removeAccount(item)}>
                    <AntDesign name="closecircleo" size={24} color="black" />
                  </ProfileButton>
                </ProfileContainerBorder>
              );
            })
          )
        ) : (
          <PlaceholderAccounts />
        )}
      </ContainerBase>
      <ModalBase modal={showAlert} setModal={setShowAlert}>
        <TitlePage tiny style={{ marginBottom: 15 }} center>
          Deletar a conta {currentDelete?.account}?
        </TitlePage>
        <View style={{ flexDirection: "row" }}>
          <ButtonBase
            style={{ flex: 1, marginRight: 2.5 }}
            onPress={() => confirmRemove()}
            loading={loading}
            title="Confirmar"
            color="link-orange"
          />
          <ButtonBase
            style={{ flex: 1, marginLeft: 2.5 }}
            onPress={() => setShowAlert(false)}
            loading={loading}
            title="Cancelar"
            color="link"
          />
        </View>
      </ModalBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          disabled={documentInitial == ""}
          onPress={() => checkAccountExists()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default Transfer;
