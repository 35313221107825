import styled from "styled-components/native";

export const ButtonContainer = styled.TouchableOpacity`
  text-align: center;
  text-decoration: none;
  padding: 15px;
  border-radius: 25px;
  border-width: 1px;

  ${({ color }) =>
    color == "orange" && "border-color:#ff671d; background-color:#ff671d"};
  ${({ color }) =>
    color == "white" && "border-color:#fff; background-color:#fff"};
  ${({ color }) =>
    color == "outline-white" &&
    "background-color:transparent; border-color:#FFF"};
  ${({ color }) =>
    color == "outline-black" &&
    "background-color:transparent; border-color:#000"};
  ${({ color }) =>
    color == "outline-orange" &&
    "background-color:transparent; border-color:#ff671d"};
  ${({ color }) =>
    color == "yellow" && "background-color:#FFAC1D; border-color:#FFAC1D"};
  ${({ color }) =>
    color == "link" && "background-color:transparent; border-width:0px"};
  ${({ color }) =>
    color == "link-white" && "background-color:transparent; border-width:0px"};
  ${({ color }) =>
    color == "link-grey" && "background-color:transparent; border-width:0px"};
  ${({ color }) =>
    color == "link-orange" && "background-color:transparent; border-width:0px"};
  ${({ tiny }) => tiny && "padding:5px"};

  ${({ disabled, color }) =>
    disabled &&
    !color.includes("link") &&
    "background-color:#DCDCDC; border-color:#DCDCDC"};
`;

export const ButtonText = styled.Text`
  font-size: 14px;
  color: #000;
  text-align: center;
  font-family: "Montserrat_400Regular";
  ${({ textLeft }) => textLeft && "text-align:left"};
  ${({ color }) => color == "orange" && "color:#fff"};
  ${({ color }) => color == "outline-orange" && "color:#ff671d"};
  ${({ color }) => color == "link" && "color:#000"};
  ${({ color }) => color == "link-white" && "color:#FFF"};
  ${({ color }) => color == "link-orange" && "color:#ff671d"};
  ${({ color }) => color == "link-grey" && "color:#666666"};
  ${({ color }) => color == "outline-white" && "color:#FFF"};
  ${({ color }) => color == "white" && "color:#ff671d"};
  ${({ tiny }) => tiny && "font-size: 12px;"};
`;

export const ButtonBaseContainer = styled.TouchableOpacity`
  padding: 5px;
  max-width: 40px;
`;

export const ButtonWrap = styled.TouchableOpacity``;

export const ButtonWrapGrey = styled.TouchableOpacity`
  border-width: 1px;
  border-color: #dddddd;
  padding: 5px 10px;
  border-radius: 5px;
  ${({ selected }) =>
    selected == "1" && "background-color:#ff671d; border-color:#ff671d;"};
`;

export const ButtonMenuContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  max-width: 40px;
`;

export const ButtonIconsContainer = styled.TouchableOpacity`
  flex-direction: row;
  border-width: 1px;
  border-color: #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
`;

export const ButtonIconsLeft = styled.View`
  width: 60px;
  justify-content: center;
  align-items: center;
`;

export const ButtonIconsContent = styled.View`
  flex: 1;
`;

export const ButtonIconsRight = styled.View`
  width: 30px;
  justify-content: center;
  align-items: center;
`;

export const ButtonsGroup = styled.View`
  flex-direction: row;
  align-self: flex-start;
`;
