import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Text, View } from "react-native";
import { Camera } from "expo-camera";
import { CameraPreview } from "./barcodePhotoPreview";
import { MaterialIcons } from "@expo/vector-icons";
import {
  PhotoArea,
  PhotoContainer,
  PhotoContent,
  PhotoFooter,
  PhotoBorders,
  PhotoType,
  ButtonContainer,
  PhotoHeader,
  ButtonHeaderContainer,
} from "./styles";
import { Box, useToast } from "native-base";
import { ButtonBack, ButtonPhoto } from "../../components/buttons";
import { PAYMENT_BARCODE_DATA } from "../../constants/routes";
import { ButtonBaseContainer } from "../../styles/buttons";
import { manipulateAsync } from "expo-image-manipulator";
import { GlobalContext } from "../../contexts/Provider";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import { getExtensionByName } from "../../helpers/format";
import { ContainerBaseCenter } from "../../styles/default";

const barcodePhotoList = ({ navigation, route }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(true);
  const [takePicture, setTakePicture] = useState(false);
  const [capturedImageA, setCapturedImageA] = useState(null);
  const [capturedImageB, setCapturedImageB] = useState(null);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
  const camera = useRef();
  const [flashMode, setFlashMode] = useState("off");
  const [loading, setLoading] = useState(false);
  const typePhoto = useRef("A");
  const { navigate } = navigation;
  const { paymentPhotos, setPaymentPhotos, setPaymentDocument } =
    useContext(GlobalContext);
  const toast = useToast();

  useEffect(() => {
    __startCamera();
  }, []);

  const __startCamera = async () => {
    const { status } = await Camera.requestPermissionsAsync();

    setHasPermission(status === "granted");
  };

  const __takePicture = async () => {
    setTakePicture(true);
    const photo = await camera.current.takePictureAsync({
      base64: true,
    });

    setTakePicture(false);
    setPreviewVisible(true);

    typePhoto.current == "A"
      ? setCapturedImageA(photo)
      : setCapturedImageB(photo);
  };

  const __savePhoto = async () => {
    let photosList = [];
    setLoading(true);
    if (capturedImageA?.uri != undefined) {
      let photoResized = await manipulateAsync(
        capturedImageA.uri,
        [{ resize: { height: 1500 } }],
        { base64: true }
      );
      photosList.push(photoResized);
    }

    if (capturedImageB?.uri != undefined) {
      let photoResized = await manipulateAsync(
        capturedImageB.uri,
        [{ resize: { height: 1500 } }],
        { base64: true }
      );
      photosList.push(photoResized);
    }
    photosList.length != 0 && setPaymentPhotos(photosList);
    setLoading(false);
    navigate(PAYMENT_BARCODE_DATA);
  };

  const pickDocument = async () => {
    setLoading(true);
    const pickerResponse = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      type: "*/*",
    });
    const extension = getExtensionByName(pickerResponse.name);
    const newUri = FileSystem.documentDirectory + pickerResponse.name;
    await FileSystem.copyAsync({
      from: pickerResponse.uri,
      to: newUri,
    });
    const fileBase64 = await FileSystem.readAsStringAsync(newUri, {
      encoding: FileSystem.EncodingType.Base64,
    });
    setPaymentDocument({ base64: fileBase64, ext: extension });
    setLoading(false);
    navigate(PAYMENT_BARCODE_DATA);
  };

  const __retakePicture = () => {
    typePhoto.current == "A"
      ? setCapturedImageA(null)
      : setCapturedImageB(null);
    setPreviewVisible(false);
    __startCamera();
  };

  const __handleFlashMode = () => {
    if (flashMode === "on") {
      setFlashMode("off");
    } else if (flashMode === "off") {
      setFlashMode("on");
    } else {
      setFlashMode("auto");
    }
  };

  const __switchCamera = () => {
    setCameraType(
      cameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  };

  const boxStyle = { flex: 1, width: "100%", backgroundColor: "transparent" };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return (
      <ContainerBaseCenter>
        <Text style={{ flex: 1, textAlign: "center" }}>
          Sem acesso à câmera ...
        </Text>
      </ContainerBaseCenter>
    );
  }

  return (
    <PhotoContainer>
      <PhotoArea>
        {previewVisible ? (
          <CameraPreview
            photoA={capturedImageA}
            photoB={capturedImageB}
            savePhoto={__savePhoto}
            retakePicture={__retakePicture}
            pickDocument={pickDocument}
            loading={loading}
            navigation={navigation}
            typePhoto={typePhoto}
          />
        ) : (
          <Camera
            type={cameraType}
            flashMode={flashMode}
            style={{ flex: 1 }}
            ref={camera}
          >
            <Box style={boxStyle} safeArea>
              <PhotoHeader>
                <ButtonContainer>
                  <ButtonBack
                    onPress={navigation.goBack}
                    color="white"
                    size={24}
                  />
                </ButtonContainer>

                <ButtonHeaderContainer>
                  <ButtonBaseContainer
                    style={{ marginRight: 5 }}
                    onPress={__handleFlashMode}
                  >
                    {flashMode == "on" ? (
                      <MaterialIcons name="flash-on" size={24} color="white" />
                    ) : flashMode == "off" ? (
                      <MaterialIcons name="flash-off" size={24} color="white" />
                    ) : (
                      <MaterialIcons
                        name="flash-auto"
                        size={24}
                        color="white"
                      />
                    )}
                  </ButtonBaseContainer>
                  <ButtonBaseContainer onPress={__switchCamera}>
                    <MaterialIcons
                      name="camera-front"
                      size={24}
                      color="white"
                    />
                  </ButtonBaseContainer>
                </ButtonHeaderContainer>
              </PhotoHeader>
              <PhotoContent>
                <PhotoBorders></PhotoBorders>
              </PhotoContent>
              <PhotoFooter>
                <ButtonContainer>
                  <ButtonPhoto disabled={takePicture} onPress={__takePicture} />
                  <PhotoType>Boleto</PhotoType>
                </ButtonContainer>
              </PhotoFooter>
            </Box>
          </Camera>
        )}
      </PhotoArea>
      <StatusBar style="auto" />
    </PhotoContainer>
  );
};

export default barcodePhotoList;
