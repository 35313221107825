import { Button, Image } from "native-base";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Text, View, useWindowDimensions } from "react-native";
import { Carousel } from "../../components/carousel";
import { TemplateBase } from "../../components/templates";
import { breakpointDown, breakpointUp } from "../../helpers/breakpoints";
import { TextPage, TitlePage } from "../../styles/typograph";
import { AntDesign } from "@expo/vector-icons";
import { TextInputBase } from "../../components/inputs";
import {
  DashboardAside,
  DashboardBannersMobile,
  DashboardBottomGroup,
  DashboardButtonsGroupMobile,
  DashboardContainer,
  DashboardContent,
  DashboardHistoryList,
  DashboardBanners,
} from "./styles";
import axiosInstance from "../../helpers/axiosInstance";
import {
  ButtonFeatured,
  DashboardDesktopButtons,
  DashboardHistoryButton,
  DashboardMobileButtons,
  PlaceholderBanner,
  PlaceholderButtons,
  PlaceholderHistory,
  PlaceholderBannerDesktop,
} from "./components";
import { formatDateDayMonth, formatMoney } from "../../helpers/format";
import { HISTORY } from "../../constants/routes";
import BalanceBox from "../../components/balanceBox";
import {
  HistoryContent,
  HistoryExtra,
  HistoryIcon,
  HistoryIconContainer,
  HistoryItem,
} from "../../styles/default";
import getBalance from "../../actions/getBalance";
import { GlobalContext } from "../../contexts/Provider";

const Dashboard = ({ navigation, route }) => {
  const { width } = useWindowDimensions();
  const { navigate } = navigation;
  const [banners, setBanners] = useState();
  const [buttons, setButtons] = useState();
  const [history, setHistory] = useState();
  const isMounted = useRef(true);
  const searchTimerRef = useRef(null);
  const { setBalance, resetBalanceLastRefresh } = useContext(GlobalContext);

  useEffect(() => {
    getBanners();
    getButtons();
    getHistory();

    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (route.params?.transaction_update) {
      setHistory(undefined);
      setBalance(undefined);
      getHistory();
      getBalance(setBalance);
      resetBalanceLastRefresh();
    }
  }, [route.params?.transaction_update]);

  const getBanners = () => {
    axiosInstance
      .get("banners")
      .then((res) => {
        setBannersCarousel(res.data);
      })
      .catch((err) => {});
  };

  const getButtons = () => {
    axiosInstance
      .get("buttons")
      .then((res) => {
        isMounted.current && setButtons(res.data);
      })
      .catch((err) => {});
  };

  const setBannersCarousel = (data) => {
    let carrousel = [];
    data.map((item, index) => {
      carrousel.push(
        <Image
          key={index}
          resizeMode="contain"
          alt="Banner"
          width={300}
          height={150}
          source={{ uri: item.url }}
        />
      );
    });

    isMounted.current && setBanners(carrousel);
  };

  const getHistory = async () => {
    axiosInstance
      .post("transaction/get")
      .then((res) => {
        if (res?.data !== undefined) {
          let historyData = res?.data.splice(0, 5);
          isMounted.current && setHistory(historyData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchHistory = (v) => {
    searchTimerRef.current && clearTimeout(searchTimerRef.current);
    searchTimerRef.current = setTimeout(() => {
      setHistory(undefined);
      v == "" || v == undefined
        ? getHistory()
        : axiosInstance
            .post("transaction/search", { value: v })
            .then((res) => {
              isMounted.current && setHistory(res?.data);
            })
            .catch((err) => {
              console.log(err);
            });
    }, 2000);
  };

  const getIcon = (type) => {
    switch (type) {
      case 5:
        return require("../../assets/images/icon-deposit.png");
      case 10:
        return require("../../assets/images/icon-payment.png");
      case 15:
        return require("../../assets/images/icon-pix.png");
      case 20:
        return require("../../assets/images/icon-pix.png");
      case 25:
        return require("../../assets/images/icon-transfer.png");
      case 30:
        return require("../../assets/images/icon-transfer.png");
      case 50:
        return require("../../assets/images/icon-card.png");
      case 80:
        return require("../../assets/images/icon-card.png");
      default:
        return require("../../assets/images/icon.png");
    }
  };

  const pageContent = (
    <DashboardContainer mobile={breakpointDown(width, "lg")}>
      <DashboardContent mobile={breakpointDown(width, "lg")}>
        <View style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10 }}>
          <BalanceBox />
        </View>
        {breakpointUp(width, "lg") ? (
          <>
            <DashboardDesktopButtons navigate={navigate} />

            {banners ? (
              <DashboardBanners>
                <Carousel data={banners} showPaginator={false} />
              </DashboardBanners>
            ) : (
              <PlaceholderBannerDesktop />
            )}
          </>
        ) : (
          <>
            <DashboardButtonsGroupMobile>
              <DashboardMobileButtons navigate={navigate} />
            </DashboardButtonsGroupMobile>

            {banners ? (
              <DashboardBannersMobile>
                <Carousel data={banners} showPaginator={false} />
              </DashboardBannersMobile>
            ) : (
              <PlaceholderBanner />
            )}
          </>
        )}
        {buttons ? (
          <DashboardBottomGroup mobile={breakpointDown(width, "lg")}>
            {buttons.map((item, index) => {
              return (
                <ButtonFeatured
                  key={index}
                  title={item.title}
                  description={item.description}
                  extra={item.extra}
                  type={item.type}
                  mobile={breakpointDown(width, "lg")}
                />
              );
            })}
          </DashboardBottomGroup>
        ) : (
          <DashboardBottomGroup mobile={breakpointDown(width, "lg")}>
            <PlaceholderButtons />
          </DashboardBottomGroup>
        )}
      </DashboardContent>
      <DashboardAside mobile={breakpointDown(width, "lg")}>
        <TitlePage style={{ marginBottom: 10 }}>Histórico</TitlePage>

        <TextInputBase
          borderFull
          placeholder="Pesquisar"
          onChangeText={(v) => searchHistory(v)}
          buttonIcon={<AntDesign name="search1" size={18} color="black" />}
        />

        <DashboardHistoryList>
          {history ? (
            history.map((item, index) => {
              return (
                <HistoryItem key={index}>
                  <HistoryIconContainer>
                    <HistoryIcon>
                      <Image
                        resizeMode="contain"
                        alt="Icon pix"
                        size={35}
                        source={getIcon(item.transaction_type_id)}
                      />
                    </HistoryIcon>
                  </HistoryIconContainer>
                  <HistoryContent>
                    <TextPage fontWeight="medium">
                      {item.transaction_type?.name}
                    </TextPage>
                    <TextPage tiny>
                      {item.receiver ? item.receiver.name : "-"}
                    </TextPage>
                    {item.type == "D" ? (
                      <TextPage color="red" tiny>
                        {"-R$" + formatMoney(item.amount)}
                      </TextPage>
                    ) : (
                      <TextPage color="green" tiny>
                        {"R$" + formatMoney(item.amount)}
                        {item.transaction_status_id == "6"
                          ? " - Estornado"
                          : ""}
                      </TextPage>
                    )}
                  </HistoryContent>
                  <HistoryExtra>
                    <TextPage tiny>
                      {formatDateDayMonth(item.created_at)}
                    </TextPage>
                  </HistoryExtra>
                </HistoryItem>
              );
            })
          ) : (
            <PlaceholderHistory />
          )}
        </DashboardHistoryList>
        <DashboardHistoryButton onPress={() => navigate(HISTORY)} />
      </DashboardAside>
    </DashboardContainer>
  );

  return (
    <TemplateBase
      navigation={navigation}
      content={pageContent}
      paddingResetMobile
    />
  );
};

export default Dashboard;
