import React, { useContext, useEffect, useRef, useState } from "react";
import { TextPage, TextPageDot, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerBaseCenter,
  FooterBase,
  GreyContainer,
} from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { DASHBOARD } from "../../constants/routes";
import { TextInputBase } from "../../components/inputs";
import { View } from "react-native";
import { secondsToTime } from "../../helpers/format";
import { useToast } from "native-base";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { TemplatePage } from "../../components/templates";
import { Ionicons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";

const TransactionForgotPassword = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [code, setCode] = useState("");
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [password, setPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFF");
  const [backIconColor, setBackIconColor] = useState("#000");
  const initTransactionPasswordRef = useRef();
  const toast = useToast();
  const timeToWait = 60;
  const [counterLocal, setCounterLocal] = useState(60);
  const isMounted = useRef(true);
  const {
    authState: { authData },
    transactionPasswordCounter,
    initTransactionPasswordCounter,
    resetTransactionPasswordCounter,
  } = useContext(GlobalContext);

  useEffect(() => {
    transactionPasswordCounter.current == timeToWait && sendSms();
    initTransactionPasswordCounter();
    initTransactionPasswordCounterLocal();

    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setCounterLocal(
        transactionPasswordCounter.current <= 0
          ? 1
          : transactionPasswordCounter.current
      );
    });

    return unsubscribe;
  }, [navigation]);

  const initTransactionPasswordCounterLocal = () => {
    if (initTransactionPasswordRef.current == undefined && isMounted.current) {
      initTransactionPasswordRef.current = setInterval(() => {
        setCounterLocal((prev) => prev - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (counterLocal <= 0) {
      setResend(true);
      clearInterval(initTransactionPasswordRef.current);
      initTransactionPasswordRef.current = undefined;
    }
  }, [counterLocal]);

  const sendSms = () => {
    axiosInstance
      .post("customer-auth/send-sms", {
        phone: authData.user.customer.phone,
      })
      .then((res) => {
        toast.show({
          description: "Sms enviado",
          placement: "top",
        });
      })
      .catch((err) => {
        setResend(true);
        toast.show({
          description: "Erro ao enviar o sms",
          placement: "top",
        });
      });
  };

  const resendSms = () => {
    resetTimer();
    initTransactionPasswordCounterLocal();
    sendSms();
    setResend(false);
  };

  const resetTimer = () => {
    resetTransactionPasswordCounter();
    setCounterLocal(timeToWait);
  };

  const validateSms = () => {
    setLoading(true);
    axiosInstance
      .post("customer-auth/validate-sms", {
        code: code,
        customerId: authData.user.customer.id,
      })
      .then((res) => {
        setLoading(false);
        setValidate(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: "Erro ao validar o sms",
          placement: "top",
        });
      });
  };

  const createPassword = () => {
    setLoading(true);
    if (password == confirmPassword) {
      axiosInstance
        .post("user/transaction-password", { password: password })
        .then((res) => {
          setLoading(false);
          setShowSuccess(true);
          setBackgroundColor("#FF671D");
          setBackIconColor("#FFF");
        })
        .catch((err) => {
          toast.show({
            description: "Erro ao alterar senha, contate o suporte",
            placement: "top",
          });
          setLoading(false);
        });
    } else {
      toast.show({
        description: "As senhas devem ser iguais",
        placement: "top",
      });
      setLoading(false);
    }
  };

  const pageValidation = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Redefinir senha</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Insira o código que enviamos para você por SMS
        </TextPage>
        <TextInputBase
          label={"Digite o código"}
          onChangeText={setCode}
          value={code}
          keyboardType="numeric"
        />
        <View style={{ marginTop: 30, marginBottom: 40 }}>
          <TextPage fontWeight="medium" style={{ textAlign: "center" }}>
            {secondsToTime(counterLocal)}
          </TextPage>
          {resend && (
            <ButtonBase
              tiny
              onPress={() => resendSms()}
              title="Não recebeu o código?"
              color={"link-grey"}
            />
          )}
        </View>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          disabled={code === ""}
          title="CONFIMAR"
          onPress={() => validateSms()}
          color={"orange"}
        />
      </FooterBase>
    </>
  );

  const pagePassword = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Redefinir senha</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Crie uma senha para efetuar o login
        </TextPage>
        <TextInputBase
          keyboardType="numeric"
          maxLength={4}
          label={"Digite a senha"}
          onChangeText={setPassword}
          buttonIcon={
            passwordVisible ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )
          }
          buttonOnPress={() => setPasswordVisible(!passwordVisible)}
          secureTextEntry={!passwordVisible}
          value={password}
        />

        <GreyContainer>
          <TextPage tiny style={{ marginBottom: 10 }}>
            Sua senha precisa ter:
          </TextPage>
          <TextPageDot>4 dígitos</TextPageDot>
          <TextPageDot style={{ marginBottom: 10 }}>
            Somente números
          </TextPageDot>
          <TextPage tiny>
            Não use números sequenciais ou a data do seu aniversário.
          </TextPage>
        </GreyContainer>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          onPress={() => setShowConfirmPassword(true)}
          disabled={password === ""}
          title="CRIAR SENHA"
          color={"orange"}
        />
      </FooterBase>
    </>
  );

  const pageConfirmPassword = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Confirmar senha</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Digite a sua senha novamente
        </TextPage>
        <TextInputBase
          keyboardType="numeric"
          maxLength={4}
          label={"Digite a senha"}
          onChangeText={setConfirmPassword}
          buttonIcon={
            passwordVisible ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )
          }
          buttonOnPress={() => setPasswordVisible(!passwordVisible)}
          secureTextEntry={!passwordVisible}
          value={confirmPassword}
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => createPassword()}
          loading={loading}
          disabled={confirmPassword === ""}
          title="CONFIRMAR"
          color={"orange"}
        />
      </FooterBase>
    </>
  );

  const pageSuccess = (
    <>
      <ContainerBaseCenter>
        <Feather name="check-circle" size={40} color="#FFF" />
        <TitlePage
          style={{ marginBottom: 10, marginTop: 15 }}
          color="white"
          center
        >
          Senha criada com sucesso!
        </TitlePage>
        <TextPage center color="white">
          Use essa senha para confirmar suas transações no app.
        </TextPage>
      </ContainerBaseCenter>
      <FooterBase>
        <ButtonBase
          color="outline-white"
          title="CONTINUAR"
          onPress={() => navigate(DASHBOARD)}
        />
      </FooterBase>
    </>
  );

  const pageContent = () => {
    if (showSuccess) return pageSuccess;
    if (showConfirmPassword) return pageConfirmPassword;
    if (validate) return pagePassword;

    return pageValidation;
  };

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent()}
      backNavigate={DASHBOARD}
      fullScreen={true}
      paddingResetMobile
      backgroundColor={backgroundColor}
      backIconColor={backIconColor}
      closeIcon={true}
    />
  );
};

export default TransactionForgotPassword;
