import React, { useEffect, useRef, useState } from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import * as Location from "expo-location";
import { ContainerBorder } from "./styles";
import { useToast } from "native-base";
import { PlaceholderBanks } from "./components";
import { cepMask } from "../../helpers/masks";
import axiosInstance from "../../helpers/axiosInstance";

const WithDrawBankList = ({ navigation }) => {
  const location = useRef(null);
  const toast = useToast();
  const [loading, setLoading] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      toast.show({
        description: "Sem permissão",
        placement: "top",
      });
    }
    location.current = await Location.getCurrentPositionAsync({});
    getBankList();
  };

  const getBankList = () => {
    setLoading(true);
    axiosInstance
      .post("withdraw/get-terminals", {
        latitude: location.current?.coords?.latitude,
        longitude: location.current?.coords?.longitude,
      })
      .then((res) => {
        setLoading(false);
        setBanks(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 10 }}>Lista de Bancos</TitlePage>
      <TextPage style={{ marginBottom: 30 }}>
        Lista de bancos próximos a você
      </TextPage>
      {loading ? (
        <PlaceholderBanks />
      ) : banks.length == 0 ? (
        <TextPage style={{ marginTop: 25 }} center>
          Nenhum banco foi encontrado
        </TextPage>
      ) : (
        banks.map((item, index) => {
          return (
            <ContainerBorder key={index}>
              <TextPage fontWeight="medium">{item.SegmentType}</TextPage>
              <TextPage>
                {item?.AddressLine + ", " + item?.AddressNumber}
              </TextPage>
              <TextPage>
                {item?.Neightborhood + " | " + cepMask(item?.ZipCode)}
              </TextPage>
            </ContainerBorder>
          );
        })
      )}
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      paddingResetMobile
    />
  );
};

export default WithDrawBankList;
