import React from "react";
import { Box } from "native-base";
import { useNavigation } from "@react-navigation/core";
import { ContainerBaseCenter, FooterBase } from "../../../styles/default";
import { SuccessContainer } from "./styles";
import { TextPage, TitlePage } from "../../../styles/typograph";
import { Text, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { ButtonBase } from "../../../components/buttons";
import { CARDS, LOGIN } from "../../../constants/routes";

const NewCardSuccess = ({ navigation }) => {
  const { navigate } = useNavigation();

  return (
    <Box style={{ flex: 1, backgroundColor: "#FF671D" }} safeArea>
      <ContainerBaseCenter>
        <SuccessContainer>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Feather name="check-circle" size={40} color="white" />
          </View>
          <TitlePage
            style={{ marginBottom: 10, marginTop: 15 }}
            color="white"
            center
          >
            Cartão solicitado com sucesso
          </TitlePage>
          <TextPage color="white" center style={{ marginBottom: 10 }}>
            Acompanhe em cartões a sua emissão.
          </TextPage>
          <TextPage color="white" center>
            O cartão 8000001689 foi solicitado com sucesso no dia 06/04/2022 às
            19:36h.
          </TextPage>
        </SuccessContainer>
      </ContainerBaseCenter>
      <FooterBase>
        <ButtonBase
          color="outline-white"
          title="VISUALIZAR CARTÕES"
          onPress={() => navigate(CARDS)}
        />
      </FooterBase>
    </Box>
  );
};

export default NewCardSuccess;
