import styled from "styled-components/native";

export const HeaderTemplate = styled.View`
  background-color: #ff671d;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

export const HeaderGroup = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const BellContainer = styled.View`
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
`;

export const BellCounter = styled.View`
  position: absolute;
  top: -2.5px;
  right: -2.5px;
  background-color: #fff;
  border-radius: 100px;
  width: 13px;
  height: 13px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: #ff671d;
`;

export const BellNumber = styled.Text`
  color: #ff671d;
  font-size: 9px;
  text-align: center;
  font-family: "Montserrat_500Medium";
`;
