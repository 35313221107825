import React, { useContext } from "react";
import { TextPage, TextPageDot, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import { ButtonBase } from "../../components/buttons";
import { GlobalContext } from "../../contexts/Provider";
import { formatMoney } from "../../helpers/format";
import { WITHDRAW_BANK_LIST } from "../../constants/routes";

const WithDrawBankInfo = ({ navigation }) => {
  const { navigate } = navigation;
  const { settings } = useContext(GlobalContext);

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 30 }}>
        Saque em Banco 24 horas
      </TitlePage>
      <TextPage style={{ marginBottom: 25 }}>
        A conta Time Pay permite o saque na rede 24 horas de todo o Brasil.
      </TextPage>
      <TextPage>Passo a passo:</TextPage>
      <TextPageDot>* Utilize seus cartão no terminal;</TextPageDot>
      <TextPageDot>* Digite a senha de 4 dígitos do cartão;</TextPageDot>
      <TextPageDot>* Selecione a opção "Saque crédito";</TextPageDot>
      <TextPageDot style={{ marginBottom: 25 }}>
        * Digite o valor e confirme.
      </TextPageDot>
      <TextPage style={{ marginBottom: 25 }}>
        A utilização destes ATM é apenas para realizar saques, sendo permitido o
        valor máximo de R$ {formatMoney(settings?.fee_withdraw_max_value)} por
        dia ou conforme horários.
      </TextPage>
      <TextPage>Taxa de saque.</TextPage>
      <TextPage style={{ marginBottom: 40 }}>
        Será cobrado o valor de R$ {formatMoney(settings?.fee_withdraw)} por
        saque.
      </TextPage>
      <ButtonBase
        onPress={() => navigate(WITHDRAW_BANK_LIST)}
        title="CONSULTAR REDE BANCO 24 HORAS"
        color="orange"
      />
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      paddingResetMobile
    />
  );
};

export default WithDrawBankInfo;
