import React, { useState, useEffect, useContext } from "react";
import { Flex, Image, useToast, View } from "native-base";
import { TextPage, TitlePage } from "../../../styles/typograph";
import {
  ContainerBase,
  ContainerBorder,
  ContainerBorderless,
  FooterBase,
  GreyContainer,
} from "../../../styles/default";
import { ButtonBase } from "../../../components/buttons";
import {
  CARDS,
  CARDS_NEW_SUCCESS,
  TRANSACTION_FORGOT_PASSWORD,
} from "../../../constants/routes";
import axiosInstance from "../../../helpers/axiosInstance";
import { GlobalContext } from "../../../contexts/Provider";
import { TemplatePage } from "../../../components/templates";
import { ModalBase } from "../../../components/modals";
import { Ionicons } from "@expo/vector-icons";
import { TextInputBase } from "../../../components/inputs";

const NewCardConfirm = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [cardData, setCardData] = useState({});
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const toast = useToast();
  const {
    authState: { authData },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (route.params) {
      setCardData(route.params);
    }
  }, [route.params]);

  const handleModal = () => {
    setModal(false);
    navigate(CARDS_NEW_SUCCESS);
  };

  const pageContent = (
    <>
      <ContainerBase paddingReset>
        <TitlePage style={{ marginBottom: 5, marginLeft: 15 }}>
          Confirmar dados
        </TitlePage>
        <TextPage big style={{ marginBottom: 5, marginLeft: 15 }}>
          Verifique se todos os dados foram informados corretamente.
        </TextPage>

        <ContainerBorder>
          <TextPage>Nome para o cartão</TextPage>
          <TextPage fontWeight="medium">{cardData?.name}</TextPage>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Endereço de entrega</TextPage>
          <TextPage fontWeight="medium">
            {cardData?.registerType == 2 ? "Outro endereço" : "Do cadastro"}
          </TextPage>
        </ContainerBorderless>

        <GreyContainer>
          <TextPage>Endereço</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            {cardData?.address}
          </TextPage>

          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Número</TextPage>
              <TextPage TextPage="medium">{cardData?.number}</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>CEP</TextPage>
              <TextPage TextPage="medium">{cardData?.zipcode}</TextPage>
            </View>
          </Flex>

          <TextPage>Complemento</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            {cardData?.complement}
          </TextPage>

          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Bairro</TextPage>
              <TextPage TextPage="medium">{cardData?.neighborhood}</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>Cidade</TextPage>
              <TextPage TextPage="medium">{cardData?.city}</TextPage>
            </View>
          </Flex>
          <Flex direction="row" space={3}>
            <View style={{ flex: 1 }}>
              <TextPage>Estado</TextPage>
              <TextPage TextPage="medium">{cardData?.state}</TextPage>
            </View>
            <View style={{ flex: 1 }}></View>
          </Flex>
        </GreyContainer>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => setModal(true)}
          title="CONFIRMAR"
          color="orange"
        />
      </FooterBase>
      <ModalBase
        header="DIGITE A SUA SENHA"
        modal={modal}
        setModal={setModal}
        type="bottom"
      >
        <TextInputBase
          label={"Digite a senha de transações"}
          onChangeText={setPassword}
          buttonIcon={
            passwordVisible ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )
          }
          buttonOnPress={() => setPasswordVisible(!passwordVisible)}
          secureTextEntry={!passwordVisible}
          value={password}
          maxLength={4}
        />
        <ButtonBase
          textLeft
          onPress={() => {
            setModal(false);
            navigate(TRANSACTION_FORGOT_PASSWORD);
          }}
          style={{ marginBottom: 15 }}
          title="Esqueci a senha"
          color="link"
        />
        <ButtonBase
          onPress={() => {
            handleModal();
          }}
          title="CONFIRMAR"
          color="orange"
        />
      </ModalBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={CARDS}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default NewCardConfirm;
