import styled from "styled-components/native";

export const BarCodeScannerContainer = styled.View`
  flex: 1;
`;

export const BarCodeScannerHeader = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  height: 90px;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  padding-top: 15px;
`;

export const BarCodeScannerFooter = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  height: 90px;
  align-items: flex-end;
  flex-direction: row;
`;

export const BarCodeScannerHeaderText = styled.Text`
  color: #fff;
  font-family: "Montserrat_500Medium";
  font-size: 20px;
  flex: 1;
  text-align: center;
`;

export const BarCodeButton = styled.TouchableOpacity`
  padding: 20px;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const BarCodeButtonText = styled.Text`
  font-family: "Montserrat_500Medium";
  color: #ff671d;
`;

export const RowBarCodeData = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ColBarCodeData = styled.View`
  width: 45%;
`;

//PHOTO

export const PhotoContainer = styled.View`
  flex: 1;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;

export const PhotoPreviewContainer = styled.View`
  flex: 1;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PhotoArea = styled.View`
  flex: 1;
  width: 100%;
`;

export const PhotoGrid = styled.View`
  flex: 1;
  width: 100%;
  background-color: transparent;
  padding-top: 16px;
`;

export const PhotoHeader = styled.View`
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export const PhotoContent = styled.View`
  height: 75%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const PhotoContentFace = styled.View`
  height: 75%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const PhotoBorders = styled.View`
  border-color: #fff;
  border-width: 1px;
  border-style: dashed;
  border-radius: 2.5px;
  height: 100%;
`;

export const PhotoBordersFace = styled.View`
  border-color: #fff;
  border-width: 1px;
  border-style: dashed;
  border-radius: 125px;
  height: 100%;
`;

export const PhotoFooter = styled.View`
  height: 25%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonContainer = styled.View`
  flex: 0 1 auto;
  justify-content: center;
`;

export const ButtonHeaderContainer = styled.View`
  flex: 0 1 auto;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonPhotoStyle = styled.TouchableOpacity`
  background-color: #ec621e;
  border-radius: 50px;
  color: #fff;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const PhotoType = styled.Text`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
`;

export const ImageRow = styled.View`
  justify-content: space-around;
  flex-direction: row;
`;

export const ImageContainer = styled.TouchableOpacity`
  background-color: #cccccc;
  width: 125px;
  height: 150px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

export const ImagePreview = styled.Image`
  width: 125px;
  height: 150px;
  align-self: center;
  border-radius: 10px;
`;

export const ImageDefaultText = styled.Text`
  color: #fff;
  font-family: "Montserrat_500Medium";
  font-size: 16px;
`;
