import React from "react";
import { ModalBase } from "../../../components/modals";
import { Image, Text } from "react-native";
import { ButtonBase } from "../../../components/buttons";

export const PhotoInstructions = ({
  modal,
  setModal,
  typePhoto,
  getTypePhotoTitle,
}) => {
  const getImage = () => {
    switch (typePhoto) {
      case "RGF":
        return require("../../../assets/images/photo-rg-front.jpg");
      case "RGV":
        return require("../../../assets/images/photo-rg-back.jpg");
      case "COMP":
        return require("../../../assets/images/photo-proof.png");
      case "CNH":
        return require("../../../assets/images/photo-cnh-front.jpg");
      default:
        return "";
    }
  };

  return (
    <ModalBase modal={modal} setModal={setModal} maxWidth={300} type="default">
      <Image
        style={{
          width: 100,
          height: 150,
          resizeMode: "contain",
          alignSelf: "center",
          marginTop: 30,
        }}
        source={getImage()}
      />
      <Text style={{ textAlign: "center", marginTop: 10, fontWeight: "bold" }}>
        {getTypePhotoTitle(typePhoto)}
      </Text>
      <Text style={{ textAlign: "center", marginTop: 5 }}>
        {typePhoto == "CNH"
          ? "Posicione o documento aberto em uma mesa, centralize-o na marcação e tire a foto."
          : "Posicione o documento em uma mesa, centralize-o na marcação e tire a foto."}
      </Text>
      <ButtonBase
        style={{ marginTop: 15 }}
        onPress={() => setModal(false)}
        title="OK, entendi"
        color="orange"
      />
    </ModalBase>
  );
};

export const PhotoFaceInstructions = ({ modal, setModal }) => {
  return (
    <ModalBase modal={modal} setModal={setModal} maxWidth={300} type="default">
      <Image
        style={{
          width: 100,
          height: 150,
          resizeMode: "contain",
          alignSelf: "center",
          marginTop: 30,
        }}
        source={require("../../../assets/images/photo-selfie.png")}
      />
      <Text style={{ textAlign: "center", marginTop: 10, fontWeight: "bold" }}>
        REGISTRO FACIAL
      </Text>
      <Text style={{ textAlign: "center", marginTop: 5 }}>
        Centralize seu rosto na marcação e tire uma foto.
      </Text>
      <ButtonBase
        style={{ marginTop: 15 }}
        onPress={() => setModal(false)}
        title="OK, entendi"
        color="orange"
      />
    </ModalBase>
  );
};
