import React, { useRef, useState, useEffect, useContext } from "react";
import { ImageBackground, Image, useWindowDimensions } from "react-native";
import BackgroundImg from "../../assets/images/login-background.jpg";
import Logo from "../../assets/images/logo.png";
import {
  LoginContainer,
  LoginDesktopContainer,
  LoginImageLogo,
  LogoContainer,
  LoginRow,
  LoginWhiteBox,
} from "./styles";
import { ButtonBase } from "../../components/buttons";
import { AntDesign } from "@expo/vector-icons";
import { BENEFITS, FORGOT_PASSWORD, REGISTER } from "../../constants/routes";
import { Box, useToast } from "native-base";
import { TitlePage } from "../../styles/typograph";
import CpfModal from "./cpfModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { breakpointUp } from "../../helpers/breakpoints";
import { Formik } from "formik";
import { TextInputBase } from "../../components/inputs";
import { YUP_MESSAGES } from "../../constants/validationMessages";
import * as yup from "yup";
import { GlobalContext } from "../../contexts/Provider";
import { clearCaracters } from "../../helpers/format";
import { cpfCnpjMask } from "../../helpers/masks";
import loginUser from "../../actions/loginUser";

const Login = ({ navigation, route }) => {
  const { width } = useWindowDimensions();
  const iconButton = <AntDesign name="doubleright" size={14} color="white" />;
  const { navigate } = navigation;
  const [showModal, setShowModal] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(true);
  const isMounted = useRef(true);
  const [initialValues, setInitialValues] = useState({
    usernameDesktop: "",
    passwordDesktop: "",
  });
  const {
    authDispatch,
    authState: { error, loading },
  } = useContext(GlobalContext);
  const toast = useToast();

  const loginValidationSchema = yup.object().shape({
    usernameDesktop: yup.string().required(YUP_MESSAGES.required),
    passwordDesktop: yup.string().required(YUP_MESSAGES.required),
  });

  const getCustomer = async () => {
    try {
      let documentAS = await AsyncStorage.getItem("cpf_cnpj");
      let customerAS = JSON.parse(await AsyncStorage.getItem("customer"));
      if (isMounted.current) {
        documentAS && setUsername(documentAS);
        setCustomer(customerAS);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCustomer();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (remember) {
      if (username != "" && username != undefined && username.length >= 11) {
        AsyncStorage.setItem("cpf_cnpj", username);
      }
    } else {
      AsyncStorage.removeItem("cpf_cnpj");
    }
  }, [username, remember]);

  useEffect(() => {
    if (route.params?.cleanCustomer) {
      AsyncStorage.removeItem("customer");
      setCustomer(false);
    }
  }, [route.params?.cleanCustomer]);

  useEffect(() => {
    if (error?.error) {
      toast.show({
        description: error?.error,
        placement: "top",
      });
    }
  }, [error]);

  const newAccount = () => {
    AsyncStorage.removeItem("customer");
    navigate(REGISTER);
  };

  const onSubmitForm = (values) => {
    loginUser(
      {
        username: clearCaracters(values.usernameDesktop),
        password: values.passwordDesktop,
      },
      authDispatch
    );
  };

  return (
    <Box style={{ flex: 1 }} safeArea>
      {breakpointUp(width, "lg") ? (
        <LoginDesktopContainer>
          <LoginImageLogo source={Logo} />
          <LoginRow>
            <LoginWhiteBox>
              <TitlePage style={{ marginBottom: 50 }} center>
                FAÇA SEU LOGIN
              </TitlePage>
              <Formik
                enableReinitialize
                validateOnMount={true}
                validationSchema={loginValidationSchema}
                initialValues={initialValues}
                onSubmit={(values) => onSubmitForm(values)}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  errors,
                  touched,
                  isValid,
                }) => (
                  <>
                    <TextInputBase
                      name={"usernameDesktop"}
                      label={"CPF"}
                      onChangeText={(v) => {
                        setFieldValue("usernameDesktop", cpfCnpjMask(v));
                        setFieldTouched("usernameDesktop", true, false);
                      }}
                      onBlur={handleBlur("usernameDesktop")}
                      value={values.usernameDesktop}
                      errors={errors.usernameDesktop}
                      touched={touched.usernameDesktop}
                    />

                    <TextInputBase
                      name={"passwordDesktop"}
                      label={"SENHA"}
                      onChangeText={handleChange("passwordDesktop")}
                      onBlur={handleBlur("passwordDesktop")}
                      value={values.passwordDesktop}
                      errors={errors.passwordDesktop}
                      touched={touched.passwordDesktop}
                      secureTextEntry={true}
                    />

                    <ButtonBase
                      style={{ marginTop: 25, marginBottom: 25 }}
                      title="ENTRAR"
                      color="orange"
                      onPress={handleSubmit}
                      loading={loading}
                      disabled={!isValid}
                    />
                    <ButtonBase
                      style={{ marginBottom: 10 }}
                      tiny
                      title="Esqueci a senha"
                      color="link-orange"
                      onPress={() => navigate(FORGOT_PASSWORD)}
                      iconRight={
                        <AntDesign name="right" size={12} color="#ff671d" />
                      }
                    />
                    <ButtonBase
                      tiny
                      title="Abra uma conta"
                      iconRight={
                        <AntDesign name="right" size={12} color="#ff671d" />
                      }
                      color="link-orange"
                    />
                  </>
                )}
              </Formik>
            </LoginWhiteBox>
          </LoginRow>
        </LoginDesktopContainer>
      ) : (
        <ImageBackground
          source={BackgroundImg}
          style={{
            flex: 1,
            resizeMode: "cover",
            justifyContent: "flex-end",
          }}
        >
          <LogoContainer>
            <Image
              source={Logo}
              style={{
                width: 250,
                height: 50,
                resizeMode: "contain",
              }}
            />
          </LogoContainer>
          <LoginContainer>
            {customer && (
              <ButtonBase
                style={{ marginBottom: 10 }}
                title="NOVA CONTA"
                color="orange"
                onPress={() => newAccount()}
              />
            )}
            <ButtonBase
              style={{ marginBottom: 10 }}
              title={customer ? "CONTINUAR" : "ABRIR CONTA"}
              color="orange"
              onPress={() => navigate(REGISTER)}
            />
            <ButtonBase
              style={{ marginBottom: 10 }}
              title="JÁ TENHO CONTA"
              color="outline-white"
              onPress={() => setShowModal(true)}
            />
            <ButtonBase
              title="Conheça os benefícios"
              color="link-white"
              onPress={() => navigate(BENEFITS)}
              iconLeft={iconButton}
            />
          </LoginContainer>
          <CpfModal
            modal={showModal}
            setModal={setShowModal}
            remember={remember}
            setRemember={setRemember}
            username={username}
            setUsername={setUsername}
            navigate={navigate}
          />
        </ImageBackground>
      )}
    </Box>
  );
};

export default Login;
