import React, { useContext } from "react";
import {
  Text,
  View,
  useWindowDimensions,
  Button,
  StyleSheet,
} from "react-native";
import { breakpointUp, breakpointDown } from "../helpers/breakpoints";
import SideMenu from "../navigators/SideMenu";
import {
  BellContainer,
  BellCounter,
  BellNumber,
  HeaderGroup,
  HeaderTemplate,
} from "../styles/templates";
import { Box, Image } from "native-base";
import { ButtonBack, ButtonBase, ButtonMenu, ButtonClose } from "./buttons";
import { AntDesign, Feather } from "@expo/vector-icons";
import {
  DesktopBaseContainer,
  FullScreenContainer,
  HeaderBase,
  HeaderBaseClose,
  PageContainer,
  PageContainerMobile,
  SideMenuContainer,
  PageContainerMobileFullScreen,
  PageContainerFullScreen,
} from "../styles/default";
import * as Linking from "expo-linking";
import { GlobalContext } from "../contexts/Provider";
import { NOTIFICATION } from "../constants/routes";

export const TemplateBase = ({
  navigation,
  content,
  paddingResetMobile,
  backgroundColor = "#FFF",
}) => {
  const { width } = useWindowDimensions();
  const { countNotification } = useContext(GlobalContext);
  const { navigate } = navigation;

  return (
    <Box style={{ flex: 1, backgroundColor: backgroundColor }} safeArea>
      <HeaderTemplate>
        <HeaderGroup>
          {breakpointDown(width, "xl") && (
            <View style={{ paddingRight: 10 }}>
              <ButtonMenu
                icon={<Feather name="menu" size={26} color="white" />}
                onPress={() => navigation.openDrawer()}
              />
            </View>
          )}
          <View style={{ paddingTop: 5 }}>
            <Image
              resizeMode="contain"
              size={125}
              source={require("../assets/images/logo.png")}
              alt="Logo"
            />
          </View>
        </HeaderGroup>
        <HeaderGroup>
          <View>
            <ButtonMenu
              onPress={() =>
                Linking.openURL("https://centraldeajuda.timepay.com.br/")
              }
              icon={
                <AntDesign name="questioncircleo" size={22} color="white" />
              }
            />
          </View>
          <BellContainer>
            {countNotification && (
              <BellCounter>
                <BellNumber>{countNotification}</BellNumber>
              </BellCounter>
            )}
            <ButtonMenu
              onPress={() => navigate(NOTIFICATION)}
              icon={<Feather name="bell" size={22} color="white" />}
            />
          </BellContainer>
          <View>
            <ButtonBase
              iconRight={<AntDesign name="down" size={12} color="#ff671d" />}
              tiny
              color="white"
              title="Contas"
            />
          </View>
        </HeaderGroup>
      </HeaderTemplate>
      {breakpointUp(width, "xl") ? (
        <DesktopBaseContainer>
          <SideMenuContainer>
            <SideMenu navigation={navigation} />
          </SideMenuContainer>
          <FullScreenContainer>
            <PageContainer>{content}</PageContainer>
          </FullScreenContainer>
        </DesktopBaseContainer>
      ) : (
        <FullScreenContainer>
          <PageContainerMobile paddingResetMobile={paddingResetMobile}>
            {content}
          </PageContainerMobile>
        </FullScreenContainer>
      )}
    </Box>
  );
};

export const TemplatePage = ({
  navigation,
  content,
  paddingResetMobile,
  backNavigate,
  goBack = false,
  infiniteScroll = false,
  fullScreen = false,
  closeIcon = false,
  backgroundColor = "#FFF",
  backIconColor = "black",
  dataNavigate = {},
}) => {
  const { width } = useWindowDimensions();
  const { navigate } = navigation;
  const { countNotification } = useContext(GlobalContext);

  return (
    <Box style={{ flex: 1, backgroundColor: backgroundColor }} safeArea>
      {breakpointUp(width, "lg") ? (
        <>
          <HeaderTemplate>
            <HeaderGroup>
              {breakpointDown(width, "xl") && (
                <View style={{ paddingRight: 10 }}>
                  <ButtonMenu
                    icon={<Feather name="menu" size={26} color="white" />}
                    onPress={() => navigation.openDrawer()}
                  />
                </View>
              )}
              <View style={{ paddingTop: 5 }}>
                <Image
                  resizeMode="contain"
                  size={125}
                  source={require("../assets/images/logo.png")}
                  alt="Logo"
                />
              </View>
            </HeaderGroup>
            <HeaderGroup>
              <View>
                <ButtonMenu
                  onPress={() =>
                    Linking.openURL("https://centraldeajuda.timepay.com.br/")
                  }
                  icon={
                    <AntDesign name="questioncircleo" size={22} color="white" />
                  }
                />
              </View>
              <BellContainer>
                {countNotification && (
                  <BellCounter>
                    <BellNumber>{countNotification}</BellNumber>
                  </BellCounter>
                )}
                <ButtonMenu
                  onPress={() => navigate(NOTIFICATION)}
                  icon={<Feather name="bell" size={22} color="white" />}
                />
              </BellContainer>
              <View>
                <ButtonBase
                  iconRight={
                    <AntDesign name="down" size={12} color="#ff671d" />
                  }
                  tiny
                  color="white"
                  title="Contas"
                />
              </View>
            </HeaderGroup>
          </HeaderTemplate>

          <DesktopBaseContainer>
            {breakpointUp(width, "xl") && (
              <SideMenuContainer>
                <SideMenu navigation={navigation} />
              </SideMenuContainer>
            )}

            <FullScreenContainer>
              {infiniteScroll ? (
                content
              ) : fullScreen ? (
                <PageContainerFullScreen>{content}</PageContainerFullScreen>
              ) : (
                <PageContainer>{content}</PageContainer>
              )}
            </FullScreenContainer>
          </DesktopBaseContainer>
        </>
      ) : (
        <>
          {closeIcon ? (
            <HeaderBaseClose>
              <ButtonClose
                onPress={() => {
                  return goBack
                    ? navigation.goBack()
                    : navigate(backNavigate, dataNavigate);
                }}
                color={backIconColor}
                size={24}
              />
            </HeaderBaseClose>
          ) : (
            <HeaderBase>
              <ButtonBack
                onPress={() => {
                  return goBack
                    ? navigation.goBack()
                    : navigate(backNavigate, dataNavigate);
                }}
                color={backIconColor}
                size={24}
              />
            </HeaderBase>
          )}

          <FullScreenContainer>
            {infiniteScroll ? (
              content
            ) : fullScreen ? (
              <PageContainerMobileFullScreen
                paddingResetMobile={paddingResetMobile}
              >
                {content}
              </PageContainerMobileFullScreen>
            ) : (
              <PageContainerMobile paddingResetMobile={paddingResetMobile}>
                {content}
              </PageContainerMobile>
            )}
          </FullScreenContainer>
        </>
      )}
    </Box>
  );
};
