import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { Text, View } from "react-native";
import { Camera } from "expo-camera";
import { MaterialIcons } from "@expo/vector-icons";
import {
  PhotoArea,
  PhotoContainer,
  PhotoContentFace,
  PhotoFooter,
  PhotoBordersFace,
  PhotoType,
  ButtonContainer,
  PhotoHeader,
  ButtonHeaderContainer,
} from "./styles";
import { Box, Image, useToast } from "native-base";
import {
  ButtonBack,
  ButtonBase,
  ButtonPhoto,
} from "../../../components/buttons";
import {
  CARDS_SUCCESS,
  REGISTER_INSTRUCTIONS_FACE,
} from "../../../constants/routes";
import { ButtonBaseContainer } from "../../../styles/buttons";
import { manipulateAsync } from "expo-image-manipulator";
import { ModalBase } from "../../../components/modals";

const CardFace = ({ navigation, route }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [takePicture, setTakePicture] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.front);
  const camera = useRef();
  const [flashMode, setFlashMode] = useState("off");
  const [typePhoto, setTypePhoto] = useState("Registro Facial");
  const [photoInstructionsShow, setPhotoInstructionsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { navigate } = navigation;
  const toast = useToast();

  useEffect(() => {
    __startCamera();
    startPhotoInstructions();
  }, []);

  useEffect(() => {}, []);

  const startPhotoInstructions = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setPhotoInstructionsShow(true);
  };

  const __startCamera = async () => {
    const { status } = await Camera.requestPermissionsAsync();

    setHasPermission(status === "granted");
  };

  const __takePicture = async () => {
    setTakePicture(true);
    const photo = await camera.current.takePictureAsync({
      quality: 1,
      base64: true,
    });

    setTakePicture(false);
    setCapturedImage(photo);
    navigate(CARDS_SUCCESS);
  };

  const __savePhoto = async () => {
    setLoading(true);
    const photoResized = await manipulateAsync(
      capturedImage.uri,
      [{ resize: { height: 1500 } }],
      { base64: true }
    );
    console.log(photoResized.base64);
    navigate(CARDS_SUCCESS);
  };
  const __retakePicture = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    __startCamera();
  };
  const __handleFlashMode = () => {
    if (flashMode === "on") {
      setFlashMode("off");
    } else if (flashMode === "off") {
      setFlashMode("on");
    } else {
      setFlashMode("auto");
    }
  };
  const __switchCamera = () => {
    setCameraType(
      cameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  };

  const boxStyle = { flex: 1, width: "100%", backgroundColor: "transparent" };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <PhotoContainer>
      <PhotoArea>
        <Camera
          type={cameraType}
          flashMode={flashMode}
          style={{ flex: 1 }}
          ref={camera}
        >
          <Box style={boxStyle} safeArea>
            <PhotoHeader>
              <ButtonContainer>
                <ButtonBack
                  onPress={() => navigate(REGISTER_INSTRUCTIONS_FACE)}
                  color="white"
                  size={24}
                />
              </ButtonContainer>

              <ButtonHeaderContainer>
                <ButtonBaseContainer
                  style={{ marginRight: 5 }}
                  onPress={__handleFlashMode}
                >
                  {flashMode == "on" ? (
                    <MaterialIcons name="flash-on" size={24} color="white" />
                  ) : flashMode == "off" ? (
                    <MaterialIcons name="flash-off" size={24} color="white" />
                  ) : (
                    <MaterialIcons name="flash-auto" size={24} color="white" />
                  )}
                </ButtonBaseContainer>
                <ButtonBaseContainer onPress={__switchCamera}>
                  <MaterialIcons name="camera-front" size={24} color="white" />
                </ButtonBaseContainer>
              </ButtonHeaderContainer>
            </PhotoHeader>
            <PhotoContentFace>
              <PhotoBordersFace></PhotoBordersFace>
            </PhotoContentFace>
            <PhotoFooter>
              <ButtonContainer>
                <ButtonPhoto disabled={takePicture} onPress={__takePicture} />
                <PhotoType>{typePhoto}</PhotoType>
              </ButtonContainer>
            </PhotoFooter>
            <ModalBase
              modal={photoInstructionsShow}
              setModal={setPhotoInstructionsShow}
              maxWidth={300}
              type="default"
            >
              <Image
                style={{
                  width: 100,
                  height: 150,
                  resizeMode: "contain",
                  alignSelf: "center",
                  marginTop: 30,
                }}
                alt="Selfie"
                source={require("../../../assets/images/photo-selfie.png")}
              />
              <Text
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                REGISTRO FACIAL
              </Text>
              <Text style={{ textAlign: "center", marginTop: 5 }}>
                Centralize seu rosto na marcação e tire uma foto.
              </Text>
              <ButtonBase
                style={{ marginTop: 15 }}
                onPress={() => setPhotoInstructionsShow(false)}
                title="OK, entendi"
                color="orange"
              />
            </ModalBase>
          </Box>
        </Camera>
      </PhotoArea>
      <StatusBar style="auto" />
    </PhotoContainer>
  );
};

export default CardFace;
