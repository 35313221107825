import React from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import { Image } from "native-base";
import {
  DashboardBigButton,
  DashboardBigButtonContent,
  DashboardBigButtonIcon,
  DashboardButton,
  DashboardButtonImage,
  DashboardButtonsGroup,
} from "./styles";
import { AntDesign } from "@expo/vector-icons";
import { ButtonBase } from "../../components/buttons";
import {
  CARDS,
  DEPOSIT,
  PAYMENT,
  TRANSFER,
  WITHDRAW,
} from "../../constants/routes";
import { View } from "react-native";
import { Carousel } from "../../components/carousel";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";

export const ButtonFeatured = ({
  title,
  description,
  extra,
  onPress,
  type,
  mobile,
}) => {
  if (type == "white") {
    return (
      <DashboardBigButton mobile={mobile} onPress={onPress}>
        <DashboardBigButtonContent>
          <TextPage tiny>{description}</TextPage>
          <TitlePage style={{ fontSize: 20 }}>{title}</TitlePage>
        </DashboardBigButtonContent>
        <DashboardBigButtonIcon>
          <ButtonBase
            tiny
            color="orange"
            title={extra == undefined ? "" : extra}
          />
        </DashboardBigButtonIcon>
      </DashboardBigButton>
    );
  }
  if (type == "orange") {
    return (
      <DashboardBigButton mobile={mobile} orange onPress={onPress}>
        <DashboardBigButtonContent>
          <TitlePage style={{ fontSize: 20 }} color="white">
            {title}
          </TitlePage>
          <TextPage color="white" tiny>
            {description}
          </TextPage>
        </DashboardBigButtonContent>
        <DashboardBigButtonIcon>
          <AntDesign name="right" size={24} color="white" />
        </DashboardBigButtonIcon>
      </DashboardBigButton>
    );
  }
};

export const DashboardDesktopButtons = ({ navigate }) => (
  <DashboardButtonsGroup>
    <DashboardButton style={{ marginRight: 10 }}>
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon pix"
          size={30}
          source={require("../../assets/images/icon-pix.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        PIX
      </TextPage>
    </DashboardButton>
    <DashboardButton
      style={{ marginRight: 10 }}
      onPress={() => navigate(TRANSFER)}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon transfer"
          size={30}
          source={require("../../assets/images/icon-transfer.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Transferir
      </TextPage>
    </DashboardButton>
    <DashboardButton
      onPress={() => navigate(DEPOSIT)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon deposit"
          size={30}
          source={require("../../assets/images/icon-deposit.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Depositar
      </TextPage>
    </DashboardButton>
    <DashboardButton
      onPress={() => navigate(PAYMENT)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon payment"
          size={30}
          source={require("../../assets/images/icon-payment.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Pagar
      </TextPage>
    </DashboardButton>
    <DashboardButton
      onPress={() => navigate(WITHDRAW)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon"
          size={30}
          source={require("../../assets/images/icon-withdraw.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Sacar
      </TextPage>
    </DashboardButton>
    <DashboardButton
      onPress={() => navigate(CARDS)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon card"
          size={30}
          source={require("../../assets/images/icon-card.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Cartão
      </TextPage>
    </DashboardButton>
  </DashboardButtonsGroup>
);

export const DashboardMobileButtons = ({ navigate }) => {
  const ButtonsCarousel = [
    <DashboardButton>
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon pix"
          size={30}
          source={require("../../assets/images/icon-pix.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        PIX
      </TextPage>
    </DashboardButton>,
    <DashboardButton onPress={() => navigate(TRANSFER)}>
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon transfer"
          size={30}
          source={require("../../assets/images/icon-transfer.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Transferir
      </TextPage>
    </DashboardButton>,
    <DashboardButton onPress={() => navigate(DEPOSIT)}>
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon deposit"
          size={30}
          source={require("../../assets/images/icon-deposit.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Depositar
      </TextPage>
    </DashboardButton>,
    <DashboardButton onPress={() => navigate(PAYMENT)}>
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon payment"
          size={30}
          source={require("../../assets/images/icon-payment.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Pagar
      </TextPage>
    </DashboardButton>,
    <DashboardButton
      onPress={() => navigate(WITHDRAW)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon card"
          size={30}
          source={require("../../assets/images/icon-withdraw.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Sacar
      </TextPage>
    </DashboardButton>,
    <DashboardButton
      onPress={() => navigate(CARDS)}
      style={{ marginRight: 10 }}
    >
      <DashboardButtonImage>
        <Image
          resizeMode="contain"
          alt="Icon card"
          size={30}
          source={require("../../assets/images/icon-card.png")}
        />
      </DashboardButtonImage>
      <TextPage tiny fontWeight="medium" center>
        Cartão
      </TextPage>
    </DashboardButton>,
  ];

  return <Carousel data={ButtonsCarousel} showPaginator={false} />;
};

export const DashboardHistoryButton = ({ onPress }) => (
  <View
    style={{
      justifyContent: "center",
      alignItems: "center",
      marginTop: 15,
      marginBottom: 50,
    }}
  >
    <ButtonBase
      onPress={onPress}
      style={{ width: 125 }}
      title="Ver mais"
      tiny
      color="orange"
    />
  </View>
);

export const PlaceholderBanner = () => (
  <View>
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{ height: 180 }} />
    </Placeholder>
  </View>
);

export const PlaceholderBannerDesktop = () => (
  <View style={{ marginTop: 25, marginLeft: 10, marginRight: 10 }}>
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{ height: 180 }} />
    </Placeholder>
  </View>
);

export const PlaceholderButtons = () => (
  <View style={{ padding: 10 }}>
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{ height: 60 }} />
      <PlaceholderLine style={{ height: 60 }} />
    </Placeholder>
  </View>
);

export const PlaceholderHistory = () => (
  <>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
  </>
);
