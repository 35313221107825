import React, { useEffect, useRef, useState } from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import { ContainerBorder } from "./styles";
import { useToast } from "native-base";
import { PlaceholderNotifications } from "./components";
import { cepMask } from "../../helpers/masks";
import axiosInstance from "../../helpers/axiosInstance";
import { DASHBOARD } from "../../constants/routes";

const Notification = ({ navigation }) => {
  const location = useRef(null);
  const toast = useToast();
  const [loading, setLoading] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    setLoading(true);
    axiosInstance
      .get("notification/get")
      .then((res) => {
        setLoading(false);
        setNotifications(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 30 }}>Notificações</TitlePage>
      {loading ? (
        <PlaceholderNotifications />
      ) : notifications.length == 0 ? (
        <TextPage style={{ marginTop: 25 }} center>
          Nenhuma notificação
        </TextPage>
      ) : (
        notifications.map((item, index) => {
          return (
            <ContainerBorder key={index}>
              <TextPage fontWeight="medium">{item.title}</TextPage>
              <TextPage>{item.body}</TextPage>
            </ContainerBorder>
          );
        })
      )}
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
    />
  );
};

export default Notification;
