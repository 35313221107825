import React, { useEffect, useState, useRef } from "react";
import { Animated, View, StyleSheet } from "react-native";
import { Flex, Image, Text } from "native-base";
import { TextPage, TitlePage } from "../../../styles/typograph";
import { ContainerBase, GreyContainer } from "../../../styles/default";
import { useNavigation } from "@react-navigation/core";
import { CARDS, CARDS_FACE } from "../../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import {
  ButtonIconsContainer,
  ButtonIconsLeft,
  ButtonIconsRight,
  ButtonIconsContent,
  ButtonContainer,
} from "../../../styles/buttons";
import { TemplatePage } from "../../../components/templates";
import { ButtonBox, CardContainer } from "./styles";
import { ButtonBase } from "../../../components/buttons";
import { ModalBase } from "../../../components/modals";
import { TextInputBase } from "../../../components/inputs";

const CardDetails = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [cardData, setCardData] = useState(false);
  const [modal, setModal] = useState(false);
  const [digits, setDigits] = useState("");
  const animateHeight = useRef(new Animated.Value(170)).current;
  const animateOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {}, []);

  const showCardData = () => {
    Animated.timing(animateHeight, {
      toValue: 100,
      duration: 1000,
      useNativeDriver: false,
    }).start();
    Animated.timing(animateOpacity, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start();
    setCardData(true);
  };

  const hideCardData = () => {
    Animated.timing(animateHeight, {
      toValue: 170,
      duration: 1000,
      useNativeDriver: false,
    }).start();
    Animated.timing(animateOpacity, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: false,
    }).start(({ finished }) => {
      setCardData(false);
    });
  };

  const styles = StyleSheet.create({
    card: {
      width: 300,
      backgroundColor: "#ff671d",
      borderRadius: 15,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingBottom: 15,
      marginBottom: 15,
    },
    cardInfo: {
      marginTop: 15,
      marginBottom: 15,
    },
  });

  const CardButtons = () => {
    return (
      <ContainerBase>
        <ButtonIconsContainer
          style={{ marginBottom: 20 }}
          onPress={() => setModal(true)}
        >
          <ButtonIconsLeft>
            <Image
              size={30}
              resizeMode="contain"
              source={require("../../../assets/images/icon-payment.png")}
              alt="payment icon"
            />
          </ButtonIconsLeft>
          <ButtonIconsContent>
            <TextPage fontWeight="medium" big>
              Meu cartão chegou
            </TextPage>
            <TextPage tiny>
              Seu cartão novo chegou? Faça aqui seu desbloqueio
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>

        <ButtonIconsContainer
          style={{ marginBottom: 20 }}
          onPress={() => showCardData()}
        >
          <ButtonIconsLeft>
            <Image
              size={30}
              resizeMode="contain"
              source={require("../../../assets/images/icon-keyboard.png")}
              alt="payment icon"
            />
          </ButtonIconsLeft>
          <ButtonIconsContent>
            <TextPage fontWeight="medium" big>
              Dados do cartão
            </TextPage>
            <TextPage tiny>
              Verifique os dados da remessa, entrega e status
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
      </ContainerBase>
    );
  };

  const CardInfo = () => {
    return (
      <Animated.View style={[styles.cardInfo, { opacity: animateOpacity }]}>
        <GreyContainer>
          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Para</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>EDUARDO VELOSO</TextPage>
            </View>
          </Flex>
          <TextPage>Endereço</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            Ocidental, 419
          </TextPage>
          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Número</TextPage>
              <TextPage TextPage="medium">419</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>CEP</TextPage>
              <TextPage TextPage="medium">30882-000</TextPage>
            </View>
          </Flex>
          <TextPage>Complemento</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            -
          </TextPage>
          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Bairro</TextPage>
              <TextPage TextPage="medium">Serrano</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>Cidade</TextPage>
              <TextPage TextPage="medium">Belo Horizonte</TextPage>
            </View>
          </Flex>
          <Flex direction="row" space={3}>
            <View style={{ flex: 1 }}>
              <TextPage>Estado</TextPage>
              <TextPage TextPage="medium">MG</TextPage>
            </View>
            <View style={{ flex: 1 }}></View>
          </Flex>
        </GreyContainer>
        <ContainerBase>
          <ButtonContainer
            onPress={() => hideCardData()}
            style={{ padding: 10, marginBottom: 10 }}
          >
            <TextPage center>VOLTAR</TextPage>
          </ButtonContainer>
          <ButtonBase
            onPress={() => setModal(true)}
            title="MEU CARTÃO CHEGOU"
            color="orange"
          />
        </ContainerBase>
      </Animated.View>
    );
  };

  const pageContent = (
    <>
      <TitlePage style={{ marginBottom: 20, marginLeft: 15 }}>
        Meus cartões
      </TitlePage>

      <CardContainer>
        <Animated.View style={[styles.card, { height: animateHeight }]}>
          <Text style={{ fontSize: 18, color: "#000000", marginBottom: 5 }}>
            Cartão 80395415
          </Text>
          <TextPage tiny center>
            Cartão em processamento
          </TextPage>
        </Animated.View>
      </CardContainer>
      <TextPage tiny center style={{ marginBottom: 10 }}>
        Solicitado em 06/04/2022 às 19:36h
      </TextPage>
      <ButtonBox>
        <ButtonContainer
          onPress={() => console.log("teste")}
          style={{ padding: 10 }}
        >
          <TextPage center>ACOMPANHAR RASTREIO</TextPage>
        </ButtonContainer>
      </ButtonBox>
      {cardData ? <CardInfo /> : <CardButtons />}
      <ModalBase
        header="4 ÚLTIMOS DIGITOS"
        modal={modal}
        setModal={setModal}
        type="bottom"
      >
        <TextInputBase
          name={"digits"}
          label={"Informe os 4 últimos dígitos do cartão para desbloquear"}
          onChangeText={setDigits}
          value={digits}
          maxLength={4}
          keyboardType="numeric"
        />
        <ButtonBase
          onPress={() => navigate(CARDS_FACE)}
          title="CONFIRMAR"
          color="orange"
        />
      </ModalBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={CARDS}
      paddingResetMobile
    />
  );
};

export default CardDetails;
