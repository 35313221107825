import React from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { DEPOSIT_BANK_SLIP, DEPOSIT_TED } from "../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import {
  ButtonIconsContainer,
  ButtonIconsRight,
  ButtonIconsContent,
} from "../../styles/buttons";
import { TemplatePage } from "../../components/templates";

const Deposit = ({ navigation }) => {
  const { navigate } = navigation;

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 30 }}>Depósito</TitlePage>

      <ButtonIconsContainer
        style={{ marginBottom: 20, paddingLeft: 10 }}
        onPress={() => navigate(DEPOSIT_BANK_SLIP)}
      >
        <ButtonIconsContent>
          <TextPage fontWeight="medium" big>
            Depósito por boleto
          </TextPage>
          <TextPage tiny>
            Faça o pagamento e receba na sua conta em até 2 dias úteis
          </TextPage>
        </ButtonIconsContent>
        <ButtonIconsRight>
          <AntDesign name="right" size={24} color="black" />
        </ButtonIconsRight>
      </ButtonIconsContainer>

      <ButtonIconsContainer
        style={{ marginBottom: 20, paddingLeft: 10 }}
        onPress={() => navigate(DEPOSIT_TED)}
      >
        <ButtonIconsContent>
          <TextPage fontWeight="medium" big>
            Por TED ou DOC
          </TextPage>
          <TextPage tiny>
            Transfira de outro banco para sua conta Time Pay
          </TextPage>
        </ButtonIconsContent>
        <ButtonIconsRight>
          <AntDesign name="right" size={24} color="black" />
        </ButtonIconsRight>
      </ButtonIconsContainer>
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      paddingResetMobile
    />
  );
};

export default Deposit;
