import React from "react";
import { Ionicons, FontAwesome, Feather } from "@expo/vector-icons";
import {
  ButtonContainer,
  ButtonText,
  ButtonBaseContainer,
  ButtonMenuContainer,
  ButtonWrap,
  ButtonWrapGrey,
} from "../styles/buttons";
import { ButtonPhotoStyle } from "../screens/Register/documents/styles";
import { ActivityIndicator } from "react-native";

export const ButtonBase = ({
  style,
  onPress,
  title,
  tiny,
  color,
  iconLeft,
  iconRight,
  disabled,
  textLeft,
  loading = false,
}) => (
  <ButtonContainer
    disabled={disabled}
    style={style}
    onPress={onPress}
    tiny={tiny}
    color={color}
  >
    {loading ? (
      <ActivityIndicator
        size="small"
        color={
          color == "outline-black" || color == "link-orange" ? "#000" : "#FFF"
        }
      />
    ) : (
      <ButtonText textLeft={textLeft} color={color} tiny={tiny}>
        {iconLeft} {title} {iconRight}
      </ButtonText>
    )}
  </ButtonContainer>
);

export const ButtonMenu = ({ style, onPress, icon }) => (
  <ButtonMenuContainer style={style} onPress={onPress}>
    {icon}
  </ButtonMenuContainer>
);

export const ButtonFilter = ({
  children,
  style,
  onPress,
  loading,
  disabled,
  selected,
}) => (
  <>
    {loading ? (
      <ButtonWrapGrey
        selected={selected}
        disabled={disabled}
        style={style}
        onPress={onPress}
      >
        <ActivityIndicator size="small" color="#000" />
      </ButtonWrapGrey>
    ) : (
      <ButtonWrapGrey
        selected={selected}
        disabled={disabled}
        style={style}
        onPress={onPress}
      >
        {children}
      </ButtonWrapGrey>
    )}
  </>
);

export const ButtonWrapContent = ({
  children,
  style,
  onPress,
  loading,
  disabled,
}) => (
  <>
    {loading ? (
      <ActivityIndicator size="small" color="#000" />
    ) : (
      <ButtonWrap disabled={disabled} style={style} onPress={onPress}>
        {children}
      </ButtonWrap>
    )}
  </>
);

export const ButtonBack = ({ style, onPress, color, size }) => (
  <ButtonBaseContainer style={style} onPress={onPress}>
    <Ionicons name="arrow-back" size={size} color={color} />
  </ButtonBaseContainer>
);

export const ButtonClose = ({ style, onPress, color, size }) => (
  <ButtonBaseContainer style={style} onPress={onPress}>
    <Ionicons name="close" size={size} color={color} />
  </ButtonBaseContainer>
);

export const ButtonPhoto = ({ onPress, disabled }) => (
  <ButtonPhotoStyle disabled={disabled} onPress={onPress}>
    {disabled ? (
      <ActivityIndicator size="small" color="#fff" />
    ) : (
      <FontAwesome name="camera" size={16} color="white" />
    )}
  </ButtonPhotoStyle>
);
