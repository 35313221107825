import React from "react";
import { View } from "react-native";
import { SelectList, SelectListOption } from "../../styles/inputs";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";

export const PlaceholderAccounts = () => (
  <>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
  </>
);

export const PlaceholderBanks = () => (
  <SelectList>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
    <SelectListOption>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </SelectListOption>
  </SelectList>
);
