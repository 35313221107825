import React, { useState, useEffect, useContext } from "react";
import { FlatList, ScrollView } from "react-native";
import { Box, useToast, Image, HStack, View, Flex } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  FooterBase,
  ContainerPad,
  GreyContainer,
  FlatListBase,
} from "../../styles/default";
import {
  SelectList,
  SelectListOption,
  SelectListOptionText,
} from "../../styles/inputs";
import { ButtonBase } from "../../components/buttons";
import { SwitchBase, TextInputBase, DateInput } from "../../components/inputs";
import { DASHBOARD, TRANSFER } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { accountMask, cpfCnpjMask, moneyMask } from "../../helpers/masks";
import {
  clearCaracters,
  formatMoney,
  formatDecimal,
  formatDateFitFormat,
  formatDateInput,
} from "../../helpers/format";
import { Formik } from "formik";
import { TemplatePage } from "../../components/templates";
import {
  ProfileContainer,
  ProfileImage,
  ProfileContent,
  ContainerBorder,
  ContainerBorderless,
  AccountTypeItem,
  ContainerFee,
} from "./styles";
import { AntDesign } from "@expo/vector-icons";
import TransactionPassword from "../../components/transactionPassword";
import TransferAnotherBankAccountModal from "./transferAnotherBankAccountModal";
import TransferAnotherBankAgencyModal from "./transferAnotherBankAgencyModal";
import TransactionValueConfirm from "../../components/transactionValueConfirm";

const TransferAnotherBank = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [bank, setBank] = useState();
  const [bankLabel, setBankLabel] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [confirmFields, setConfirmFields] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);
  const [saveContact, setSaveContact] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmValue, setShowConfirmValue] = useState(false);
  const [showAgencyModal, setShowAgencyModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [accountTypeSelected, setAccountTypeSelected] = useState({
    id: 1,
    name: "Conta corrente",
  });
  const [miniumPaymentDate, setMiniumPaymentDate] = useState(new Date());
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [initialValues, setInitialValues] = useState({
    document: "",
    name: "",
    branch: "",
    account: "",
  });
  const [formValues, setFormValues] = useState();
  const [validateAmount, setValidateAmount] = useState();
  const toast = useToast();
  const {
    balance,
    bankList,
    settings,
    accountTypeList,
    authState: { authData },
  } = useContext(GlobalContext);

  useEffect(() => {
    getTranferDate();
  }, []);

  useEffect(() => {
    comparePaymentDate();
  }, [paymentDate]);

  useEffect(() => {
    if (route.params?.accountSelected) {
      let accountData = route.params?.accountSelected;
      accountData?.bank && setBank(accountData?.bank);
      accountData?.bank_name && setBankLabel(accountData?.bank_name);
      setInitialValues({
        document: accountData?.cpf_cnpj ? accountData?.cpf_cnpj : "",
        name: accountData?.name ? accountData?.name : "",
        branch: accountData?.branch ? accountData?.branch : "",
        account: accountData?.account
          ? accountData?.account + accountData?.digit
          : "",
      });
    }
  }, [route.params?.accountSelected]);

  const getTranferDate = () => {
    setLoading(true);
    axiosInstance
      .get("transaction/transfer-date")
      .then((res) => {
        setLoading(false);
        let formatedDate = formatDateFitFormat(res.data);
        setMiniumPaymentDate(formatedDate);
        compareDate(formatedDate);
        setPaymentDate(formatedDate);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const compareDate = (date) => {
    if (
      date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear() !=
      paymentDate.getDate() +
        "-" +
        paymentDate.getMonth() +
        "-" +
        paymentDate.getFullYear()
    ) {
      setScheduled(true);
      toast.show({
        description:
          "Horário limite atingido, a transferência só poderá ser realizado no próximo dia útil ",
        placement: "top",
      });
    }
  };

  const onSubmitForm = async (values) => {
    setLoading(true);
    try {
      await validateDocument(values.document);
      setFormValues(values);
      setConfirmFields(true);
    } catch (err) {
      toast.show({
        description: "Cpf/Cnpj inválidos",
        placement: "top",
      });
    }
    setLoading(false);
  };

  const makeTransfer = async (code) =>
    axiosInstance.post("transaction/transfer", {
      ...formValues,
      code: code,
      account: clearCaracters(formValues.account),
      cpf_cnpj: clearCaracters(formValues.document),
      amount: formatDecimal(amount),
      bank: bank,
      bank_name: bankLabel,
      account_type_id: accountTypeSelected.id,
      description: description,
      balance: balance,
      payment_date: formatDateInput(paymentDate),
    });

  const validateDocument = async (cpf_cnpj) =>
    axiosInstance.post("customer-auth/validate-cpf-cnpj", {
      cpf_cnpj: clearCaracters(cpf_cnpj),
    });

  const validateData = (values) => {
    let errors = {};

    if (!values.document) {
      errors.document = "Cpf ou Cnpj obrigatório";
    }
    if (!values.name) {
      errors.name = "Nome obrigatório";
    }
    if (!values.branch) {
      errors.branch = "Agência obrigatória";
    }
    if (!values.account) {
      errors.account = "Conta obrigatória";
    }

    return errors;
  };

  const searchBank = (v) => {
    setBankLabel(v);
    let bankFinded = bankList.filter(
      (item) =>
        item.label.toLowerCase().includes(v.toLowerCase()) || item.value == v
    );
    setSearchList(bankFinded);
  };

  const setSelectedBank = (v) => {
    setBank(v.value);
    setBankLabel(v.value + " - " + v.label);
  };

  const showSelectList = () => {
    setBank("");
    setBankLabel("");
    setSearchList([]);
  };

  const renderItem = ({ item }) => (
    <SelectListOption onPress={() => setSelectedBank(item)}>
      <SelectListOptionText>
        {item.value + " - " + item.label}
      </SelectListOptionText>
    </SelectListOption>
  );

  const handleConfirmValue = () => {
    if (!scheduled && balance - formatDecimal(amount) < 0) {
      toast.show({
        description: "Saldo insuficiente",
        placement: "top",
      });
      return;
    }
    if (formatDecimal(amount) < settings?.mv_transferout) {
      setValidateAmount(
        "Valor mínimo: R$" + formatMoney(settings?.mv_transferout)
      );
      return;
    }
    setShowConfirmValue(true);
  };

  const comparePaymentDate = () => {
    let today = new Date();
    if (paymentDate > today) {
      setScheduled(true);
    }
  };

  const pageBank = (
    <>
      <ContainerPad style={{ paddingBottom: 0 }}>
        <TitlePage style={{ marginBottom: 15 }}>Qual é o banco?</TitlePage>

        <TextInputBase
          borderFull
          placeholder="Pesquisar por nome"
          onChangeText={(v) => searchBank(v)}
          value={bankLabel}
          buttonIcon={<AntDesign name="search1" size={18} color="black" />}
        />
      </ContainerPad>

      <FlatListBase
        data={searchList.length > 0 ? searchList : bankList}
        renderItem={renderItem}
        keyExtractor={(item) => item.value.toString()}
      />

      <FooterBase>
        <ButtonBase title="CONTINUAR" color="orange" />
      </FooterBase>
    </>
  );

  const pageFields = (
    <Formik
      enableReinitialize
      validateOnMount={true}
      validate={validateData}
      initialValues={initialValues}
      onSubmit={(values) => onSubmitForm(values)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
        isValid,
      }) => (
        <>
          <ContainerBase>
            <TitlePage style={{ marginBottom: 25 }}>
              Preencha com os dados da conta do outro banco
            </TitlePage>

            <TextInputBase
              label="Banco"
              onFocus={() => showSelectList()}
              value={bankLabel}
            />

            <TextPage>Tipo de conta</TextPage>
            <ScrollView
              style={{ marginBottom: 10 }}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {accountTypeList &&
                accountTypeList.map((item) => {
                  return (
                    <AccountTypeItem key={item.id.toString()}>
                      <ButtonBase
                        tiny
                        onPress={() => setAccountTypeSelected(item)}
                        title={item.name}
                        color={
                          accountTypeSelected?.id == item.id
                            ? "orange"
                            : "outline-black"
                        }
                      />
                    </AccountTypeItem>
                  );
                })}
            </ScrollView>

            <TextInputBase
              keyboardType="numeric"
              name={"document"}
              label={"Cpf/Cnpj"}
              onChangeText={(v) => {
                setFieldValue("document", cpfCnpjMask(v));
                setFieldTouched("document", true, false);
              }}
              onBlur={handleBlur("document")}
              value={values.document}
              errors={errors.document}
              touched={touched.document}
            />

            <TextInputBase
              name={"name"}
              label={"Nome completo"}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
              errors={errors.name}
              touched={touched.name}
            />

            <TextInputBase
              keyboardType="numeric"
              name={"branch"}
              label={"Agência"}
              onChangeText={handleChange("branch")}
              onBlur={handleBlur("branch")}
              value={values.branch}
              errors={errors.branch}
              touched={touched.branch}
              toolTipModal={setShowAgencyModal}
            />

            <TextInputBase
              keyboardType="numeric"
              name={"account"}
              label={"Conta com dígito"}
              onChangeText={handleChange("account")}
              onBlur={handleBlur("account")}
              value={values.account}
              errors={errors.account}
              touched={touched.account}
              toolTipModal={setShowAccountModal}
            />

            <DateInput
              label="Data de transferência"
              minimumDate={miniumPaymentDate}
              valueFormated={
                paymentDate.getDate() +
                "/" +
                (paymentDate.getMonth() + 1) +
                "/" +
                paymentDate.getFullYear()
              }
              value={paymentDate}
              setValue={setPaymentDate}
            />

            <ContainerFee>
              <TextPage tiny>
                Tarifa por transação:
                {authData?.user?.fee?.transfer_out &&
                  " R$" + authData?.user?.fee?.transfer_out}
              </TextPage>
            </ContainerFee>
          </ContainerBase>
          <FooterBase>
            <ButtonBase
              loading={loading}
              disabled={!isValid}
              onPress={() => handleSubmit()}
              title="TRANSFERIR"
              color="orange"
            />
          </FooterBase>
          <TransferAnotherBankAgencyModal
            modal={showAgencyModal}
            setModal={setShowAgencyModal}
          />
          <TransferAnotherBankAccountModal
            modal={showAccountModal}
            setModal={setShowAccountModal}
          />
        </>
      )}
    </Formik>
  );

  const pageContentValue = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 10 }}>
          Quanto você quer transferir?
        </TitlePage>
        <TextPage style={{ marginBottom: 10 }}>
          Seu saldo disponível em conta
          <TextPage color="orange">
            {balance && " R$" + formatMoney(balance)}
          </TextPage>
        </TextPage>

        <TextInputBase
          keyboardType="numeric"
          name={"amount"}
          onChangeText={(v) => {
            setAmount(moneyMask(v));
          }}
          value={amount}
          errors={validateAmount}
          touched={true}
          bigText
        />

        <TextInputBase
          name={"description"}
          label={"Descrição(opcional)"}
          onChangeText={setDescription}
          value={description}
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={amount == "" || amount == undefined || amount.length < 4}
          onPress={() => handleConfirmValue()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
      <TransactionValueConfirm
        modal={showConfirmValue}
        setModal={setShowConfirmValue}
        setConfirmValue={setConfirmValue}
        amount={formatDecimal(amount)}
      />
    </>
  );

  const pageContentData = (
    <>
      <ContainerBase paddingReset>
        <TitlePage style={{ marginBottom: 5, marginLeft: 15 }}>
          Transferir
        </TitlePage>
        <ContainerBorder>
          <TextPage>De</TextPage>
          <ProfileContainer>
            <ProfileImage>
              <Image
                source={require("../../assets/images/default-photo.png")}
                size="50"
                borderRadius="100"
                alt="photo"
              />
            </ProfileImage>
            <ProfileContent>
              <TextPage fontWeight="medium">{authData?.user?.name}</TextPage>
              <TextPage>CPF: {authData?.user?.cpf_cnpj}</TextPage>
            </ProfileContent>
          </ProfileContainer>
        </ContainerBorder>
        <ContainerBorder>
          <TextPage>Para</TextPage>
          <TextPage fontWeight="medium">{formValues?.name}</TextPage>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">{"R$" + amount}</TextPage>
          <TextPage tiny>
            tarifa
            {authData?.user?.fee?.transfer_out &&
              " R$" + authData?.user?.fee?.transfer_out}
          </TextPage>
        </ContainerBorderless>

        <GreyContainer>
          <TextPage>CPF/CNPJ</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            {formValues?.document}
          </TextPage>

          <TextPage>Banco</TextPage>
          <TextPage style={{ marginBottom: 15 }} TextPage="medium">
            {bankLabel}
          </TextPage>

          <Flex direction="row" space={3} style={{ marginBottom: 15 }}>
            <View style={{ flex: 1 }}>
              <TextPage>Tipo de transferência</TextPage>
              <TextPage TextPage="medium">TED</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>Tipo de conta</TextPage>
              <TextPage TextPage="medium">{accountTypeSelected?.name}</TextPage>
            </View>
          </Flex>
          <Flex direction="row" space={3}>
            <View style={{ flex: 1 }}>
              <TextPage>Agência</TextPage>
              <TextPage TextPage="medium">{formValues?.branch}</TextPage>
            </View>
            <View style={{ flex: 1 }}>
              <TextPage>Conta</TextPage>
              <TextPage TextPage="medium">{formValues?.account}</TextPage>
            </View>
          </Flex>
        </GreyContainer>

        <ContainerPad>
          <SwitchBase
            style={{ marginBottom: 10 }}
            label="Salvar contato"
            value={saveContact}
            setValue={(v) => setSaveContact(v)}
          />
        </ContainerPad>
        <ContainerFee>
          <TextPage>
            Saldo em conta {balance && " R$" + formatMoney(balance)}
          </TextPage>
        </ContainerFee>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => setShowPassword(true)}
          title="CONFIRMAR"
          color="orange"
        />
      </FooterBase>
      <TransactionPassword
        modal={showPassword}
        setModal={setShowPassword}
        transaction={makeTransfer}
        transactionData={{
          name: formValues?.name,
          amount: formatDecimal(amount),
          description: description,
          transaction_type_id: 25,
          fee: authData?.user?.fee?.transfer_out,
          cpf_cnpj: clearCaracters(formValues?.document),
          bank: bank,
          bank_name: bankLabel,
          type: accountTypeSelected,
          branch: formValues?.branch,
          account: formValues?.account,
          save: saveContact,
          scheduled: scheduled,
        }}
      />
    </>
  );

  const pageContent = () => {
    if (!bank) return pageBank;
    if (confirmValue) return pageContentData;
    if (confirmFields) return pageContentValue;

    return pageFields;
  };

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent()}
      backNavigate={TRANSFER}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferAnotherBank;
