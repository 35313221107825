import React, { useState, useEffect } from "react";
import { useToast } from "native-base";
import { TitlePage } from "../../styles/typograph";
import { ContainerBase, FooterBase } from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { TextInputBase } from "../../components/inputs";
import {
  DASHBOARD,
  TRANSFER,
  TRANSFER_ANOTHERBANK,
  TRANSFER_FITBANK_OPTIONS,
} from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { cpfCnpjMask } from "../../helpers/masks";
import { clearCaracters } from "../../helpers/format";
import { TemplatePage } from "../../components/templates";

const TransferFitBankDocument = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [documentInitial, setDocumentInitial] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {}, []);

  const checkAccountExists = () => {
    setLoading(true);
    axiosInstance
      .post("user/find-by-document", {
        cpf_cnpj: clearCaracters(documentInitial),
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.cpf_cnpj) {
          navigate(TRANSFER_FITBANK_OPTIONS, {
            ...res?.data,
          });
        } else {
          toast.show({
            description: "Conta Timepay não encontrada",
            placement: "top",
          });
          navigate(TRANSFER_ANOTHERBANK, {
            ...res?.data,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const pageContent = (
    <>
      <ContainerBase contentContainerStyle={{ paddingBottom: 25 }}>
        <TitlePage style={{ marginBottom: 25 }}>
          Pra quem você quer transferir?
        </TitlePage>
        <TextInputBase
          keyboardType="numeric"
          name="documentInitial"
          label="CPF/CNPJ"
          onChangeText={(v) => setDocumentInitial(cpfCnpjMask(v))}
          value={documentInitial}
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          disabled={documentInitial == ""}
          onPress={() => checkAccountExists()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={TRANSFER}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferFitBankDocument;
