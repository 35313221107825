import React, { useState, useEffect, useRef } from "react";
import { LOGIN } from "../../constants/routes";
import { ButtonBack } from "../../components/buttons";
import {
  MainContainer,
  FooterContainer,
  HeaderContainer,
  ChatImageBox,
} from "./styles";
import { Box } from "native-base";
import RegisterPersonType from "./personType";
import { ChatClient, ChatItemComponent } from "./components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  initPresentation,
  initTerms,
  initPersonType,
  initDocument,
  initDocumentType,
  initAddress,
  initEmail,
  initPhone,
  initPhoneConfirmation,
  initPassword,
  endMessage,
  FooterPresentation,
  FooterTerms,
  FooterPersonType,
  FooterDocument,
  FooterDocumentType,
  FooterPhone,
  FooterAddress,
  FooterEmail,
  FooterPhoneConfirmation,
  FooterEndMessage,
  FooterPassword,
} from "./steps";
import DocumentType from "./documentType";
import { useToast } from "native-base";
import { Image } from "react-native";

const Register = ({ navigation, route }) => {
  const { navigate } = navigation;

  const [chatList, setChatList] = useState([]);
  const [personType, setPersonType] = useState("pf");
  const [personTypeShow, setPersonTypeShow] = useState(false);
  const [documentTypeShow, setDocumentTypeShow] = useState(false);
  const [documentType, setDocumentType] = useState("rg");
  const [termOpened, setTermOpened] = useState(false);
  const [currentStep, setCurrentStep] = useState();
  const currentStepRef = useRef();
  const scrollViewRef = useRef();
  const customer = useRef();
  const steps = useRef();
  const initialStep = 5;
  const toast = useToast();

  steps.current = [
    {
      id: 5,
      nextId: 10,
      init: () => initPresentation(setChatList),
    },
    {
      id: 10,
      nextId: 15,
      init: () => initTerms(setChatList, customer.current.nickname),
    },
    {
      id: 15,
      nextId: 20,
      init: () => initPersonType(setChatList),
    },
    {
      id: 20,
      nextId: 25,
      init: () => initDocument(setChatList),
    },
    {
      id: 25,
      nextId: 30,
      init: () => initDocumentType(setChatList),
    },
    {
      id: 30,
      nextId: 35,
      init: () => initAddress(setChatList),
    },
    {
      id: 35,
      nextId: 40,
      init: () => initEmail(setChatList),
    },
    {
      id: 40,
      nextId: 45,
      init: () => initPhone(setChatList),
    },
    {
      id: 45,
      nextId: 50,
      init: () =>
        initPhoneConfirmation(setChatList, customer.current.phone, toast),
    },
    {
      id: 50,
      nextId: 55,
      init: () => initPassword(setChatList),
    },
    {
      id: 55,
      init: () => endMessage(setChatList),
    },
  ];

  useEffect(() => {
    if (route.params?.personalData) {
      findStep(25, false);
      updateCustomer(
        { ...route.params?.photoData },
        [
          {
            text: route.params?.photoData,
            type: "documents",
            fade: 500,
          },
        ],
        false
      );
      updateCustomer({ ...route.params?.personalData }, [
        {
          text: getObjectMessage(route.params?.personalData),
          type: "client",
          fade: 500,
        },
      ]);
    }
  }, [route.params?.personalData]);

  useEffect(() => {
    if (route.params?.addressData) {
      findStep(30, false);
      updateCustomer({ ...route.params?.addressData }, [
        {
          text: getObjectMessage(route.params?.addressData),
          type: "client",
          fade: 500,
        },
      ]);
    }
  }, [route.params?.addressData]);

  useEffect(() => {
    if (route.params?.phoneValidated) {
      findStep(45, false);
      updateCustomer({ phone: route.params?.phoneValidated }, [
        {
          text: "Token validado",
          type: "client",
          fade: 500,
        },
      ]);
    }
  }, [route.params?.phoneValidated]);

  useEffect(() => {
    if (route.params?.passwordConfirmation) {
      findStep(50, false);
      updateCustomer({ passwordCreated: 1 }, [
        {
          text: "Senha criada",
          type: "client",
          fade: 500,
        },
      ]);
    }
  }, [route.params?.passwordConfirmation]);

  useEffect(() => {
    getInitialStep();
  }, []);

  const getObjectMessage = (routeData) => {
    if (routeData == undefined || routeData == "") {
      return "Passo concluido";
    } else {
      let objmsg = Object.entries(routeData).map(([k, v]) => {
        return v && `${v} `;
      });
      return objmsg.join(", ");
    }
  };

  const getInitialStep = async () => {
    setChatList([]);

    try {
      customer.current = JSON.parse(await AsyncStorage.getItem("customer"));

      customer.current
        ? findStep(customer.current.step)
        : findStep(initialStep);
    } catch (error) {}
  };

  const updateCustomer = (
    value,
    ChatClient,
    nextStep = true,
    storage = false
  ) => {
    customer.current = { ...customer.current, ...value };
    setChatList((prev) => [...prev, ...ChatClient]);

    nextStep && findStep(currentStepRef.current.nextId);

    storage &&
      AsyncStorage.setItem(
        "customer",
        JSON.stringify({
          ...customer.current,
        })
      );
  };

  const findStep = (findStep, initStep = true) => {
    if (!findStep) {
      findStep = initialStep;
    }
    let stepFind = steps.current.filter((item) => {
      return item.id == findStep;
    })[0];
    currentStepRef.current = stepFind;
    setCurrentStep(stepFind);
    if (initStep) {
      stepFind.init();
      customer.current = {
        ...customer.current,
        step: stepFind.id,
      };
      findStep != initialStep &&
        AsyncStorage.setItem("customer", JSON.stringify(customer.current));
    }
  };

  const checkTerm = currentStep?.id == 10 && termOpened;

  return (
    <Box style={{ flex: 1, backgroundColor: "#ff671d" }} safeArea>
      <HeaderContainer>
        <ButtonBack onPress={() => navigate(LOGIN)} color="white" size={24} />
      </HeaderContainer>
      <MainContainer
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: true })
        }
      >
        {chatList.map((chatItem, index) => {
          return (
            <ChatItemComponent
              key={index}
              chatItem={chatItem}
              navigate={navigate}
              setTermOpened={setTermOpened}
            />
          );
        })}
      </MainContainer>
      <FooterContainer>
        {currentStep?.id == 5 && (
          <FooterPresentation updateCustomer={updateCustomer} />
        )}
        {checkTerm && <FooterTerms updateCustomer={updateCustomer} />}
        {currentStep?.id == 15 && (
          <FooterPersonType setPersonTypeShow={setPersonTypeShow} />
        )}
        {currentStep?.id == 20 && (
          <FooterDocument
            updateCustomer={updateCustomer}
            customer={customer.current}
            toast={toast}
          />
        )}
        {currentStep?.id == 25 && (
          <FooterDocumentType setDocumentTypeShow={setDocumentTypeShow} />
        )}
        {currentStep?.id == 30 && <FooterAddress navigate={navigate} />}
        {currentStep?.id == 35 && (
          <FooterEmail
            updateCustomer={updateCustomer}
            customer={customer.current}
            toast={toast}
          />
        )}
        {currentStep?.id == 40 && (
          <FooterPhone updateCustomer={updateCustomer} />
        )}
        {currentStep?.id == 45 && (
          <FooterPhoneConfirmation
            navigate={navigate}
            phone={customer.current.phone}
            toast={toast}
          />
        )}
        {currentStep?.id == 50 && <FooterPassword navigate={navigate} />}
        {currentStep?.id == 55 && <FooterEndMessage navigate={navigate} />}
      </FooterContainer>
      <RegisterPersonType
        modal={personTypeShow}
        setModal={setPersonTypeShow}
        value={personType}
        setValue={setPersonType}
        updateCustomer={updateCustomer}
      />
      <DocumentType
        modal={documentTypeShow}
        setModal={setDocumentTypeShow}
        value={documentType}
        setValue={setDocumentType}
        updateCustomer={updateCustomer}
        navigate={navigate}
      />
    </Box>
  );
};

export default Register;
