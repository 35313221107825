import React, { useContext, useEffect } from "react";
import { Image, Text, View } from "react-native";
import { Box, useToast } from "native-base";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { HeaderBase, ContainerBase, FooterBase } from "../../styles/default";
import {
  ImagePreview,
  ImageRow,
  ImageContainer,
  ImageDefaultText,
} from "./styles";

export const CameraPreview = ({
  photoA,
  photoB,
  retakePicture,
  savePhoto,
  loading,
  navigation,
  typePhoto,
  pickDocument,
}) => {
  const setPhoto = async (type) => {
    typePhoto.current = type;
    retakePicture();
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Image
            style={{ resizeMode: "contain" }}
            source={require("../../assets/images/icon-camera.png")}
          />
        </View>
        <Text
          style={{
            textAlign: "center",
            marginTop: 25,
            marginBottom: 50,
            fontWeight: "bold",
          }}
        >
          Salve as fotos de seu documentos ou anexe para consultar
          posteriormente
        </Text>
        <ImageRow>
          <ImageContainer onPress={() => setPhoto("A")}>
            {photoA ? (
              <ImagePreview
                style={{ resizeMode: "contain" }}
                source={{ uri: photoA && photoA.uri }}
              />
            ) : (
              <ImageDefaultText>FOTO 1</ImageDefaultText>
            )}
          </ImageContainer>

          <ImageContainer onPress={() => setPhoto("B")}>
            {photoB ? (
              <ImagePreview
                style={{ resizeMode: "contain" }}
                source={{ uri: photoB && photoB.uri }}
              />
            ) : (
              <ImageDefaultText>FOTO 2</ImageDefaultText>
            )}
          </ImageContainer>
        </ImageRow>
        <View style={{ paddingTop: 40 }}>
          <ButtonBase
            onPress={() => pickDocument()}
            title="ANEXAR DOCUMENTO"
            color="outline-black"
          />
        </View>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          onPress={savePhoto}
          title="SALVAR FOTOS"
          color="orange"
        />
      </FooterBase>
    </Box>
  );
};
