import React, { useContext, useEffect, useState } from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerPad,
  FooterBase,
  GreyContainer,
} from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import { GlobalContext } from "../../contexts/Provider";
import { ButtonBase } from "../../components/buttons";
import { formatDate, formatMoney } from "../../helpers/format";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import * as Clipboard from "expo-clipboard";
import { useToast } from "native-base";
import { DASHBOARD } from "../../constants/routes";

const DepositBankSlipSuccess = ({ navigation, route }) => {
  const [bankSlip, setBankSlip] = useState();
  const {
    authState: { authData },
  } = useContext(GlobalContext);
  const toast = useToast();

  useEffect(() => {
    if (route.params?.data) {
      setBankSlip(route.params?.data);
    } else {
      navigate(DASHBOARD);
    }
  }, [route.params?.data]);

  const onShare = async (voucher) => {
    var fileShare = "";
    try {
      const { uri: localUrl } = await FileSystem.downloadAsync(
        voucher,
        FileSystem.documentDirectory + "boletotimepay.pdf"
      );
      fileShare = localUrl;
    } catch (error) {
      fileShare = voucher;
    }

    if (!(await Sharing.isAvailableAsync())) {
      toast.show({
        description: "Conceda permissão para compartilhar",
        placement: "top",
      });
      return;
    }
    await Sharing.shareAsync(fileShare);
  };

  const copyClipboard = () => {
    Clipboard.setString(bankSlip?.bank_slip?.barcode);
    toast.show({
      description: "Código de barras copiado",
      placement: "top",
    });
  };

  const pageContent = (
    <>
      <ContainerBase paddingReset>
        <ContainerPad>
          <TitlePage style={{ marginBottom: 30 }}>Depósito Time Pay</TitlePage>

          <TextPage>Para</TextPage>
          <TextPage style={{ marginBottom: 30 }} fontWeight="medium">
            {authData?.user?.name}
          </TextPage>

          <TextPage>Valor</TextPage>
          <TextPage style={{ marginBottom: 30 }} fontWeight="medium">
            R${formatMoney(bankSlip?.amount)}
          </TextPage>
        </ContainerPad>

        <GreyContainer style={{ marginBottom: 40 }}>
          <TextPage>Código de barras</TextPage>
          <TextPage style={{ marginBottom: 25 }} fontWeight="medium">
            {bankSlip?.bank_slip?.barcode}
          </TextPage>

          <TextPage>Validade do boleto</TextPage>
          <TextPage fontWeight="medium">
            {bankSlip?.bank_slip?.due_date &&
              formatDate(bankSlip?.bank_slip?.due_date)}
          </TextPage>
        </GreyContainer>
        <ContainerPad style={{ paddingBottom: 25 }}>
          <TextPage style={{ marginBottom: 40 }} tiny center>
            Este boleto estará disponível para pagamento em até 30 minutos e
            levará 2 dias úteis para ser creditado em sua conta.
          </TextPage>

          <ButtonBase
            onPress={() => onShare(bankSlip?.bank_slip?.url)}
            style={{ marginBottom: 10 }}
            title="COMPARTILHAR BOLETO"
            color="outline-orange"
          />
          <ButtonBase
            onPress={() => copyClipboard()}
            title="COPIAR CÓDIGO DE BARRAS"
            color="orange"
          />
        </ContainerPad>
      </ContainerBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
    />
  );
};

export default DepositBankSlipSuccess;
