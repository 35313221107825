import axiosInstance from "../helpers/axiosInstance";

export default (setState) => {
  const uniq = (a) => {
    return a.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );
  };

  const formatBankData = (data) => {
    let bData = [];
    if (data != undefined) {
      for (let i = 0; i < data.length; i++) {
        bData.push({ label: data[i].Name, value: data[i].Code });
      }
      setState(uniq(bData));
    }
  };

  axiosInstance
    .get("bank")
    .then((res) => {
      formatBankData(res.data);
    })
    .catch((err) => {});
};
