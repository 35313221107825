import { useToast } from "native-base";
import axiosInstance from "../helpers/axiosInstance";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { ButtonWrapContent } from "./buttons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useState } from "react";

export default ({ item }) => {
  const [loadingExtra, setLoadingExtra] = useState(false);
  const toast = useToast();

  const generateVoucher = (code) => {
    setLoadingExtra(true);
    axiosInstance
      .post("transaction/voucher", {
        code: code,
      })
      .then((res) => {
        onShare(res.data?.voucher);
      })
      .catch((err) => {
        setLoadingExtra(false);
        toast.show({
          description: "Erro ao gerar",
          placement: "top",
        });
      });
  };

  const onShare = async (voucher) => {
    var fileShare = "";
    try {
      const { uri: localUrl } = await FileSystem.downloadAsync(
        voucher,
        FileSystem.documentDirectory + "documentotimepay.pdf"
      );
      fileShare = localUrl;
    } catch (error) {
      fileShare = voucher;
    }

    if (!(await Sharing.isAvailableAsync())) {
      toast.show({
        description: "Conceda permissão para compartilhar",
        placement: "top",
      });
      setLoadingExtra(false);
      return;
    }
    await Sharing.shareAsync(fileShare);
    setLoadingExtra(false);
  };

  if (item.transaction_type_id == "5") {
    return (
      <ButtonWrapContent
        onPress={() => onShare(item.bank_slip?.url)}
        loading={loadingExtra}
      >
        <MaterialCommunityIcons
          style={{ marginLeft: 5 }}
          name="paperclip"
          size={26}
          color="black"
        />
      </ButtonWrapContent>
    );
  } else if (
    item.transaction_type_id == "10" ||
    item.transaction_type_id == "25" ||
    item.transaction_type_id == "30"
  ) {
    return (
      <ButtonWrapContent
        onPress={() =>
          item.voucher ? onShare(item.voucher) : generateVoucher(item.code)
        }
        loading={loadingExtra}
      >
        <MaterialCommunityIcons
          style={{ marginLeft: 5 }}
          name="paperclip"
          size={26}
          color="black"
        />
      </ButtonWrapContent>
    );
  }

  return null;
};
