import styled, { css } from "styled-components/native";

export const InputContainer = styled.View`
  position: relative;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Montserrat_400Regular";
`;

export const InputError = styled.Text`
  font-size: 12px;
  color: #ff1d43;
  margin-top: 5px;
`;

export const InputBase = styled.TextInput`
  font-family: "Montserrat_400Regular";
  border-bottom-color: #333333;
  border-bottom-width: 1px;
  color: #000000;
  font-size: 14px;
  padding: 5px;
  ${({ bigText }) => bigText && "font-size:20px"};
  ${({ borderFull }) =>
    borderFull &&
    "border-bottom-color:#DDDDDD; border-width:1px; border-color: #DDDDDD; border-radius:5px; "};
`;

export const LabelBase = styled.Text`
  font-family: "Montserrat_400Regular";
  color: #666666;
  font-size: 14px;
`;

export const InputButton = styled.TouchableOpacity`
  position: absolute;
  padding: 5px;
  right: 0px;
  bottom: 5px;
`;

export const DateInputBox = styled.TouchableOpacity`
  border-bottom-color: #333333;
  border-bottom-width: 1px;
  color: #000000;
  padding: 5px;
`;

export const DateInputText = styled.Text`
  font-family: "Montserrat_400Regular";
  color: #000000;
  font-size: 14px;
`;

export const SelectList = styled.View`
  border-top-color: #eeeeee;
  border-top-width: 1px;
`;

export const SelectListOption = styled.TouchableOpacity`
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  padding: 10px 15px;
`;

export const InputTooltip = styled.View`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const InputTooltipButton = styled.TouchableOpacity`
  padding: 5px;
`;

export const TooltipBox = styled.View`
  position: absolute;
  left: -100px;
  width: 100px;
  border-radius: 5px;
  padding: 5px;
  background-color: #686868;
`;

export const TooltipText = styled.Text`
  font-family: "Montserrat_400Regular";
  color: #fff;
  font-size: 9px;
`;

export const SelectListOptionText = styled.Text`
  font-family: "Montserrat_400Regular";
  color: #000000;
  font-size: 14px;
`;
