import React from "react";
import { Image, View, Text, useWindowDimensions } from "react-native";
import { ScrollView, Box } from "native-base";
import { ButtonBase, ButtonBack } from "../../../components/buttons";
import { Carousel } from "../../../components/carousel";
import { useNavigation } from "@react-navigation/core";
import { REGISTER_PHOTO } from "../../../constants/routes";
import { HeaderBase } from "../../../styles/default";

const data = [
  <View style={{ paddingTop: 0, paddingBottom: 50 }}>
    <Image
      style={{
        width: 175,
        height: 175,
        marginTop: 75,
        resizeMode: "contain",
        alignSelf: "center",
      }}
      source={require("../../../assets/images/photo-instructions1.png")}
    />
    <Text style={{ textAlign: "center", marginTop: 25 }}>
      Para escanear o documento, retire-o da capa protetora e deixe-o em uma
      superfície plana.
    </Text>
  </View>,
  <View style={{ paddingTop: 0, paddingBottom: 50 }}>
    <Image
      style={{
        width: 250,
        height: 250,
        resizeMode: "contain",
        alignSelf: "center",
      }}
      source={require("../../../assets/images/photo-instructions2.png")}
    />
    <Text style={{ textAlign: "center", marginTop: 25 }}>
      Não se esqueça de verificar a iluminação do ambiente.
    </Text>
  </View>,
];

const RegisterInstructions = ({ navigation }) => {
  const { width } = useWindowDimensions();
  const { navigate } = useNavigation();

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ScrollView style={{ padding: 15 }}>
        <Carousel data={data} containerSize={width - 30} itensView={1} />
      </ScrollView>
      <View style={{ padding: 15 }}>
        <ButtonBase
          onPress={() => navigate(REGISTER_PHOTO)}
          title="CONTINUAR"
          color="outline-black"
        />
      </View>
    </Box>
  );
};

export default RegisterInstructions;
