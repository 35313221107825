import React, { useEffect, useState, useRef } from "react";
import { Animated, View, StyleSheet } from "react-native";
import { Flex, Image, Text, useToast } from "native-base";
import { TextPage, TitlePage } from "../../../styles/typograph";
import { ContainerBase, GreyContainer } from "../../../styles/default";
import { useNavigation } from "@react-navigation/core";
import {
  CARDS,
  CARDS_FACE,
  CARDS_PASSWORD_SUCCESS,
} from "../../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import { ButtonContainer } from "../../../styles/buttons";
import { TemplatePage } from "../../../components/templates";
import { ButtonBox, CardContainer } from "./styles";
import { ButtonBase } from "../../../components/buttons";
import { ModalBase } from "../../../components/modals";
import { TextInputBase } from "../../../components/inputs";

const CardPassword = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const toast = useToast();

  useEffect(() => {}, []);

  const styles = StyleSheet.create({
    card: {
      width: 300,
      height: 100,
      backgroundColor: "#ff671d",
      borderRadius: 15,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingBottom: 15,
      marginBottom: 15,
    },
  });

  const handlePassword = () => {
    setModal(false);
    setModalConfirm(true);
  };

  const handleConfirmPassword = () => {
    if (password == passwordConfirmation) {
      navigate(CARDS_PASSWORD_SUCCESS);
    } else {
      toast.show({
        description: "Sua senha não é igual a confirmação",
        placement: "top",
      });
    }
  };

  const pageContent = (
    <>
      <TitlePage style={{ marginBottom: 20, marginLeft: 15 }}>
        Meus cartões
      </TitlePage>

      <CardContainer>
        <View style={styles.card}>
          <Text style={{ fontSize: 18, color: "#000000", marginBottom: 5 }}>
            Cartão 80395415
          </Text>
          <TextPage tiny center>
            Aguardando criar senha
          </TextPage>
        </View>
      </CardContainer>
      <TextPage tiny center style={{ marginBottom: 10 }}>
        Ativado em 06/04/2022 às 19:36h
      </TextPage>
      <ButtonBox>
        <ButtonContainer
          onPress={() => setModal(true)}
          style={{ padding: 10, marginBottom: 15 }}
        >
          <TextPage center>CRIAR SENHA DO CARTÃO</TextPage>
        </ButtonContainer>
      </ButtonBox>
      <GreyContainer>
        <TextPage>Importante!</TextPage>
        <TextPage style={{ marginBottom: 15 }} TextPage="medium">
          A senha do cartão é diferente da senha transacional ou de acesso ao
          aplicativo.
        </TextPage>
      </GreyContainer>
      <ModalBase
        header="CRIAR SENHA DO CARTÃO"
        modal={modal}
        setModal={setModal}
        type="bottom"
      >
        <TextInputBase
          name={"password"}
          label={"Atenção! Você está criando uma senha de 4 dígitos do cartão"}
          onChangeText={setPassword}
          value={password}
          maxLength={4}
          keyboardType="numeric"
          secureTextEntry={true}
        />

        <ButtonBase
          onPress={() => handlePassword()}
          title="CONFIRMAR"
          color="orange"
        />
      </ModalBase>

      <ModalBase
        header="CONFIRME A SENHA DO CARTÃO"
        modal={modalConfirm}
        setModal={setModalConfirm}
        type="bottom"
      >
        <TextInputBase
          name={"passwordConfirmation"}
          label={"Atenção! Você está criando uma senha de 4 dígitos do cartão"}
          onChangeText={setPasswordConfirmation}
          value={passwordConfirmation}
          maxLength={4}
          keyboardType="numeric"
          secureTextEntry={true}
        />

        <ButtonBase
          onPress={() => handleConfirmPassword()}
          title="CONFIRMAR"
          color="orange"
        />
      </ModalBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={CARDS}
      paddingResetMobile
    />
  );
};

export default CardPassword;
