import React, { useEffect, useState } from "react";
import { Box } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { TitlePage, TextPageDot, TextPage } from "../../styles/typograph";
import { GreyBox } from "./styles";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import {
  REGISTER,
  REGISTER_PASSWORD_CONFIRMATION,
} from "../../constants/routes";
import { TextInputBase } from "../../components/inputs";

const RegisterPassword = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Senha de acesso</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Crie uma senha para efetuar o login
        </TextPage>
        <TextInputBase
          label={"Digite a senha"}
          onChangeText={setPassword}
          buttonIcon={
            passwordVisible ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )
          }
          buttonOnPress={() => setPasswordVisible(!passwordVisible)}
          secureTextEntry={!passwordVisible}
          value={password}
        />

        <GreyBox>
          <TextPage tiny style={{ marginBottom: 10 }}>
            Sua senha precisa ter pelo menos:
          </TextPage>
          <TextPageDot>6 (seis) caracteres</TextPageDot>
          <TextPageDot>Uma letra maiúscula</TextPageDot>
          <TextPageDot style={{ marginBottom: 10 }}>Um número</TextPageDot>
          <TextPage tiny>
            Não use números sequenciais ou a data do seu aniversário.
          </TextPage>
        </GreyBox>

        <ButtonBase
          disabled={password === ""}
          title="CRIAR SENHA"
          onPress={() =>
            navigate(REGISTER_PASSWORD_CONFIRMATION, { password: password })
          }
          color={"orange"}
        />
      </ContainerBase>
    </Box>
  );
};

export default RegisterPassword;
