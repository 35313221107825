import styled from "styled-components/native";

export const DashboardContainer = styled.View`
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
`;

export const DashboardContent = styled.View`
  width: ${({ mobile }) => (mobile ? "100%" : "70%")};
  flex-direction: column;
`;

export const DashboardAside = styled.View`
  padding-left: 10px;
  padding-right: 10px;
  width: ${({ mobile }) => (mobile ? "100%" : "30%")};
  margin-top: ${({ mobile }) => (mobile ? "0px" : "25px")};
`;

export const DashboardButtonsGroup = styled.View`
  margin-top: 25px;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  flex-wrap: wrap;
`;

export const DashboardButtonsGroupMobile = styled.View`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DashboardButton = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  border-color: #ff671d;
  border-radius: 5px;
  margin-bottom: 10px;
  border-width: 1px;
  width: 110px;
  height: 100px;
`;

export const DashboardButtonImage = styled.View`
  align-items: center;
  margin-bottom: 10px;
`;

export const DashboardBannersMobile = styled.View`
  background-color: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  z-index: 10;
`;

export const DashboardBanners = styled.View`
  padding-top: 25px;
  padding-bottom: 0px;
  z-index: 10;
`;

export const DashboardBottomGroup = styled.View`
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DashboardBigButton = styled.TouchableOpacity`
  flex-direction: row;
  border-color: #dddddd;
  border-width: 1px;
  border-radius: 5px;
  padding: 15px 10px;
  ${({ orange }) => orange && "background-color:#FF671D; border-color:#FF671D"};
  margin-bottom: 10px;
  justify-content: space-between;
  margin-right: ${({ mobile }) => (mobile ? "0px" : "5px")};
`;

export const DashboardBigButtonContent = styled.TouchableOpacity`
  flex: 1;
  justify-content: center;
  max-width: 210px;
`;

export const DashboardBigButtonIcon = styled.TouchableOpacity`
  width: 65px;
  justify-content: center;
  align-items: center;
`;

export const DashboardHistoryList = styled.View``;
