import styled from "styled-components/native";

export const SidebarContent = styled.ScrollView`
  flex: 1;
  border-color: #dddddd;
  border-width: 1px;
`;

export const ProfileContainer = styled.View`
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  margin-bottom: 5px;
  border-bottom-color: #dddddd;
  border-bottom-width: 1px;
  font-family: "Montserrat_400Regular";
`;

export const ProfileImage = styled.View`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContent = styled.View`
  flex: 1;
`;

export const MenuItem = styled.TouchableOpacity`
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 5px;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
`;

export const MenuText = styled.Text`
  flex: 1;
  font-size: 16px;
  color: #ff671d;
  font-family: "Montserrat_500Medium";
`;

export const MenuIcon = styled.View`
  width: 50px;
  justify-content: center;
  align-items: center;
`;

export const HeaderMenu = styled.View`
  background-color: #fff;
  padding-left: 10px;
  padding-top: 5px;
`;
