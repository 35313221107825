import React, { useState, useEffect, useContext } from "react";
import { FlatList, ScrollView } from "react-native";
import { useToast } from "native-base";
import { TextPage, TitlePage } from "../../../styles/typograph";
import { ContainerBase, FooterBase } from "../../../styles/default";
import { ButtonBase } from "../../../components/buttons";
import { TextInputBase, DateInput } from "../../../components/inputs";
import { CARDS, CARDS_NEW_CONFIRM } from "../../../constants/routes";
import axiosInstance from "../../../helpers/axiosInstance";
import { GlobalContext } from "../../../contexts/Provider";
import { cepMask } from "../../../helpers/masks";
import { Formik } from "formik";
import { TemplatePage } from "../../../components/templates";
import { RegisterType, ContainerFee } from "./styles";

const NewCard = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [loading, setLoading] = useState(false);
  const [cepData, setCepData] = useState(false);
  const [registerType, setRegisterType] = useState({
    id: 1,
    name: "Do cadastro",
  });
  const [registerTypeList, setRegisterTypeList] = useState([
    {
      id: 1,
      name: "Do cadastro",
    },
    { id: 2, name: "Outro endereço" },
  ]);
  const emptyFields = {
    name: "",
    zipcode: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  };
  const [initialValues, setInitialValues] = useState(emptyFields);
  const toast = useToast();
  const {
    authState: { authData },
  } = useContext(GlobalContext);

  useEffect(() => {
    registerType?.id == "2" ? resetFields() : setDefaultValues();
  }, [registerType]);

  const resetFields = () => {
    setInitialValues({
      ...emptyFields,
      name: authData?.user?.customer?.name.substring(0, 20),
    });
  };

  const setDefaultValues = () => {
    let RegisterValues = {};
    let completeAddress = authData?.user?.customer?.address.split(",");
    RegisterValues.name = authData?.user?.customer?.name.substring(0, 20);
    RegisterValues.zipcode = authData?.user?.customer?.zipcode;
    RegisterValues.address = completeAddress[0]
      ? completeAddress[0].trim()
      : "";
    RegisterValues.number = completeAddress[1] ? completeAddress[1].trim() : "";
    RegisterValues.complement = authData?.user?.customer?.complement;
    RegisterValues.neighborhood = authData?.user?.customer?.neighborhood;
    RegisterValues.city = authData?.user?.customer?.city;
    RegisterValues.state = authData?.user?.customer?.state;
    setInitialValues({ ...RegisterValues });
  };

  const setDataAddress = (addressData) => {
    let RegisterValues = {};

    RegisterValues.name = authData?.user?.customer?.name.substring(0, 20);
    RegisterValues.zipcode = addressData.cep;
    RegisterValues.address = addressData.logradouro;
    RegisterValues.complement = addressData.complemento;
    RegisterValues.neighborhood = addressData.bairro;
    RegisterValues.city = addressData.localidade;
    RegisterValues.state = addressData.uf;

    setInitialValues({ ...RegisterValues });
  };

  const onSubmitForm = async (values) => {
    setLoading(true);
    try {
      navigate(CARDS_NEW_CONFIRM, { ...values, registerType });
    } catch (err) {
      toast.show({
        description: "Erro",
        placement: "top",
      });
    }
    setLoading(false);
  };

  const validateData = (values) => {
    let errors = {};

    if (!values.zipcode) {
      errors.zipcode = "Cep obrigatório";
    }
    if (values.zipcode?.length < 9) {
      errors.zipcode = "Cep formato inválido";
    }

    if (!values.name) {
      errors.name = "Nome obrigatório";
    }

    if (!values.address) {
      errors.address = "Endereço obrigatório";
    }
    if (!values.number) {
      errors.number = "Número obrigatório";
    }
    if (!values.neighborhood) {
      errors.neighborhood = "Bairro obrigatório";
    }
    if (!values.city) {
      errors.city = "Cidade obrigatória";
    }
    if (!values.state) {
      errors.state = "Estado obrigatório";
    }

    return errors;
  };

  const handleCep = (v, setFieldValue, setFieldTouched) => {
    setFieldValue("zipcode", cepMask(v));
    setFieldTouched("zipcode", true, false);
    if (cepMask(v).length >= 9) {
      getData(v);
    }
  };

  const getData = (zipcode) => {
    setLoading(true);
    axiosInstance
      .post("customer-auth/zipcode", {
        zipcode: zipcode,
      })
      .then((res) => {
        setDataAddress(res.data);
        setLoading(false);
        setCepData(false);
      })
      .catch((err) => {
        setLoading(false);
        setCepData(true);
      });
  };

  const pageContent = (
    <Formik
      enableReinitialize
      validateOnMount={true}
      validate={validateData}
      initialValues={initialValues}
      onSubmit={(values) => onSubmitForm(values)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
        isValid,
      }) => (
        <>
          <ContainerBase>
            <TitlePage style={{ marginBottom: 25 }}>
              Solicitar novo cartão
            </TitlePage>

            <TextInputBase
              name={"name"}
              label={"Nome para o cartão (20 caracteres)"}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
              errors={errors.name}
              touched={touched.name}
              maxLength={20}
            />

            <TextPage>Endereço de entrega</TextPage>
            <ScrollView
              style={{ marginBottom: 10 }}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {registerTypeList &&
                registerTypeList.map((item) => {
                  return (
                    <RegisterType key={item.id.toString()}>
                      <ButtonBase
                        tiny
                        onPress={() => setRegisterType(item)}
                        title={item.name}
                        color={
                          registerType.id == item.id
                            ? "orange"
                            : "outline-black"
                        }
                      />
                    </RegisterType>
                  );
                })}
            </ScrollView>

            <TextInputBase
              keyboardType="numeric"
              name={"zipcode"}
              label={"CEP"}
              onChangeText={(v) => {
                handleCep(v, setFieldValue, setFieldTouched);
              }}
              onBlur={handleBlur("zipcode")}
              value={values.zipcode}
              errors={errors.zipcode}
              touched={touched.zipcode}
            />

            <TextInputBase
              name={"address"}
              label={"Endereço"}
              onChangeText={handleChange("address")}
              onBlur={handleBlur("address")}
              value={values.address}
              errors={errors.address}
              touched={touched.address}
              editable={cepData}
            />

            <TextInputBase
              name={"number"}
              label={"Número"}
              onChangeText={handleChange("number")}
              onBlur={handleBlur("number")}
              value={values.number}
              errors={errors.number}
              touched={touched.number}
            />

            <TextInputBase
              name={"complement"}
              label={"Complemento"}
              onChangeText={handleChange("complement")}
              onBlur={handleBlur("complement")}
              value={values.complement}
              errors={errors.complement}
              touched={touched.complement}
            />

            <TextInputBase
              name={"neighborhood"}
              label={"Bairro"}
              onChangeText={handleChange("neighborhood")}
              onBlur={handleBlur("neighborhood")}
              value={values.neighborhood}
              errors={errors.neighborhood}
              touched={touched.neighborhood}
              editable={cepData}
            />
            <TextInputBase
              name={"city"}
              label={"Cidade"}
              onChangeText={handleChange("city")}
              onBlur={handleBlur("city")}
              value={values.city}
              errors={errors.city}
              touched={touched.city}
              editable={cepData}
            />
            <TextInputBase
              name={"state"}
              label={"Estado"}
              onChangeText={handleChange("state")}
              onBlur={handleBlur("state")}
              value={values.state}
              errors={errors.state}
              touched={touched.state}
              editable={cepData}
            />
          </ContainerBase>
          <FooterBase>
            <ButtonBase
              loading={loading}
              disabled={!isValid}
              onPress={() => handleSubmit()}
              title="CONTINUAR"
              color="orange"
            />
          </FooterBase>
        </>
      )}
    </Formik>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={CARDS}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default NewCard;
