import React from "react";
import { View } from "react-native";
import { Fade, Placeholder, PlaceholderLine } from "rn-placeholder";

export const PlaceholderNotifications = () => (
  <>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 15 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
  </>
);
