import React, { useState, useEffect, useContext } from "react";
import { Box, useToast, Image, Text } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerPad,
  FooterBase,
  GreyContainer,
} from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { DateInput, TextInputBase, SwitchBase } from "../../components/inputs";
import { TRANSFER } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { GlobalContext } from "../../contexts/Provider";
import { cpfCnpjMask, moneyMask } from "../../helpers/masks";
import { TemplatePage } from "../../components/templates";

import {
  ProfileContainer,
  ProfileImage,
  ProfileContent,
  ContainerBorder,
  ContainerBorderless,
} from "./styles";
import {
  clearCaracters,
  formatMoney,
  formatDecimal,
  formatDateFitFormat,
  formatDateInput,
} from "../../helpers/format";
import TransactionPassword from "../../components/transactionPassword";
import TransactionValueConfirm from "../../components/transactionValueConfirm";

const TransferFitBank = ({ navigation, route }) => {
  const { navigate } = navigation;
  const toast = useToast();
  const {
    balance,
    settings,
    authState: { authData },
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [miniumPaymentDate, setMiniumPaymentDate] = useState(new Date());
  const [confirmDate, setConfirmDate] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [saveContact, setSaveContact] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmValue, setShowConfirmValue] = useState(false);
  const [mvTranfer, setMvtransfer] = useState();
  const [validateAmount, setValidateAmount] = useState();
  const [scheduled, setScheduled] = useState(false);

  useEffect(() => {
    getTranferDate();
  }, []);

  useEffect(() => {
    comparePaymentDate();
  }, [paymentDate]);

  useEffect(() => {
    if (!route.params?.cpf_cnpj) {
      navigate(TRANSFER);
    }
  }, [route.params?.cpf_cnpj]);

  const getTranferDate = () => {
    setLoading(true);
    axiosInstance
      .get("transaction/transfer-date")
      .then((res) => {
        setLoading(false);
        let formatedDate = formatDateFitFormat(res.data);
        setMiniumPaymentDate(formatedDate);
        compareDate(formatedDate);
        setPaymentDate(formatedDate);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const comparePaymentDate = () => {
    let today = new Date();
    if (paymentDate > today) {
      setScheduled(true);
    }
  };

  const compareDate = (date) => {
    if (
      date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear() !=
      paymentDate.getDate() +
        "-" +
        paymentDate.getMonth() +
        "-" +
        paymentDate.getFullYear()
    ) {
      setScheduled(true);
      toast.show({
        description:
          "Horário limite atingido, a transferência só poderá ser realizado no próximo dia útil ",
        placement: "top",
      });
    }
  };

  const makeInternalTransfer = async (code) =>
    axiosInstance.post("transaction/internal-transfer", {
      code: code,
      cpf_cnpj: clearCaracters(route.params?.cpf_cnpj),
      amount: formatDecimal(amount),
      description: description,
      balance: balance,
      payment_date: formatDateInput(paymentDate),
    });

  const handleConfirmValue = () => {
    if (!scheduled && balance - formatDecimal(amount) < 0) {
      toast.show({
        description: "Saldo insuficiente",
        placement: "top",
      });
      return;
    }
    if (formatDecimal(amount) < settings?.mv_internal_transferout) {
      setValidateAmount(
        "Valor mínimo: R$" + formatMoney(settings?.mv_internal_transferout)
      );
      return;
    }

    setShowConfirmValue(true);
  };

  const fakeTransfer = async () =>
    axiosInstance.post("transaction/fake-transfer", {
      amount: formatDecimal(amount),
    });

  const pageContentDate = (
    <>
      <ContainerBase paddingReset={true}>
        <ContainerPad style={{ paddingBottom: 0 }}>
          <TitlePage style={{ marginBottom: 5 }}>Transferir</TitlePage>
          <TextPage style={{ marginBottom: 30 }}>
            para {route.params?.name}
          </TextPage>
        </ContainerPad>
        <GreyContainer>
          <TextPage TextPage="medium">CPF/CNPJ</TextPage>
          <TextPage style={{ marginBottom: 25 }}>
            {cpfCnpjMask(route.params?.cpf_cnpj)}
          </TextPage>

          <TextPage TextPage="medium">Tipo de transferência</TextPage>
          <TextPage>Entre contas Time Pay</TextPage>
        </GreyContainer>
        <ContainerPad>
          <DateInput
            label="Data de transferência"
            minimumDate={miniumPaymentDate}
            valueFormated={
              paymentDate.getDate() +
              "/" +
              (paymentDate.getMonth() + 1) +
              "/" +
              paymentDate.getFullYear()
            }
            value={paymentDate}
            setValue={setPaymentDate}
          />
        </ContainerPad>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          loading={loading}
          onPress={() => setConfirmDate(true)}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  const pageContentValue = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 5 }}>
          Quanto você quer transferir?
        </TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          Seu saldo disponível em conta
          <TextPage color="orange">
            {balance && " R$" + formatMoney(balance)}
          </TextPage>
        </TextPage>

        <TextInputBase
          keyboardType="numeric"
          name={"amount"}
          onChangeText={(v) => {
            setAmount(moneyMask(v));
          }}
          value={amount}
          bigText
          errors={validateAmount}
          touched={true}
        />
        <TextInputBase
          name={"description"}
          label={"Descrição(opcional)"}
          onChangeText={setDescription}
          value={description}
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={amount == "" || amount == undefined || amount.length < 4}
          onPress={() => handleConfirmValue()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
      <TransactionValueConfirm
        modal={showConfirmValue}
        setModal={setShowConfirmValue}
        setConfirmValue={setConfirmValue}
        amount={formatDecimal(amount)}
      />
    </>
  );

  const pageContentData = (
    <>
      <ContainerBase paddingReset={true}>
        <ContainerPad>
          <TitlePage>Transferir</TitlePage>
        </ContainerPad>
        <ContainerBorder>
          <TextPage>De</TextPage>
          <ProfileContainer>
            <ProfileImage>
              <Image
                source={require("../../assets/images/default-photo.png")}
                size="50"
                borderRadius="100"
                alt="photo"
              />
            </ProfileImage>
            <ProfileContent>
              <TextPage fontWeight="medium">{authData?.user?.name}</TextPage>
              <TextPage>CPF: {cpfCnpjMask(authData?.user?.cpf_cnpj)}</TextPage>
            </ProfileContent>
          </ProfileContainer>
        </ContainerBorder>
        <ContainerBorder>
          <TextPage>Para</TextPage>
          <TextPage fontWeight="medium">{route.params?.name}</TextPage>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">{"R$" + amount}</TextPage>
          <TextPage tiny>
            tarifa
            {authData?.user?.fee?.internal_transfer_out &&
              " R$" + authData?.user?.fee?.internal_transfer_out}
          </TextPage>
        </ContainerBorderless>
        <GreyContainer style={{ marginBottom: 15 }}>
          <TextPage TextPage="medium">CPF/CNPJ</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            {cpfCnpjMask(route.params?.cpf_cnpj)}
          </TextPage>

          <TextPage TextPage="medium">Tipo de transferência</TextPage>
          <TextPage>Entre contas Time Pay</TextPage>
        </GreyContainer>
        <ContainerPad>
          <SwitchBase
            style={{ marginBottom: 10 }}
            label="Salvar contato"
            value={saveContact}
            setValue={(v) => setSaveContact(v)}
          />
        </ContainerPad>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => setShowPassword(true)}
          title="CONFIRMAR"
          color="orange"
        />
      </FooterBase>
      <TransactionPassword
        modal={showPassword}
        setModal={setShowPassword}
        transaction={makeInternalTransfer}
        transactionData={{
          name: route.params?.name,
          amount: formatDecimal(amount),
          description: description,
          transaction_type_id: 30,
          fee: authData?.user?.fee?.internal_transfer_out,
          cpf_cnpj: clearCaracters(route.params?.cpf_cnpj),
          bank: 450,
          bank_name: "Timepay (Fitbank)",
          type: { id: 1, name: "Conta corrente" },
          save: saveContact,
          scheduled: scheduled,
        }}
      />
    </>
  );

  const pageContent = () => {
    if (confirmValue) return pageContentData;
    if (confirmDate) return pageContentValue;

    return pageContentDate;
  };

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent()}
      backNavigate={TRANSFER}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferFitBank;
