import styled from "styled-components/native";

export const ContainerBorder = styled.View`
  margin-bottom: 15px;
  padding: 15px;
  border-bottom-color: #dddddd;
  border-bottom-width: 1px;
`;

export const ContainerBorderless = styled.View`
  margin-bottom: 15px;
  padding: 15px;
`;

export const ProfileContainerBorder = styled.TouchableOpacity`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
  border-color: #dddddd;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px 5px;
  margin-bottom: 5px;
`;

export const ProfileContainer = styled.View`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
`;

export const ProfileImage = styled.View`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContent = styled.View`
  flex: 1;
`;

export const ProfileImageButton = styled.TouchableOpacity`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContentButton = styled.TouchableOpacity`
  flex: 1;
`;

export const ProfileButton = styled.TouchableOpacity`
  flex: 0 0 40px;
  justify-content: center;
  align-items: center;
`;
