import React, { useContext, useState } from "react";
import { breakpointDown } from "../helpers/breakpoints";
import {
  BalanceBox,
  BalanceLastRefresh,
  BalanceLine,
  BalanceValue,
  BaseColumn,
  BaseColumnFlexAuto,
  BaseRow,
} from "../styles/default";
import { TextPage } from "../styles/typograph";
import { ButtonWrapContent } from "./buttons";
import { PlaceholderBalance, PlaceholderBalanceHide } from "./placeholders";
import { formatMoney, secondsToMinutes } from "../helpers/format";
import { GlobalContext } from "../contexts/Provider";
import { useWindowDimensions } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import getBalance from "../actions/getBalance";

export default ({ tinyBox }) => {
  const { width } = useWindowDimensions();
  const [showBalance, setShowBalance] = useState(false);
  const { balance, setBalance, balanceLastRefresh, resetBalanceLastRefresh } =
    useContext(GlobalContext);

  const handleRefreshBalance = () => {
    setBalance(undefined);
    getBalance(setBalance);
    resetBalanceLastRefresh(0);
  };

  return (
    <BalanceBox
      style={
        tinyBox ? { paddingTop: 10, paddingBottom: 10, minHeight: 80 } : {}
      }
      mobile={breakpointDown(width, "lg")}
    >
      <BaseRow>
        <BaseColumn>
          <TextPage>Saldo disponível</TextPage>
        </BaseColumn>
        <BaseColumnFlexAuto>
          {secondsToMinutes(balanceLastRefresh.current) > 0 && (
            <ButtonWrapContent onPress={() => handleRefreshBalance()}>
              <BalanceLastRefresh>
                há {secondsToMinutes(balanceLastRefresh.current)}min{" "}
                <Feather name="refresh-cw" size={12} color="black" />
              </BalanceLastRefresh>
            </ButtonWrapContent>
          )}
        </BaseColumnFlexAuto>
      </BaseRow>

      {balance ? (
        <BalanceLine>
          {showBalance ? (
            <BalanceValue>R$ {formatMoney(balance)}</BalanceValue>
          ) : (
            <PlaceholderBalanceHide />
          )}
          <ButtonWrapContent
            onPress={() => setShowBalance(!showBalance)}
            style={{ alignSelf: "flex-end", padding: 5 }}
          >
            {showBalance ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )}
          </ButtonWrapContent>
        </BalanceLine>
      ) : (
        <PlaceholderBalance />
      )}
    </BalanceBox>
  );
};
