import React, { useEffect, useState } from "react";
import { Box, ScrollView } from "native-base";
import { Image } from "native-base";
import { TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { BENEFITS, LOGIN, REGISTER } from "../../constants/routes";
import { BenefitsContainer } from "./styles";
import { ImageBackground, Text, View } from "react-native";

const BenefitsDetail = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [benefit, setBenefit] = useState({});

  useEffect(() => {
    if (route.params?.benefit) {
      setBenefit(route.params?.benefit);
    } else {
      navigate(BENEFITS);
    }
  }, [route.params?.benefit]);

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <ContainerBase paddingReset>
        <ImageBackground
          source={{
            uri: benefit.photo,
          }}
          style={{
            height: 250,
            resizeMode: "cover",
          }}
        >
          <ButtonBack
            style={{ marginTop: 5, marginLeft: 5 }}
            onPress={navigation.goBack}
            color="white"
            size={24}
          />
        </ImageBackground>

        <BenefitsContainer
          style={{
            borderTopColor: "#ff671d",
            backgroundColor: "#F8F8F8",
            borderTopWidth: 2,
            marginTop: 0,
            marginBottom: 10,
            padding: 10,
            paddingTop: 25,
            paddingBototm: 25,
          }}
        >
          <TitlePage style={{ marginBottom: 25 }}>{benefit.title}</TitlePage>
          <Text>{benefit.description}</Text>
        </BenefitsContainer>
        <View style={{ padding: 10 }}>
          <ButtonBase
            title="ABRIR CONTA"
            color="orange"
            onPress={() => navigate(REGISTER)}
          />
          <ButtonBase
            title="JÁ TENHO CONTA"
            color="outline-black"
            onPress={() => navigate(LOGIN)}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
        </View>
      </ContainerBase>
    </Box>
  );
};

export default BenefitsDetail;
