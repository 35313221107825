import React from "react";
import { View, TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";

export const Carousel = ({
  data = [],
  containerSize = 0,
  showPaginator = true,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState();
  const slidesRef = React.useRef(null);

  const clickItem = (i) => {
    slidesRef.current.scrollToIndex({ animated: true, index: i });
  };

  const renderItem = ({ item }) => {
    var renderStyle = {
      alignSelf: "flex-start",
      paddingLeft: 10,
      justifyContent: "center",
      alignItems: "center",
    };
    if (containerSize != 0) {
      renderStyle = {
        width: containerSize,
        paddingLeft: 10,
        justifyContent: "center",
        alignItems: "center",
      };
    }

    return <View style={renderStyle}>{item}</View>;
  };

  const Paginator = () => {
    return (
      <View
        style={{ flexDirection: "row", height: 30, justifyContent: "center" }}
      >
        {data.map((_, i) => {
          return (
            <TouchableOpacity
              style={{ padding: 5 }}
              onPress={() => clickItem(i)}
              key={i.toString()}
            >
              <View
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 5,
                  backgroundColor: currentIndex == i ? "#FF671D" : "#A7A7A7",
                }}
              ></View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  const onViewableItemsChanged = React.useRef(({ viewableItems }) => {
    setCurrentIndex(viewableItems[0]?.index);
  }).current;

  return (
    <View>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(_, index) => index.toString()}
        ref={slidesRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        onViewableItemsChanged={onViewableItemsChanged}
        viewabilityConfig={{
          viewAreaCoveragePercentThreshold: 50,
        }}
      />
      {showPaginator && <Paginator />}
    </View>
  );
};
