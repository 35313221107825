import React, { useState, useEffect, useContext } from "react";
import { Platform } from "react-native";
import { TextPage, TitleOrange, TitlePage } from "../../styles/typograph";
import { ContainerBase, FooterBase, HeaderBase } from "../../styles/default";
import {
  DASHBOARD,
  TRANSFER_PIX_COPY_PASTE,
  TRANSFER_PIX_KEY,
  TRANSFER_PIX_QR,
} from "../../constants/routes";
import { TemplatePage } from "../../components/templates";
import {
  ButtonIconsContainer,
  ButtonIconsRight,
  ButtonIconsContent,
} from "../../styles/buttons";
import { AntDesign } from "@expo/vector-icons";

const TransferPix = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [checkMobile, setCheckMobile] = useState(false);

  useEffect(() => {
    if (Platform.OS == "android" || Platform.OS == "ios") setCheckMobile(true);
  }, []);

  const pageContent = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Você quer pagar?</TitlePage>
        <ButtonIconsContainer
          onPress={() => navigate(TRANSFER_PIX_KEY)}
          style={{ marginBottom: 20 }}
        >
          <ButtonIconsContent
            style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
          >
            <TextPage fontWeight="medium">Chave Pix</TextPage>
            <TextPage tiny>
              CPF/CNPJ, celular, e-mail ou chave aleatória
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
        {checkMobile && (
          <ButtonIconsContainer
            onPress={() => navigate(TRANSFER_PIX_QR)}
            style={{ marginBottom: 20 }}
          >
            <ButtonIconsContent
              style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
            >
              <TextPage fontWeight="medium">QR Code</TextPage>
              <TextPage tiny>Leia o QR Code com sua câmera para pagar</TextPage>
            </ButtonIconsContent>
            <ButtonIconsRight>
              <AntDesign name="right" size={24} color="black" />
            </ButtonIconsRight>
          </ButtonIconsContainer>
        )}
        <ButtonIconsContainer
          onPress={() => navigate(TRANSFER_PIX_COPY_PASTE)}
          style={{ marginBottom: 20 }}
        >
          <ButtonIconsContent
            style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
          >
            <TextPage fontWeight="medium">Pix copia e cola</TextPage>
            <TextPage tiny>
              Pague colando ou digitando o código gerado para essa transação
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
      </ContainerBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferPix;
