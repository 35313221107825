import React, { useRef, useState, useEffect } from "react";
import { Box } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { TitlePage, TextPage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { REGISTER } from "../../constants/routes";
import { TextInputBase } from "../../components/inputs";
import axiosInstance from "../../helpers/axiosInstance";
import { useToast } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";

const RegisterPasswordConfirmation = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const customer = useRef();
  const password = useRef();
  const toast = useToast();

  useEffect(() => {
    if (route.params?.password) {
      password.current = route.params?.password;
      getCustomer();
    } else {
      navigate(REGISTER);
    }
  }, [route.params?.password]);

  const getCustomer = async () => {
    try {
      customer.current = JSON.parse(await AsyncStorage.getItem("customer"));
    } catch (error) {}
  };

  const finishRegistration = () => {
    if (password.current != passwordConfirmation) {
      toast.show({
        title: "Senhas diferentes",
        status: "error",
        placement: "top",
      });
    } else {
      setLoading(true);

      axiosInstance
        .post("customer/finish-registration", {
          customerId: customer.current.id,
          password: password.current,
          password_confirmation: passwordConfirmation,
        })
        .then((res) => {
          setLoading(false);
          navigate(REGISTER, { passwordConfirmation: 1 });
        })
        .catch((err) => {
          setLoading(false);
          toast.show({
            title: err.message,
            status: "error",
            placement: "top",
          });
        });
    }
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Confirmar senha</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Digite a sua senha novamente
        </TextPage>
        <TextInputBase
          label={"Digite a senha"}
          onChangeText={setPasswordConfirmation}
          buttonIcon={
            passwordVisible ? (
              <Ionicons name="eye" size={24} color="black" />
            ) : (
              <Ionicons name="eye-off" size={24} color="black" />
            )
          }
          buttonOnPress={() => setPasswordVisible(!passwordVisible)}
          secureTextEntry={!passwordVisible}
          value={passwordConfirmation}
        />

        <ButtonBase
          loading={loading}
          disabled={passwordConfirmation === ""}
          title="CONFIMAR"
          onPress={() => finishRegistration()}
          color={"orange"}
        />
      </ContainerBase>
    </Box>
  );
};

export default RegisterPasswordConfirmation;
