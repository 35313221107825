import { Backdrop, Text } from "native-base";
import React, { useRef, useState } from "react";
import { Animated, Dimensions, StyleSheet, View, FlatList } from "react-native";
import { CARDS_DETAILS } from "../constants/routes";
import { ButtonContainer } from "../styles/buttons";
import { TextPage } from "../styles/typograph";

const CardsList = ({ cards, navigate }) => {
  const scrollX = useRef(new Animated.Value(0)).current;
  const cardWidth = 280;

  const CardItem = ({ item, index, scrollX, cardWidth }) => {
    const [showCardData, setShowCardData] = useState(false);

    const inputRange = [
      (index - 1) * (cardWidth + 30),
      index * (cardWidth + 30),
      (index + 1) * (cardWidth + 30),
    ];

    const backgroundColor = scrollX.interpolate({
      inputRange,
      outputRange: ["#707070", "#FF671D", "#707070"],
    });

    const height = scrollX.interpolate({
      inputRange,
      outputRange: [120, 170, 120],
    });

    const opacity = scrollX.interpolate({
      inputRange,
      outputRange: [0, 1, 0],
    });

    const handleShowData = () => {
      setShowCardData(!showCardData);
    };

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 30,
        }}
      >
        <View style={{ height: 170 }}>
          <Animated.View
            style={{
              height,
              backgroundColor,
              width: 280,
              borderRadius: 15,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingBottom: 15,
            }}
          ></Animated.View>
        </View>
        <Animated.View style={{ opacity, padding: 15 }}>
          <ButtonContainer
            onPress={() => navigate(CARDS_DETAILS)}
            style={{ padding: 10 }}
          >
            <TextPage center>DADOS DO CARTÃO</TextPage>
          </ButtonContainer>
        </Animated.View>
      </View>
    );
  };

  return (
    <React.Fragment>
      <FlatList
        data={cards}
        keyExtractor={(item) => item.id.toString()}
        horizontal
        decelerationRate={0}
        snapToInterval={cardWidth + 30}
        snapToAlignment="center"
        scrollEventThrottle={32}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: scrollX } } }],
          { useNativeDriver: false }
        )}
        contentContainerStyle={{ paddingRight: cardWidth / 2 }}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => {
          return (
            <CardItem
              cardWidth={cardWidth}
              scrollX={scrollX}
              item={item}
              index={index}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 170,
    width: 300,
    marginLeft: 15,
    backgroundColor: "#FF671D",
    borderRadius: 15,
  },
  container: {
    flexDirection: "row",
  },
});

export default CardsList;
