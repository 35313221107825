import React, { useState } from "react";
import { ChatInput } from "./components";
import { ButtonBase } from "../../components/buttons";
import axiosInstance from "../../helpers/axiosInstance";
import { FadeInView } from "../../components/animations";
import {
  REGISTER_ADDRESS_DATA,
  REGISTER_PHONE_CONFIRMATION,
  REGISTER_PASSWORD,
  LOGIN,
} from "../../constants/routes";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { cpfCnpjMask, cepMask, phoneMask } from "../../helpers/masks";
import { clearCaracters } from "../../helpers/format";

export const initPresentation = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    { text: "Olá", type: "bot", fade: 1000 },
    {
      text: "Para começar, digite o nome pelo qual quer ser chamado.",
      type: "bot",
      fade: 1500,
    },
  ]);
};

export const FooterPresentation = ({ updateCustomer }) => {
  const [customerName, setCustomerName] = useState();

  return (
    <FadeInView fadeTime={1000}>
      <ChatInput
        disabled={customerName == undefined || customerName == ""}
        value={customerName}
        placeholder="Digite a forma que deseja ser chamado"
        onChangeText={setCustomerName}
        onPress={() =>
          updateCustomer({ nickname: customerName }, [
            { text: customerName, type: "client", fade: 500 },
          ])
        }
      />
    </FadeInView>
  );
};

export const initTerms = (setChatList, nickName) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Estamos felizes em ter você por aqui, " + nickName + "!",
      type: "bot",
      fade: 1000,
    },
    {
      text: "Para continuar, precisamos que você leia atentamente os Termos de Uso da sua conta",
      type: "term",
      fade: 1500,
    },
  ]);
};

export const FooterTerms = ({ updateCustomer }) => {
  return (
    <ButtonBase
      onPress={() =>
        updateCustomer({ term_accepted: 1 }, [
          { text: "Aceito os termos", type: "client", fade: 500 },
        ])
      }
      title="LI E ESTOU DE ACORDO"
      color="yellow"
    />
  );
};

export const initPersonType = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Para quem você quer abrir uma conta hoje?",
      type: "bot",
      fade: 1000,
    },
  ]);
};

export const FooterPersonType = ({ setPersonTypeShow }) => {
  return (
    <ButtonBase
      onPress={() => setPersonTypeShow(true)}
      title="VER OPÇÕES"
      color="outline-white"
    />
  );
};

export const initDocument = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Qual é o número do seu CPF ou CNPJ?",
      type: "bot",
      fade: 1000,
    },
  ]);
};

export const FooterDocument = ({ updateCustomer, customer, toast }) => {
  const [cpfCnpj, setCpfCnpj] = useState();
  const [loading, setLoading] = useState();

  const checkResponseData = (response) => {
    if (response.data == undefined || response.data == null) {
      registerDocument();
    } else if (response.data?.status == 1) {
      toast.show({
        description: "Você já possui conta, efetue seu login",
        placement: "top",
      });
      setLoading(false);
    } else {
      updateCustomer(response.data, [
        { text: cpfCnpj, type: "client", fade: 500 },
      ]);
      setLoading(false);
    }
  };

  const checkClient = () => {
    setLoading(true);

    axiosInstance
      .post("customer/cpf-cnpj", {
        cpf_cnpj: clearCaracters(cpfCnpj),
      })
      .then((res) => {
        checkResponseData(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: "Erro ao checar",
          placement: "top",
        });
      });
  };

  const registerDocument = () => {
    axiosInstance
      .post("customer/create", {
        ...customer,
        cpf_cnpj: clearCaracters(cpfCnpj),
      })
      .then((res) => {
        setLoading(false);
        updateCustomer(res.data, [
          { text: cpfCnpj, type: "client", fade: 500 },
        ]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  return (
    <ChatInput
      disabled={cpfCnpj == undefined || cpfCnpj.length < 14}
      loading={loading}
      value={cpfCnpj}
      placeholder="Digite seu documento"
      onChangeText={(value) => setCpfCnpj(cpfCnpjMask(value))}
      onPress={() => checkClient()}
      keyboardType="numeric"
    />
  );
};

export const initDocumentType = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Tudo certo!",
      type: "bot",
      fade: 1000,
    },
    {
      text: "Agora , qual documento você deseja usar para sua identificação?",
      type: "bot",
      fade: 1500,
    },
  ]);
};

export const FooterDocumentType = ({ setDocumentTypeShow }) => {
  return (
    <ButtonBase
      onPress={() => setDocumentTypeShow(true)}
      title="VER OPÇÕES"
      color="outline-white"
    />
  );
};

export const initAddress = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Dados recebidos!",
      type: "bot",
      fade: 1000,
    },
    {
      text: "Qual o seu endereço? Vamos começar pelo CEP",
      type: "bot",
      fade: 1500,
    },
  ]);
};

export const FooterAddress = ({ navigate }) => {
  const [cep, setCep] = useState();

  const addressData = () => {
    return navigate(REGISTER_ADDRESS_DATA, { cep: cep });
  };

  return (
    <ChatInput
      value={cep}
      placeholder="Informe o CEP"
      keyboardType="numeric"
      onChangeText={(value) => setCep(cepMask(value))}
      onPress={() => addressData()}
    />
  );
};

export const initEmail = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Para qual e-mail podemos enviar as notificações e atualizações?",
      type: "bot",
      fade: 1000,
    },
  ]);
};

export const FooterEmail = ({ updateCustomer, customer, toast }) => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState();

  const registerEmail = () => {
    setLoading(true);

    axiosInstance
      .post("customer/update-validate-email", {
        customerId: customer.id,
        email: email,
      })
      .then((res) => {
        setLoading(false);
        updateCustomer({ email: email }, [
          { text: email, type: "client", fade: 500 },
        ]);
      })
      .catch((err) => {
        setLoading(false);
        err.message == undefined
          ? toast.show({
              description: "Erro ao cadastrar um email",
              placement: "top",
            })
          : toast.show({
              description: err.message,
              placement: "top",
            });
      });
  };

  return (
    <ChatInput
      disabled={email == undefined || email == ""}
      loading={loading}
      value={email}
      placeholder="Digite e-mail"
      onChangeText={setEmail}
      onPress={() => registerEmail()}
    />
  );
};

export const initPhone = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Qual o seu número de celular? (com DDD)",
      type: "bot",
      fade: 1000,
    },
  ]);
};

export const FooterPhone = ({ updateCustomer }) => {
  const [phone, setPhone] = useState();

  return (
    <ChatInput
      maxLength={14}
      value={phone}
      placeholder="Digite seu celular com DDD"
      onChangeText={(value) => setPhone(phoneMask(value))}
      keyboardType="numeric"
      onPress={() =>
        updateCustomer({ phone: phone }, [
          { text: phone, type: "client", fade: 500 },
        ])
      }
    />
  );
};

export const initPhoneConfirmation = (setChatList, phone, toast) => {
  setChatList((prev) => [
    ...prev,
    {
      text: " Por segurança, será enviado um token via SMS para o número de celular cadastrado",
      type: "bot",
      fade: 1000,
    },
  ]);

  const sendSms = () => {
    axiosInstance
      .post("customer/send-sms", {
        phone: clearCaracters(phone),
      })
      .then((res) => {
        toast.show({
          description: "Sms enviado",
          placement: "top",
        });
      })
      .catch((err) => {
        toast.show({
          description: "Erro ao enviar o sms",
          placement: "top",
        });
      });
  };

  sendSms();
};

export const FooterPhoneConfirmation = ({ navigate, phone }) => {
  return (
    <ButtonBase
      onPress={() =>
        navigate(REGISTER_PHONE_CONFIRMATION, { phone: clearCaracters(phone) })
      }
      title="CONTINUAR"
      color="yellow"
    />
  );
};

export const initPassword = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Crie uma senha para fazer o login no app TimePay",
      type: "bot",
      fade: 1000,
    },
    {
      text: "Lembre-se: nao é a mesma senha do cartão, nem da conta corrente.",
      type: "bot",
      fade: 1500,
    },
  ]);
};

export const FooterPassword = ({ navigate }) => {
  return (
    <ButtonBase
      onPress={() => navigate(REGISTER_PASSWORD)}
      title="CRIAR SENHA"
      color="yellow"
    />
  );
};

export const endMessage = (setChatList) => {
  setChatList((prev) => [
    ...prev,
    {
      text: "Login criado com sucesso! Para acessar sua conta, insira o número do seu CPF e sua senha na area de login.",
      type: "bot",
      fade: 1000,
    },
    {
      text: "",
      type: "end",
      fade: 1500,
    },
  ]);
};

export const FooterEndMessage = ({ navigate }) => {
  const finishRegistration = () => {
    AsyncStorage.removeItem("customer");
    navigate(LOGIN, { cleanCustomer: 1 });
  };

  return (
    <ButtonBase
      onPress={() => finishRegistration()}
      title="FINALIZAR"
      color="outline-white"
    />
  );
};
