import styled from "styled-components/native";

export const LoginContainer = styled.View`
  padding: 15px 25px;
`;

export const LogoContainer = styled.View`
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  align-items: center;
`;

export const SwitchContainer = styled.View`
  flex-direction: row;
`;

export const SwitchBox = styled.View`
  flex: 0 1 auto;
`;

export const AccountListContainer = styled.TouchableOpacity`
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #dddddd;
  margin-bottom: 5px;
`;

export const AccountListImage = styled.View`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const AccountListContent = styled.View`
  flex: 1;
`;

export const LoginDesktopContainer = styled.View`
  flex: 1;
  background-color: #ff671d;
`;

export const LoginImageLogo = styled.Image`
  width: 200px;
  height: 50px;
  resize-mode: contain;
  margin-left: 50px;
  margin-top: 50px;
`;

export const LoginRow = styled.View`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const LoginWhiteBox = styled.View`
  border-radius: 25px;
  background-color: #fff;
  padding: 75px;
  min-width: 600px;
`;
