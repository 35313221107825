import React from "react";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { WITHDRAW_BANK_INFO } from "../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import {
  ButtonIconsContainer,
  ButtonIconsRight,
  ButtonIconsContent,
} from "../../styles/buttons";
import { TemplatePage } from "../../components/templates";

const Withdraw = ({ navigation }) => {
  const { navigate } = navigation;

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 30 }}>Sacar</TitlePage>

      <ButtonIconsContainer
        style={{ marginBottom: 20 }}
        onPress={() => navigate(WITHDRAW_BANK_INFO)}
      >
        <ButtonIconsContent style={{ paddingLeft: 10 }}>
          <TextPage fontWeight="medium" big>
            Banco 24 horas
          </TextPage>
          <TextPage tiny>
            Utilize a rede Banco 24 horas para retirar dinheiro
          </TextPage>
        </ButtonIconsContent>
        <ButtonIconsRight>
          <AntDesign name="right" size={24} color="black" />
        </ButtonIconsRight>
      </ButtonIconsContainer>
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      paddingResetMobile
    />
  );
};

export default Withdraw;
