import styled from "styled-components/native";

export const HeaderBase = styled.View`
  padding-left: 10px;
  height: 40px;
  justify-content: center;
`;

export const HeaderBaseClose = styled.View`
  padding-left: 10px;
  height: 40px;
  justify-content: center;
  align-items: flex-end;
`;

export const ContainerBase = styled.ScrollView`
  padding: 15px;
  font-family: "Montserrat_400Regular";
  ${({ center }) => center && "justify-content:center; align-itens:center"};
  ${({ paddingReset }) => paddingReset && "padding: 0px"};
`;

export const FlatListBase = styled.FlatList`
  border-top-color: #eeeeee;
  border-top-width: 1px;
  font-family: "Montserrat_400Regular";
`;

export const ContainerPad = styled.View`
  padding: 15px;
  font-family: "Montserrat_400Regular";
`;

export const ContainerFull = styled.View`
  padding: 15px;
  font-family: "Montserrat_400Regular";
  flex: 1;
`;

export const ContainerBaseCenter = styled.View`
  padding: 15px;
  font-family: "Montserrat_400Regular";
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const FooterBase = styled.View`
  padding: 15px;
  height: 80px;
  font-family: "Montserrat_400Regular";
`;

export const PageContainer = styled.ScrollView`
  padding: 25px;
  font-family: "Montserrat_400Regular";
  font-size: 16px;
  flex: 1;
`;

export const PageContainerFullScreen = styled.View`
  padding: 25px;
  font-family: "Montserrat_400Regular";
  font-size: 16px;
  flex: 1;
`;

export const PageContainerMobile = styled.ScrollView`
  padding: 20px 10px;
  font-family: "Montserrat_400Regular";
  font-size: 14px;
  ${({ paddingResetMobile }) => paddingResetMobile && "padding:0px"};
`;

export const PageContainerMobileFullScreen = styled.View`
  flex: 1;
  padding: 20px 10px;
  font-family: "Montserrat_400Regular";
  font-size: 14px;
  ${({ paddingResetMobile }) => paddingResetMobile && "padding:0px"};
`;

export const GreyContainer = styled.View`
  padding: 20px 15px;
  background-color: #eeeeee;
`;

export const PlaceholderCenter = styled.View`
  width: 100px;
  align-self: center;
  margin: 0;
  ${({ width }) => width && "width:" + width};
`;

export const SideMenuContainer = styled.View`
  width: 250px;
`;

export const FullScreenContainer = styled.View`
  flex: 1;
`;

export const DesktopBaseContainer = styled.View`
  flex: 1;
  flex-direction: row;
`;

export const FlatListContainer = styled.FlatList`
  padding: 40px;
  ${({ mobile }) => mobile && "padding: 15px;"};
`;

export const Divisor = styled.View`
  width: 100%;
  border-top-color: #dddddd;
  border-top-width: 1px;
`;

export const ContainerLoading = styled.View`
  width: 100%;
  padding-top: 25px;
`;

export const BalanceBox = styled.View`
  border-width: 1px;
  border-color: #dddddd;
  padding: 20px;
  border-radius: 10px;
  min-width: 250px;
  min-height: 100px;
  align-self: ${({ mobile }) => (mobile ? "auto" : "flex-start")};
`;

export const BalanceLine = styled.View`
  flex-direction: row;
  min-height: 40px;
`;

export const BalanceValue = styled.Text`
  color: #ff671d;
  font-size: 30px;
  font-family: "Montserrat_500Medium";
  min-width: 160px;
`;

export const HistoryItem = styled.View`
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const HistoryIconContainer = styled.View`
  width: 60px;
  justify-content: center;
`;

export const HistoryIcon = styled.View`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border-width: 1px;
  border-color: #dddddd;
  justify-content: center;
  align-items: center;
`;

export const HistoryContent = styled.View`
  flex: 1;
`;

export const HistoryExtra = styled.View`
  width: 60px;
  justify-content: center;
`;

export const ScrollContainer = styled.View`
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
`;

export const BaseRow = styled.View`
  flex-direction: row;
`;

export const BaseColumn = styled.View`
  flex: 1;
`;

export const BaseColumnFlexAuto = styled.View`
  flex: 0 1 auto;
`;

export const BaseColumnAuto = styled.View`
  align-self: auto;
`;

export const ContainerBorder = styled.View`
  margin-bottom: 15px;
  padding: 15px;
  border-bottom-color: #dddddd;
  border-bottom-width: 1px;
`;

export const ContainerBorderless = styled.View`
  margin-bottom: 15px;
  padding: 15px;
`;

export const ProfileContainerBorder = styled.TouchableOpacity`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
  border-color: #dddddd;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px 5px;
  margin-bottom: 5px;
`;

export const ProfileContainer = styled.View`
  flex-direction: row;
  background-color: #fff;
  font-family: "Montserrat_400Regular";
`;

export const ProfileImage = styled.View`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContent = styled.View`
  flex: 1;
`;

export const ProfileImageButton = styled.TouchableOpacity`
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
`;

export const ProfileContentButton = styled.TouchableOpacity`
  flex: 1;
`;

export const ProfileButton = styled.TouchableOpacity`
  flex: 0 0 40px;
  justify-content: center;
  align-items: center;
`;

export const BalanceLastRefresh = styled.Text`
  font-size: 12px;
`;
