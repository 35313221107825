import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../screens/Dashboard";
import {
  DASHBOARD,
  LOGOUT,
  PAYMENT,
  PAYMENT_BARCODE_DATA,
  PAYMENT_BARCODE_MANUAL,
  PAYMENT_BARCODE_SCANNER,
  PAYMENT_BARCODE_PHOTO_LIST,
  DEPOSIT,
  TRANSFER,
  HISTORY,
  TRANSFER_FITBANK,
  TRANSFER_FITBANK_DOCUMENT,
  TRANSFER_FITBANK_OPTIONS,
  TRANSFER_PIX,
  TRANSFER_PIX_KEY,
  TRANSFER_PIX_COPY_PASTE,
  TRANSFER_PIX_CONFIRM,
  TRANSFER_PIX_QR,
  TRANSFER_ANOTHERBANK,
  TRANSFER_ACCOUNTS,
  TRANSFER_MY_ACCOUNTS,
  TRANSACTION_FORGOT_PASSWORD,
  WITHDRAW,
  WITHDRAW_BANK_INFO,
  WITHDRAW_BANK_LIST,
  DEPOSIT_BANK_SLIP,
  DEPOSIT_BANK_SLIP_SUCCESS,
  DEPOSIT_TED,
  NOTIFICATION,
  TRANSACTION_SUCCESS,
  CARDS,
  CARDS_NEW,
  CARDS_NEW_CONFIRM,
  CARDS_NEW_SUCCESS,
  CARDS_DETAILS,
  CARDS_FACE,
  CARDS_SUCCESS,
  CARDS_PASSWORD,
  CARDS_PASSWORD_SUCCESS,
} from "../constants/routes";
import Payment from "../screens/Payment";
import Logout from "../screens/Logout";
import BarcodeScanner from "../screens/Payment/barcodeScanner";
import barcodeManual from "../screens/Payment/barcodeManual";
import barcodeData from "../screens/Payment/barcodeData";
import barcodePhotoList from "../screens/Payment/barcodePhotoList";
import Deposit from "../screens/Deposit";
import DepositBankSlip from "../screens/Deposit/depositBankSlip";
import DepositBankSlipSuccess from "../screens/Deposit/depositBankSlipSuccess";
import DepositTed from "../screens/Deposit/depositTed";
import Transfer from "../screens/Transfer";
import TransferFitBank from "../screens/Transfer/transferFitBank";
import TransferFitBankDocument from "../screens/Transfer/transferFitBankDocument";
import TransferAnotherBank from "../screens/Transfer/transferAnotherBank";
import TransferPix from "../screens/Transfer/transferPix";
import TransferPixKey from "../screens/Transfer/transferPixKey";
import TransferPixCopyPaste from "../screens/Transfer/transferPixCopyPaste";
import TransferPixQr from "../screens/Transfer/transferPixQr";
import TransferPixConfirm from "../screens/Transfer/transferPixConfirm";
import TransferFitBankOptions from "../screens/Transfer/transferFitBankOptions";
import History from "../screens/History";
import TransactionForgotPassword from "../screens/Transaction/transactionForgotPassword";
import TransferAccounts from "../screens/Transfer/transferAccounts";
import TransferMyAccounts from "../screens/Transfer/transferMyAccounts";
import Withdraw from "../screens/WithDraw";
import WithDrawBankInfo from "../screens/WithDraw/withDrawBankInfo";
import WithDrawBankList from "../screens/WithDraw/withDrawBankList";
import Notification from "../screens/Notification";
import TransactionSuccess from "../screens/Transaction/transactionSuccess";
import Cards from "../screens/Cards";
import NewCard from "../screens/Cards/NewCard";
import NewCardConfirm from "../screens/Cards/NewCardConfirm";
import NewCardSuccess from "../screens/Cards/NewCardSuccess";
import CardDetails from "../screens/Cards/CardDetails";
import CardFace from "../screens/Cards/CardFace";
import CardSuccess from "../screens/Cards/CardSuccess";
import CardPassword from "../screens/Cards/CardPassword";
import CardPasswordSuccess from "../screens/Cards/CardPasswordSuccess";

const HomeNavigator = () => {
  const HomeStack = createStackNavigator();
  return (
    <HomeStack.Navigator
      initialRouteName={DASHBOARD}
      screenOptions={{ headerShown: false }}
    >
      <HomeStack.Screen name={DASHBOARD} component={Dashboard} />
      <HomeStack.Screen name={LOGOUT} component={Logout} />
      <HomeStack.Screen name={PAYMENT} component={Payment} />

      <HomeStack.Screen
        name={PAYMENT_BARCODE_SCANNER}
        component={BarcodeScanner}
      />
      <HomeStack.Screen
        name={PAYMENT_BARCODE_MANUAL}
        component={barcodeManual}
      />
      <HomeStack.Screen name={PAYMENT_BARCODE_DATA} component={barcodeData} />

      <HomeStack.Screen
        name={PAYMENT_BARCODE_PHOTO_LIST}
        component={barcodePhotoList}
      />
      <HomeStack.Screen name={DEPOSIT} component={Deposit} />
      <HomeStack.Screen name={DEPOSIT_BANK_SLIP} component={DepositBankSlip} />
      <HomeStack.Screen
        name={DEPOSIT_BANK_SLIP_SUCCESS}
        component={DepositBankSlipSuccess}
      />
      <HomeStack.Screen name={DEPOSIT_TED} component={DepositTed} />
      <HomeStack.Screen name={TRANSFER} component={Transfer} />
      <HomeStack.Screen
        name={TRANSFER_ANOTHERBANK}
        component={TransferAnotherBank}
      />
      <HomeStack.Screen
        name={TRANSFER_FITBANK_OPTIONS}
        component={TransferFitBankOptions}
      />
      <HomeStack.Screen name={TRANSFER_FITBANK} component={TransferFitBank} />
      <HomeStack.Screen
        name={TRANSFER_FITBANK_DOCUMENT}
        component={TransferFitBankDocument}
      />
      <HomeStack.Screen name={TRANSFER_PIX} component={TransferPix} />
      <HomeStack.Screen name={TRANSFER_PIX_KEY} component={TransferPixKey} />
      <HomeStack.Screen
        name={TRANSFER_PIX_COPY_PASTE}
        component={TransferPixCopyPaste}
      />
      <HomeStack.Screen name={TRANSFER_PIX_QR} component={TransferPixQr} />
      <HomeStack.Screen
        name={TRANSFER_PIX_CONFIRM}
        component={TransferPixConfirm}
      />
      <HomeStack.Screen name={TRANSFER_ACCOUNTS} component={TransferAccounts} />
      <HomeStack.Screen
        name={TRANSFER_MY_ACCOUNTS}
        component={TransferMyAccounts}
      />
      <HomeStack.Screen name={HISTORY} component={History} />
      <HomeStack.Screen name={WITHDRAW} component={Withdraw} />
      <HomeStack.Screen
        name={WITHDRAW_BANK_INFO}
        component={WithDrawBankInfo}
      />
      <HomeStack.Screen
        name={WITHDRAW_BANK_LIST}
        component={WithDrawBankList}
      />
      <HomeStack.Screen name={NOTIFICATION} component={Notification} />
      <HomeStack.Screen
        name={TRANSACTION_SUCCESS}
        component={TransactionSuccess}
      />
      <HomeStack.Screen
        name={TRANSACTION_FORGOT_PASSWORD}
        component={TransactionForgotPassword}
      />
      <HomeStack.Screen name={CARDS} component={Cards} />
      <HomeStack.Screen name={CARDS_NEW} component={NewCard} />
      <HomeStack.Screen name={CARDS_NEW_CONFIRM} component={NewCardConfirm} />
      <HomeStack.Screen name={CARDS_NEW_SUCCESS} component={NewCardSuccess} />
      <HomeStack.Screen name={CARDS_DETAILS} component={CardDetails} />
      <HomeStack.Screen name={CARDS_FACE} component={CardFace} />
      <HomeStack.Screen name={CARDS_SUCCESS} component={CardSuccess} />
      <HomeStack.Screen name={CARDS_PASSWORD} component={CardPassword} />
      <HomeStack.Screen
        name={CARDS_PASSWORD_SUCCESS}
        component={CardPasswordSuccess}
      />
    </HomeStack.Navigator>
  );
};
export default HomeNavigator;
