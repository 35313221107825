import React, { useState, useContext, useEffect } from "react";

import { ModalBase } from "../../components/modals";
import { ButtonBase } from "../../components/buttons";
import { TextInputBase } from "../../components/inputs";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import { GlobalContext } from "../../contexts/Provider";
import loginUser from "../../actions/loginUser";
import { useToast } from "native-base";

import { FORGOT_PASSWORD } from "../../constants/routes";

const PasswordModal = ({ modal, setModal, username }) => {
  const { navigate } = useNavigation();
  const [submit, setSubmit] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const toast = useToast();
  const {
    authDispatch,
    authState: { error, loading },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (error?.error) {
      toast.show({
        description: error?.error,
        placement: "top",
      });
    }
  }, [error]);

  const handleLogin = () => {
    loginUser({ username: username, password: password }, authDispatch);
  };

  return (
    <ModalBase
      header="INSIRA A SENHA DE ACESSO"
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <TextInputBase
        label={"Digite a senha"}
        onChangeText={setPassword}
        buttonIcon={
          passwordVisible ? (
            <Ionicons name="eye" size={24} color="black" />
          ) : (
            <Ionicons name="eye-off" size={24} color="black" />
          )
        }
        buttonOnPress={() => setPasswordVisible(!passwordVisible)}
        secureTextEntry={!passwordVisible}
        value={password}
      />

      <ButtonBase
        textLeft
        style={{ marginBottom: 15 }}
        onPress={() => {
          setModal(false);
          navigate(FORGOT_PASSWORD);
        }}
        title="Esqueci a senha"
        color="link"
      />

      <ButtonBase
        loading={loading}
        disabled={submit}
        onPress={() => handleLogin()}
        title="ENTRAR"
        color="orange"
      />
    </ModalBase>
  );
};

export default PasswordModal;
