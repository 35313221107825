import React, { useState } from "react";

import { ModalBase } from "../../components/modals";
import { ButtonBase } from "../../components/buttons";
import { Radio } from "native-base";
import { REGISTER_INSTRUCTIONS } from "../../constants/routes";

const DocumentType = ({
  modal,
  setModal,
  value,
  setValue,
  updateCustomer,
  navigate,
}) => {
  return (
    <ModalBase
      header={"ABRIR CONTA"}
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <Radio.Group
        name="myRadioGroup"
        accessibilityLabel="document type"
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
        }}
      >
        <Radio value="rg" my={1}>
          RG
        </Radio>
        <Radio value="cnh" my={1}>
          CNH
        </Radio>
      </Radio.Group>
      <ButtonBase
        style={{ marginTop: 25 }}
        onPress={() => {
          setModal(false);
          updateCustomer(
            { document_type: value },
            [{ text: value == "rg" ? "RG" : "CNH", type: "client", fade: 500 }],
            false,
            true
          );

          return navigate(REGISTER_INSTRUCTIONS);
        }}
        title="CONFIRMAR"
        color="orange"
      />
    </ModalBase>
  );
};

export default DocumentType;
