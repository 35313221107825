import React from "react";
import { View } from "react-native";
import {
  Placeholder,
  PlaceholderLine,
  Fade,
  PlaceholderMedia,
} from "rn-placeholder";

export const getIcon = (type) => {
  switch (type) {
    case 5:
      return require("../../assets/images/icon-deposit-black.png");
    case 10:
      return require("../../assets/images/icon-payment-black.png");
    case 15:
      return require("../../assets/images/icon-pix-black.png");
    case 20:
      return require("../../assets/images/icon-pix-black.png");
    case 25:
      return require("../../assets/images/icon-transfer-black.png");
    case 30:
      return require("../../assets/images/icon-transfer-black.png");
    case 50:
      return require("../../assets/images/icon-card-black.png");
    case 80:
      return require("../../assets/images/icon-card-black.png");
    default:
      return require("../../assets/images/icon.png");
  }
};

export const PlaceholderHistory = () => (
  <>
    <View style={{ marginBottom: 10, marginTop: 10 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 10 }}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
    <View style={{ marginBottom: 5 }}>
      <Placeholder Animation={Fade} Left={PlaceholderMedia}>
        <PlaceholderLine width={50} />
        <PlaceholderLine />
        <PlaceholderLine width={20} />
      </Placeholder>
    </View>
  </>
);
