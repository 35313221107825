import React, { useState } from "react";
import { Switch } from "native-base";
import {
  InputContainer,
  InputError,
  InputButton,
  InputBase,
  LabelBase,
  DateInputText,
  DateInputBox,
  InputTooltip,
  InputTooltipButton,
  TooltipBox,
  TooltipText,
} from "../styles/inputs";
import { Picker } from "@react-native-picker/picker";
import { SwitchBox, SwitchContainer } from "../screens/Login/styles";
import { Platform, View } from "react-native";
import { TextPage } from "../styles/typograph";
import DateTimePicker from "@react-native-community/datetimepicker";
import { FontAwesome } from "@expo/vector-icons";

export const TextInputBase = ({
  name,
  buttonOnPress,
  bigText,
  buttonIcon,
  placeholder,
  onChangeText,
  onKeyPress,
  onChange,
  onBlur,
  onFocus,
  value,
  secureTextEntry,
  keyboardType,
  label,
  errors,
  touched,
  borderFull,
  loading,
  multiline,
  numberOfLines,
  editable,
  style,
  maxLength,
  onPressIn,
  toolTip,
  toolTipModal,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const changeToolTip = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2500);
    }
  };

  return (
    <InputContainer>
      <LabelBase style={{ fontFamily: "Montserrat_400Regular" }}>
        {label}
      </LabelBase>
      {loading ? (
        <InputBase placeholder="Carregando..." />
      ) : (
        <InputBase
          maxLength={maxLength}
          style={style}
          multiline={multiline}
          numberOfLines={numberOfLines}
          name={name}
          borderFull={borderFull}
          placeholder={placeholder}
          onChangeText={onChangeText}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onFocus={onFocus}
          onPressIn={onPressIn}
          value={value || ""}
          secureTextEntry={secureTextEntry}
          keyboardType={keyboardType}
          bigText={bigText}
          editable={editable}
        />
      )}

      {toolTipModal && (
        <InputTooltip>
          <InputTooltipButton onPress={() => toolTipModal(true)}>
            <FontAwesome name="question-circle-o" size={12} color="black" />
          </InputTooltipButton>
        </InputTooltip>
      )}

      {toolTip && (
        <InputTooltip>
          {showTooltip && (
            <TooltipBox>
              <TooltipText>{toolTip}</TooltipText>
            </TooltipBox>
          )}
          <InputTooltipButton onPress={() => changeToolTip()}>
            <FontAwesome name="question-circle-o" size={12} color="black" />
          </InputTooltipButton>
        </InputTooltip>
      )}

      {buttonIcon && (
        <InputButton onPress={buttonOnPress}>{buttonIcon}</InputButton>
      )}
      {errors && touched && <InputError>{errors}</InputError>}
    </InputContainer>
  );
};

export const SwitchBase = ({ value, setValue, label, style }) => {
  return (
    <SwitchContainer style={style}>
      <View style={{ flex: 1, paddingTop: 12.5 }}>
        <TextPage>{label}</TextPage>
      </View>
      <SwitchBox style={{ alignSelf: "flex-end" }}>
        <Switch
          isChecked={value}
          onTrackColor={"#FF905A"}
          onThumbColor={"#FF671D"}
          onToggle={() => setValue(!value)}
        />
      </SwitchBox>
    </SwitchContainer>
  );
};

export const DateInput = ({
  valueFormated,
  value,
  setValue,
  label,
  errors,
  touched,
  minimumDate,
}) => {
  const [showDate, setShowDate] = React.useState(false);

  const onChangeDate = (event, selectedDate) => {
    const currentDate = selectedDate || value;
    setShowDate(Platform.OS === "ios");
    setValue(currentDate);
  };

  return (
    <>
      <InputContainer>
        <LabelBase
          style={{ fontFamily: "Montserrat_400Regular", marginBottom: 5 }}
        >
          {label}
        </LabelBase>
        <DateInputBox onPress={() => setShowDate(true)}>
          <DateInputText>{valueFormated}</DateInputText>
        </DateInputBox>
        {errors && touched && <InputError>{errors}</InputError>}
      </InputContainer>
      {showDate && (
        <DateTimePicker
          minimumDate={minimumDate}
          value={value}
          mode="date"
          display="default"
          onChange={onChangeDate}
        />
      )}
    </>
  );
};

export const PickerInput = ({
  selectedValue,
  onValueChange,
  pickerItens,
  label,
  errors,
  touched,
}) => {
  return (
    <InputContainer>
      <View
        style={{
          borderBottomColor: "#333333",
          borderBottomWidth: 1,
          paddingBottom: 10,
        }}
      >
        <LabelBase
          style={{ fontFamily: "Montserrat_400Regular", marginBottom: 10 }}
        >
          {label}
        </LabelBase>
        <Picker
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          style={{ border: 0 }}
        >
          <Picker.Item disabled label="Selecione" value="" />
          {pickerItens.map((item, index) => {
            return (
              <Picker.Item
                key={index}
                disabled
                label={item.label}
                value={item.value}
              />
            );
          })}
        </Picker>
      </View>
      {errors && touched && <InputError>{errors}</InputError>}
    </InputContainer>
  );
};
