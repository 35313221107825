import React, { useState, useEffect, useContext } from "react";
import { Box, useToast, Image } from "native-base";
import { TextPage, TitleOrange, TitlePage } from "../../styles/typograph";
import { ContainerBase, FooterBase, HeaderBase } from "../../styles/default";
import {
  DASHBOARD,
  PAYMENT,
  TRANSFER,
  TRANSFER_ANOTHERBANK,
  TRANSFER_FITBANK,
} from "../../constants/routes";
import { TemplatePage } from "../../components/templates";
import {
  ButtonIconsContainer,
  ButtonIconsRight,
  ButtonIconsContent,
} from "../../styles/buttons";
import { AntDesign } from "@expo/vector-icons";
import { cpfCnpjMask } from "../../helpers/masks";

const TransferFitBankOptions = ({ navigation, route }) => {
  const { navigate } = navigation;
  const toast = useToast();

  useEffect(() => {
    if (!route.params?.cpf_cnpj) {
      navigate(TRANSFER);
    }
  }, [route.params?.cpf_cnpj]);

  const pageContent = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 5 }}>{route.params?.name}</TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          {cpfCnpjMask(route.params?.cpf_cnpj)}
        </TextPage>
        <ButtonIconsContainer
          onPress={() =>
            navigate(TRANSFER_FITBANK, {
              ...route.params,
            })
          }
          style={{ marginBottom: 20 }}
        >
          <ButtonIconsContent
            style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
          >
            <TitleOrange>Conta Time Pay</TitleOrange>
            <TextPage tiny>Tarifa zero a qualquer dia e horário.</TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
        <ButtonIconsContainer
          onPress={() => navigate(TRANSFER_ANOTHERBANK)}
          style={{ marginBottom: 20 }}
        >
          <ButtonIconsContent
            style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
          >
            <TextPage>Transferir para outros bancos</TextPage>
            <TextPage tiny>Somente dias úteis até 17h</TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
      </ContainerBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferFitBankOptions;
