import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, Image, useToast } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  FooterBase,
  HeaderBase,
  HistoryContent,
  HistoryExtra,
  HistoryIconContainer,
  HistoryItem,
} from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { DateInput, TextInputBase } from "../../components/inputs";
import {
  DASHBOARD,
  DEPOSIT_BANK_SLIP_SUCCESS,
  PAYMENT,
} from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import { moneyMask } from "../../helpers/masks";
import { formatDecimal, formatMoney } from "../../helpers/format";
import { TemplatePage } from "../../components/templates";
import { GlobalContext } from "../../contexts/Provider";
import { HistoryContainer } from "./styles";
import GenerateVoucherButton from "../../components/generateVoucherButton";
import { getIcon, PlaceholderHistory } from "./components";

const DepositBankSlip = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const toast = useToast();
  const { balance } = useContext(GlobalContext);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    setLoadingHistory(true);
    axiosInstance
      .post("transaction/get-bankslips")
      .then((res) => {
        setLoadingHistory(false);
        let historyData = res?.data.splice(0, 5);
        setHistoryList(historyData);
      })
      .catch((err) => {
        setLoadingHistory(false);
      });
  };

  const generateBankSlip = () => {
    setLoading(true);
    axiosInstance
      .post("transaction/generate-bankslip", {
        balance: balance,
        amount: formatDecimal(amount),
      })
      .then((res) => {
        setLoading(false);
        navigate(DEPOSIT_BANK_SLIP_SUCCESS, { data: res.data });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const HistoryComponent = ({ item, index }) => {
    return (
      <>
        <HistoryItem>
          <HistoryIconContainer>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={getIcon(item.transaction_type_id)}
            />
          </HistoryIconContainer>
          <HistoryContent>
            <TextPage fontWeight="medium">
              {item.transaction_type?.name}
            </TextPage>
            <TextPage tiny>{item.receiver ? item.receiver.name : "-"}</TextPage>
            <TextPage tiny>R${formatMoney(item.amount)}</TextPage>
          </HistoryContent>
          <HistoryExtra>
            <GenerateVoucherButton item={item} />
          </HistoryExtra>
        </HistoryItem>
      </>
    );
  };

  const pageContent = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 10 }}>
          Gere um boleto de depósito
        </TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          Digite um valor entre R$ 20,00 e R$ 9.990,00
        </TextPage>

        <TextInputBase
          bigText
          name="amount"
          onChangeText={(v) => {
            setAmount(moneyMask(v));
          }}
          keyboardType="numeric"
          value={amount}
        />
        <TextPage style={{ marginTop: 30 }}>
          Boleto de depósito gerados
        </TextPage>

        <HistoryContainer>
          {!loadingHistory ? (
            historyList.length == 0 ? (
              <TextPage center>Não foi encontrado nenhum boleto</TextPage>
            ) : (
              <>
                {historyList.map((item, index) => {
                  return (
                    <HistoryComponent key={index} item={item} index={index} />
                  );
                })}
              </>
            )
          ) : (
            <PlaceholderHistory />
          )}
        </HistoryContainer>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={amount == "" || amount.length < 5}
          loading={loading}
          onPress={() => generateBankSlip()}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      goBack={true}
      fullScreen={true}
      paddingResetMobile
    />
  );
};

export default DepositBankSlip;
