import React, { useState, useEffect, useContext } from "react";
import { TextPage, TitleOrange, TitlePage } from "../../styles/typograph";
import { ContainerBase, FooterBase, HeaderBase } from "../../styles/default";
import {
  DASHBOARD,
  TRANSFER_PIX,
  TRANSFER_PIX_CONFIRM,
} from "../../constants/routes";
import { TemplatePage } from "../../components/templates";
import { TextInputBase } from "../../components/inputs";
import { ButtonBase } from "../../components/buttons";

const TransferPixCopyPaste = ({ navigation, route }) => {
  const { navigate } = navigation;
  const [code, setCode] = useState("");

  const pageContent = (
    <>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 10 }}>Enviar Pix</TitlePage>
        <TextPage style={{ marginBottom: 30 }}>Cole o código gerado</TextPage>

        <TextInputBase
          name={"code"}
          label="Cole o código"
          onChangeText={(v) => {
            setCode(v);
          }}
          value={code}
          bigText
        />
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => navigate(TRANSFER_PIX_CONFIRM)}
          disabled={code == "" || code == undefined}
          title="CONTINUAR"
          color="orange"
        />
      </FooterBase>
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={TRANSFER_PIX}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferPixCopyPaste;
