import React from "react";
import { Box } from "native-base";
import { useNavigation } from "@react-navigation/core";
import { ContainerBaseCenter, FooterBase } from "../../../styles/default";
import { SuccessContainer } from "./styles";
import { TextPage, TitlePage } from "../../../styles/typograph";
import { Text, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { ButtonBase } from "../../../components/buttons";
import { CARDS, LOGIN } from "../../../constants/routes";

const CardPasswordSuccess = ({ navigation }) => {
  const { navigate } = useNavigation();

  return (
    <Box style={{ flex: 1, backgroundColor: "#FF671D" }} safeArea>
      <ContainerBaseCenter>
        <SuccessContainer>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Feather name="check-circle" size={40} color="white" />
          </View>
          <TitlePage
            style={{ marginBottom: 10, marginTop: 15 }}
            color="white"
            center
          >
            Senha criada com sucesso
          </TitlePage>
          <TextPage color="white" center style={{ marginBottom: 10 }}>
            Seu cartão já está pronto para seu usado.
          </TextPage>
        </SuccessContainer>
      </ContainerBaseCenter>
      <FooterBase>
        <ButtonBase
          color="outline-white"
          title="VISUALIZAR CARTÕES"
          onPress={() => navigate(CARDS)}
        />
      </FooterBase>
    </Box>
  );
};

export default CardPasswordSuccess;
