import styled from "styled-components/native";

export const CardContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardBox = styled.View`
  width: 300px;
  height: 170px;
  background: #ff671d;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const ButtonBox = styled.View`
  display: flex;
  padding: 0px 40px;
  margin-bottom: 15px;
`;
