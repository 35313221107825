import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import base64 from "base-64";
import { navigate } from "../navigators/SideMenu/RootNavigator";

import { API_BASE, CLIENT_ID, CLIENT_SECRET } from "@env";
import { LOGIN, LOGOUT } from "../constants/routes";

let headers = {};
let failedRequestQueue = [];
let isRefreshing = false;
let attempts = 0;

const axiosInstance = axios.create({
  baseURL: `${API_BASE}`,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(await AsyncStorage.getItem("user"));
    const token = user?.access_token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response.data);
    }),
  (error) => {
    const originalConfig = error.config;

    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    if (error.response.status === 401) {
      attempts >= 3 && navigate(LOGOUT);
      !isRefreshing && getRefreshToken();

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token) => {
            originalConfig.headers["Authorization"] = `Bearer ${token}`;
            resolve(axiosInstance(originalConfig));
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });
    }

    return new Promise((resolve, reject) => {
      reject(error.response.data);
    });
  }
);

const getRefreshToken = async () => {
  isRefreshing = true;
  attempts++;
  const user = JSON.parse(await AsyncStorage.getItem("user"));
  const refresh_token = user?.refresh_token;
  const username = user?.user?.cpf_cnpj;

  if (user) {
    axios
      .post(API_BASE + "/user/refresh-token", {
        client_id: `${CLIENT_ID}`,
        client_secret: `${CLIENT_SECRET}`,
        username: username,
        refresh_token: refresh_token,
      })
      .then((res) => {
        let newToken = res.data?.access_token;
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${newToken}`;
        AsyncStorage.setItem("user", JSON.stringify(res.data));

        failedRequestQueue.forEach((request) => request.onSuccess(newToken));
        failedRequestQueue = [];
      })
      .catch((err) => {
        failedRequestQueue.forEach((request) => request.onFailure(err));
        failedRequestQueue = [];

        navigate(LOGOUT);
      })
      .finally(() => {
        isRefreshing = false;
      });
  } else {
    navigate(LOGOUT);
  }
};

export default axiosInstance;
