import styled from "styled-components/native";

export const RegisterType = styled.View`
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
`;

export const ContainerFee = styled.View`
  border-top-color: #eeeeee;
  border-top-width: 1px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;
