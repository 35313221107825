import React, { useEffect, useContext } from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { NavigationContainer } from "@react-navigation/native";
import AuthNavigator from "./AuthNavigator";
import DrawerNavigator from "./DrawerNavigator";
import { GlobalContext } from "../contexts/Provider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { navigationRef } from "./SideMenu/RootNavigator";
import { LOGIN_SUCCESS } from "../constants/actionTypes";
import getSettings from "../actions/getSettings";
import getBalance from "../actions/getBalance";
import getBanks from "../actions/getBanks";
import getNotifications from "../actions/getNotifications";
import axiosInstance from "../helpers/axiosInstance";

const AppNavContainer = () => {
  const {
    authDispatch,
    setBalance,
    setBankList,
    setAccountTypeList,
    settings,
    setSettings,
    setCountNotification,
    setTransactionTypes,
    authState: { isLoggedIn },
  } = useContext(GlobalContext);

  useEffect(() => {
    getUser();
    if (isLoggedIn) {
      getSettings(setSettings, setAccountTypeList, setTransactionTypes);
      getBalance(setBalance);
      getBanks(setBankList);
      getNotifications(setCountNotification);
      if (Platform.OS == "android" || Platform.OS == "ios") {
        registerForPushNotificationsAsync().then((token) =>
          subscribePush(token)
        );
      }
    }
  }, [isLoggedIn]);

  const getUser = async () => {
    try {
      const user = JSON.parse(await AsyncStorage.getItem("user"));
      if (user) {
        authDispatch({
          type: LOGIN_SUCCESS,
          payload: user,
        });
      } else {
        authDispatch({
          type: CLEAR_AUTH_STATE,
        });
      }
    } catch (error) {}
  };

  const registerForPushNotificationsAsync = async () => {
    let token;
    if (Constants.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  };

  const subscribePush = (token) => {
    axiosInstance
      .post("exponent/devices/subscribe", {
        expo_token: token,
      })
      .then((res) => {
        console.log("TOKEN PUSH", res);
      })
      .catch((err) => {
        console.log("TOKEN PUSH", err);
      });
  };

  return (
    <>
      <NavigationContainer ref={navigationRef}>
        {isLoggedIn ? <DrawerNavigator /> : <AuthNavigator />}
      </NavigationContainer>
    </>
  );
};

export default AppNavContainer;
