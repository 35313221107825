import React, { useRef, useEffect } from "react";
import { Image, View, Text, useWindowDimensions } from "react-native";
import { ScrollView, Box } from "native-base";
import { ButtonBase, ButtonBack } from "../../../components/buttons";
import { Carousel } from "../../../components/carousel";
import { useNavigation } from "@react-navigation/core";
import { REGISTER_PHOTO_FACE } from "../../../constants/routes";
import { HeaderBase } from "../../../styles/default";

const data = [
  <View style={{ paddingTop: 0, paddingBottom: 50 }}>
    <Image
      style={{
        width: 200,
        height: 200,
        resizeMode: "contain",
        alignSelf: "center",
      }}
      source={require("../../../assets/images/photo-selfie1.jpg")}
    />
    <Text style={{ textAlign: "center", marginTop: 25 }}>
      Não use bonés, óculos ou outros acessórios que cubram o rosto.
    </Text>
  </View>,
  <View style={{ paddingTop: 0, paddingBottom: 50 }}>
    <Image
      style={{
        width: 200,
        height: 200,
        resizeMode: "contain",
        alignSelf: "center",
      }}
      source={require("../../../assets/images/photo-selfie2.jpg")}
    />
    <Text style={{ textAlign: "center", marginTop: 25 }}>
      Escolha um lugar bem iluminado, de preferência com fundo neutro.
    </Text>
  </View>,
];

const RegisterInstructionsFace = ({ navigation, route }) => {
  const { width } = useWindowDimensions();
  const { navigate } = useNavigation();
  const customer = useRef();

  useEffect(() => {
    if (route.params?.customer) {
      customer.current = route.params?.customer;
    } else {
      navigate(REGISTER);
    }
  }, [route.params?.customer]);

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ScrollView style={{ padding: 15, paddingTop: 50 }}>
        <Carousel data={data} containerSize={width - 30} itensView={1} />
      </ScrollView>
      <View style={{ padding: 15 }}>
        <ButtonBase
          onPress={() =>
            navigate(REGISTER_PHOTO_FACE, { customer: customer.current })
          }
          title="CONTINUAR"
          color="outline-black"
        />
      </View>
    </Box>
  );
};

export default RegisterInstructionsFace;
