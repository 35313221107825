import React, { useContext, useState } from "react";
import { ScrollView } from "react-native";
import { ButtonBase, ButtonFilter } from "../../components/buttons";
import { ModalBase } from "../../components/modals";
import { DateInput } from "../../components/inputs";
import { GlobalContext } from "../../contexts/Provider";
import { BaseColumn, BaseRow, ScrollContainer } from "../../styles/default";
import { TextPage } from "../../styles/typograph";
import { formatDateInput } from "../../helpers/format";
import { getIcon } from "./components";
import { Image } from "native-base";

const Filter = ({ modal, setModal, setFilter }) => {
  const [selectType, setSelectType] = useState(0);
  const [dateFrom, setDateFrom] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    )
  );
  const [dateTo, setDateTo] = useState(new Date());
  const { transactionTypes } = useContext(GlobalContext);

  const applyFilters = () => {
    let filterData = {};

    if (selectType != 0) filterData.transaction_type_id = selectType;
    filterData.date_from = formatDateInput(dateFrom);
    filterData.date_to = formatDateInput(dateTo);
    setFilter(filterData);
    setModal(false);
  };

  const cleanFilters = () => {
    setFilter({});
    setModal(false);
  };

  return (
    <ModalBase
      header="TRANSAÇÃO"
      modal={modal}
      setModal={setModal}
      type="bottom"
    >
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <ScrollContainer>
          <ButtonFilter
            onPress={() => setSelectType(0)}
            selected={selectType == 0 && "1"}
          >
            <BaseRow>
              <TextPage style={{ alignSelf: "center", marginLeft: 5 }}>
                Todos
              </TextPage>
            </BaseRow>
          </ButtonFilter>
        </ScrollContainer>
        {transactionTypes &&
          transactionTypes.map((item, index) => {
            return (
              <ScrollContainer key={index}>
                <ButtonFilter
                  onPress={() => setSelectType(item.id)}
                  selected={selectType == item.id && "1"}
                >
                  <BaseRow>
                    <Image
                      resizeMode="contain"
                      alt="Icon"
                      size={5}
                      source={getIcon(item.id)}
                    />
                    <TextPage style={{ alignSelf: "center", marginLeft: 5 }}>
                      {item.name}
                    </TextPage>
                  </BaseRow>
                </ButtonFilter>
              </ScrollContainer>
            );
          })}
      </ScrollView>
      <BaseRow>
        <BaseColumn style={{ marginRight: 5 }}>
          <DateInput
            label="Data de"
            valueFormated={
              dateFrom.getDate() +
              "/" +
              (dateFrom.getMonth() + 1) +
              "/" +
              dateFrom.getFullYear()
            }
            value={dateFrom}
            setValue={setDateFrom}
          />
        </BaseColumn>
        <BaseColumn style={{ marginLeft: 5 }}>
          <DateInput
            label="Data até"
            valueFormated={
              dateTo.getDate() +
              "/" +
              (dateTo.getMonth() + 1) +
              "/" +
              dateTo.getFullYear()
            }
            value={dateTo}
            setValue={setDateTo}
          />
        </BaseColumn>
      </BaseRow>
      <BaseRow>
        <BaseColumn style={{ marginRight: 5 }}>
          <ButtonBase
            title="LIMPAR"
            color="outline-orange"
            onPress={() => cleanFilters()}
          />
        </BaseColumn>
        <BaseColumn style={{ marginLeft: 5 }}>
          <ButtonBase
            title="APLICAR"
            color="orange"
            onPress={() => applyFilters()}
          />
        </BaseColumn>
      </BaseRow>
    </ModalBase>
  );
};

export default Filter;
