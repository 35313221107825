import React, { useEffect, useState } from "react";
import { Image } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase } from "../../styles/default";
import { useNavigation } from "@react-navigation/core";
import {
  DASHBOARD,
  PAYMENT_BARCODE_MANUAL,
  PAYMENT_BARCODE_SCANNER,
} from "../../constants/routes";
import { AntDesign } from "@expo/vector-icons";
import {
  ButtonIconsContainer,
  ButtonIconsLeft,
  ButtonIconsRight,
  ButtonIconsContent,
} from "../../styles/buttons";
import { TemplatePage } from "../../components/templates";
import { Platform } from "react-native";

const Payment = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [checkMobile, setCheckMobile] = useState(false);

  useEffect(() => {
    if (Platform.OS == "android" || Platform.OS == "ios") setCheckMobile(true);
  }, []);

  const pageContent = (
    <ContainerBase>
      <TitlePage style={{ marginBottom: 30 }}>Efetue pagamentos</TitlePage>
      {checkMobile && (
        <ButtonIconsContainer
          style={{ marginBottom: 20 }}
          onPress={() => navigate(PAYMENT_BARCODE_SCANNER)}
        >
          <ButtonIconsLeft>
            <Image
              size={30}
              resizeMode="contain"
              source={require("../../assets/images/icon-payment.png")}
              alt="payment icon"
            />
          </ButtonIconsLeft>
          <ButtonIconsContent>
            <TextPage big>Ler código de barra</TextPage>
            <TextPage tiny>
              Basta apontar a câmera do seu celular para conta que deseja pagar.
            </TextPage>
          </ButtonIconsContent>
          <ButtonIconsRight>
            <AntDesign name="right" size={24} color="black" />
          </ButtonIconsRight>
        </ButtonIconsContainer>
      )}

      <ButtonIconsContainer
        style={{ marginBottom: 20 }}
        onPress={() => navigate(PAYMENT_BARCODE_MANUAL)}
      >
        <ButtonIconsLeft>
          <Image
            size={30}
            resizeMode="contain"
            source={require("../../assets/images/icon-keyboard.png")}
            alt="payment icon"
          />
        </ButtonIconsLeft>
        <ButtonIconsContent>
          <TextPage big>Digitar código de barra</TextPage>
          <TextPage tiny>Se preferir, você pode digita o código.</TextPage>
        </ButtonIconsContent>
        <ButtonIconsRight>
          <AntDesign name="right" size={24} color="black" />
        </ButtonIconsRight>
      </ButtonIconsContainer>
    </ContainerBase>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
    />
  );
};

export default Payment;
