import React, { useState, useContext, useEffect } from "react";
import { ButtonBase } from "../../components/buttons";
import { TitlePage, TextPage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerPad,
  FooterBase,
  GreyContainer,
} from "../../styles/default";
import { TemplatePage } from "../../components/templates";
import {
  ContainerBorder,
  ContainerBorderless,
  ProfileContainer,
  ProfileContent,
} from "./styles";
import { TRANSFER_PIX } from "../../constants/routes";
import { GlobalContext } from "../../contexts/Provider";
import { formatMoney } from "../../helpers/format";
import { Flex, useToast, View } from "native-base";
import axiosInstance from "../../helpers/axiosInstance";
import { SwitchBase } from "../../components/inputs";
import TransactionPassword from "../../components/transactionPassword";

const TransferSuccess = ({ navigation, route }) => {
  const { navigate } = navigation;
  const {
    authState: { authData },
  } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [saveContact, setSaveContact] = useState(false);

  const pageContent = (
    <>
      <ContainerBase paddingReset={true}>
        <ContainerPad>
          <TitlePage>Enviar Pix</TitlePage>
        </ContainerPad>

        <ContainerBorder>
          <TextPage>Para</TextPage>
          <TextPage fontWeight="medium">João Victor Manuel</TextPage>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">{"R$" + "100,00"}</TextPage>
          <TextPage tiny>
            {"tarifa: " + authData?.user?.fee?.pix_in &&
              "R$" + authData?.user?.fee?.pix_in}
          </TextPage>
        </ContainerBorderless>
        <GreyContainer style={{ marginBottom: 15 }}>
          <TextPage>CPF/CNPJ</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            080.989.589-66
          </TextPage>

          <TextPage>Tipo de transferência</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            PIX
          </TextPage>

          <TextPage>Instituição</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            NU PAGAMENTOS - IP
          </TextPage>

          <TextPage>Agência</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            0001
          </TextPage>

          <TextPage>Conta</TextPage>
          <TextPage fontWeight="medium">242521-5</TextPage>
        </GreyContainer>
        <ContainerPad>
          <SwitchBase
            style={{ marginBottom: 10 }}
            label="Salvar contato"
            value={saveContact}
            setValue={(v) => setSaveContact(v)}
          />
        </ContainerPad>
      </ContainerBase>
      <FooterBase>
        <ButtonBase title="CONFIRMAR" color="orange" />
      </FooterBase>
      <TransactionPassword modal={showPassword} setModal={setShowPassword} />
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={TRANSFER_PIX}
      paddingResetMobile
      fullScreen={true}
    />
  );
};

export default TransferSuccess;
