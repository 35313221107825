import React from "react";
import { View } from "native-base";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";

export const PlaceholderBalanceHide = () => (
  <View style={{ marginTop: 10, minWidth: 160 }}>
    <Placeholder>
      <PlaceholderLine style={{ height: 17.5, width: 125 }} />
    </Placeholder>
  </View>
);

export const PlaceholderBalance = () => (
  <View style={{ marginTop: 10 }}>
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{ height: 17.5, width: 125 }} />
    </Placeholder>
  </View>
);
