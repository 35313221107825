import React, { useState, useEffect, useContext } from "react";
import { Text, View, ActivityIndicator } from "react-native";
import { Box } from "native-base";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { BarCodeScanner } from "expo-barcode-scanner";
import {
  BarCodeArea,
  BarCodeButton,
  BarCodeButtonText,
  BarCodeColumn,
  BarCodeQrRow,
  BarCodeScan,
  BarCodeScannerContainer,
  BarCodeScannerFooter,
  BarCodeScannerHeader,
  BarCodeScannerHeaderText,
} from "./styles";
import { TRANSFER_PIX_CONFIRM, TRANSFER_PIX } from "../../constants/routes";

const TranferPixQr = ({ navigation }) => {
  const { navigate } = useNavigation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [loading, setLoading] = useState(true);
  const [qrcode, setQrcode] = useState(true);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => initScanner());

    return () => {
      unsubscribe();
      setScanned(false);
    };
  }, []);

  const initScanner = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
    setLoading(false);
  };

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    setQrcode(data);
    setScanned(false);
    navigate(TRANSFER_PIX_CONFIRM);
  };

  const barcodeStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    borderWidth: 5,
    borderColor: "blue",
  };

  if (hasPermission === null) {
    return (
      <Text style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        Solicitando permissão de câmera
      </Text>
    );
  }
  if (hasPermission === false) {
    return (
      <Text style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        Sem acesso à câmera
      </Text>
    );
  }

  return loading ? (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="large" color="#000" />
    </View>
  ) : (
    <Box style={{ flex: 1, backgroundColor: "black" }} safeAreaBottom>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={barcodeStyle}
      />

      <BarCodeScannerContainer>
        <BarCodeScannerHeader>
          <ButtonBack
            onPress={() => navigate(TRANSFER_PIX)}
            color="white"
            size={24}
          />
          <BarCodeScannerHeaderText>
            Escaneie o QR Code
          </BarCodeScannerHeaderText>
        </BarCodeScannerHeader>
        <BarCodeArea>
          <BarCodeColumn />
          <BarCodeScan />
          <BarCodeColumn />
        </BarCodeArea>

        <BarCodeScannerFooter></BarCodeScannerFooter>
      </BarCodeScannerContainer>
    </Box>
  );
};

export default TranferPixQr;
