import React, { useState, useRef, useEffect, useContext } from "react";
import { Platform, View } from "react-native";
import { Image, useToast } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerBorder,
  ContainerBorderless,
  ContainerPad,
  FooterBase,
  GreyContainer,
  PlaceholderCenter,
  ProfileContainer,
  ProfileContent,
  ProfileImage,
} from "../../styles/default";
import { ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { DateInput, TextInputBase } from "../../components/inputs";
import { ColBarCodeData, RowBarCodeData } from "./styles";
import { PAYMENT, PAYMENT_BARCODE_PHOTO_LIST } from "../../constants/routes";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  ShineOverlay,
} from "rn-placeholder";
import {
  clearCaracters,
  formatMoney,
  formatDecimal,
  formatDateShow,
  formatDateInput,
  formatDateFitFormat,
} from "../../helpers/format";
import { moneyMask } from "../../helpers/masks";
import { GlobalContext } from "../../contexts/Provider";
import { TemplatePage } from "../../components/templates";
import TransactionValueConfirm from "../../components/transactionValueConfirm";
import TransactionPassword from "../../components/transactionPassword";

const barcodeData = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [miniumPaymentDate, setMiniumPaymentDate] = useState(new Date());
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [showInputValue, setShowInputValue] = useState(false);
  const [bankName, setBankName] = useState("");
  const [digitableLine, setDigitableLine] = useState("");
  const [barcode, setBarcode] = useState("");
  const [concessionaire, setConcessionaire] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingPayDate, setLoadingPayDate] = useState(true);
  const toast = useToast();
  const [checkMobile, setCheckMobile] = useState(false);
  const [showConfirmValue, setShowConfirmValue] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const {
    authState: { authData },
    paymentBarcode,
    paymentPhotos,
    balance,
    paymentDocument,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (paymentBarcode == undefined || paymentBarcode == "") {
      navigate(PAYMENT);
    } else {
      getData();
      getPayDate();
      if (Platform.OS == "android" || Platform.OS == "ios")
        setCheckMobile(true);
    }
  }, []);

  useEffect(() => {
    comparePaymentDate();
  }, [paymentDate]);

  const getData = () => {
    setLoading(true);
    axiosInstance
      .post("transaction/digital-line", {
        barcode: clearCaracters(paymentBarcode),
      })
      .then((res) => {
        let barcodeData = JSON.parse(res.data);
        barcodeData.Value
          ? setValue(barcodeData.Value)
          : setShowInputValue(true);
        barcodeData.BankName && setBankName(barcodeData.BankName);
        barcodeData.DigitableLine &&
          setDigitableLine(barcodeData.DigitableLine);
        barcodeData.Barcode && setBarcode(barcodeData.Barcode);
        barcodeData.ConcessionaireName
          ? setConcessionaire(barcodeData.ConcessionaireName)
          : setConcessionaire("-");
        barcodeData.DueDate && setDueDate(new Date(barcodeData.DueDate));
        setLoading(false);
      })
      .catch((err) => {
        toast.show({
          description: err.message,
          placement: "top",
        });
        setLoading(false);
        navigate(PAYMENT);
      });
  };

  const handleConfirmValue = () => {
    if (!!inputValue) {
      if (!scheduled && balance - formatDecimal(inputValue) < 0) {
        toast.show({
          description: "Saldo insuficiente",
          placement: "top",
        });
        return;
      }
      setValue(formatDecimal(inputValue));
    } else if (!scheduled && balance - value < 0) {
      toast.show({
        description: "Saldo insuficiente",
        placement: "top",
      });
      return;
    }

    setShowConfirmValue(true);
  };

  const sendPayment = (code) =>
    axiosInstance.post("transaction/pay-bankslip", {
      code: code,
      balance: balance,
      due_date: formatDateInput(dueDate),
      barcode: barcode,
      amount: value,
      discount: 0,
      extra: 0,
      photoa: paymentPhotos[0]?.base64,
      photob: paymentPhotos[1]?.base64,
      document: paymentDocument?.base64,
      document_extension: paymentDocument?.ext,
      payment_date: formatDateInput(paymentDate),
    });

  const getPayDate = () => {
    setLoadingPayDate(true);
    axiosInstance
      .get("transaction/pay-date")
      .then((res) => {
        setLoadingPayDate(false);
        let formatedDate = formatDateFitFormat(res.data);
        setMiniumPaymentDate(formatedDate);
        compareDate(formatedDate);
        setPaymentDate(formatedDate);
      })
      .catch((err) => {
        setLoadingPayDate(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const compareDate = (date) => {
    if (
      date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear() !=
      paymentDate.getDate() +
        "-" +
        paymentDate.getMonth() +
        "-" +
        paymentDate.getFullYear()
    ) {
      setScheduled(true);
      toast.show({
        description:
          "Horário limite atingido, o pagamento só poderá ser realizado no próximo dia útil ",
        placement: "top",
      });
    }
  };

  const comparePaymentDate = () => {
    let today = new Date();
    if (paymentDate > today) {
      setScheduled(true);
    }
  };

  const pageContentFields = (
    <>
      <ContainerBase paddingReset>
        <TextPage style={{ marginTop: 20 }} center>
          Você está pagando
        </TextPage>

        {value ? (
          <TitlePage center color="orange">
            R$ {formatMoney(value)}
          </TitlePage>
        ) : !!showInputValue ? (
          <TitlePage center color="orange">
            Digite o valor
          </TitlePage>
        ) : (
          <PlaceholderCenter width="80px">
            <Placeholder Animation={ShineOverlay}>
              <PlaceholderLine style={{ marginTop: 10 }} />
            </Placeholder>
          </PlaceholderCenter>
        )}

        <TextPage tiny center>
          Saldo disponível: R$ {balance && formatMoney(balance)}
        </TextPage>
        <GreyContainer style={{ marginTop: 30, paddingBottom: 10 }}>
          <RowBarCodeData>
            <ColBarCodeData>
              <TextPage TextPage="medium">Para</TextPage>
            </ColBarCodeData>
            <ColBarCodeData>
              {concessionaire ? (
                <TextPage>{concessionaire}</TextPage>
              ) : (
                <Placeholder Animation={Fade}>
                  <PlaceholderLine />
                </Placeholder>
              )}
            </ColBarCodeData>
          </RowBarCodeData>
          <RowBarCodeData>
            <ColBarCodeData>
              <TextPage TextPage="medium">Banco</TextPage>
            </ColBarCodeData>
            <ColBarCodeData>
              {bankName ? (
                <TextPage>{bankName}</TextPage>
              ) : (
                <Placeholder Animation={Fade}>
                  <PlaceholderLine />
                </Placeholder>
              )}
            </ColBarCodeData>
          </RowBarCodeData>
          <RowBarCodeData>
            <ColBarCodeData>
              <TextPage TextPage="medium">Código de barras</TextPage>
            </ColBarCodeData>
            <ColBarCodeData>
              {digitableLine ? (
                <TextPage>{digitableLine}</TextPage>
              ) : (
                <Placeholder Animation={Fade}>
                  <PlaceholderLine />
                </Placeholder>
              )}
            </ColBarCodeData>
          </RowBarCodeData>
        </GreyContainer>
        <View style={{ padding: 15, paddingTop: 25, paddingBottom: 25 }}>
          {showInputValue && (
            <TextInputBase
              keyboardType="numeric"
              label="Valor"
              name={"value"}
              onChangeText={(v) => {
                setInputValue(moneyMask(v));
              }}
              value={inputValue}
            />
          )}

          <DateInput
            label="Data de pagamento"
            minimumDate={miniumPaymentDate}
            valueFormated={
              paymentDate.getDate() +
              "/" +
              (paymentDate.getMonth() + 1) +
              "/" +
              paymentDate.getFullYear()
            }
            value={paymentDate}
            setValue={setPaymentDate}
          />

          <DateInput
            label="Data de vencimento"
            valueFormated={
              dueDate.getDate() +
              "/" +
              (dueDate.getMonth() + 1) +
              "/" +
              dueDate.getFullYear()
            }
            value={dueDate}
            setValue={setDueDate}
          />

          <TextInputBase
            name="description"
            label="Descrição"
            onChangeText={(v) => {
              setDescription(v);
            }}
            value={description}
          />
          {checkMobile && (
            <ButtonBase
              onPress={() => navigate(PAYMENT_BARCODE_PHOTO_LIST)}
              title="TIRE UMA FOTO DO BOLETO"
              color="outline-black"
              style={{ marginTop: 20 }}
            />
          )}
        </View>
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          disabled={paymentDate === "" || (!inputValue && !value)}
          loading={loading && loadingPayDate}
          title="CONTINUAR"
          color="orange"
          onPress={() => handleConfirmValue()}
        />
      </FooterBase>
      <TransactionValueConfirm
        modal={showConfirmValue}
        setModal={setShowConfirmValue}
        setConfirmValue={setConfirmValue}
        amount={value}
      />
    </>
  );

  const pageContentConfirm = (
    <>
      <ContainerBase paddingReset={true}>
        <ContainerPad>
          <TitlePage>Pagar Boleto</TitlePage>
        </ContainerPad>

        <ContainerBorder>
          <TextPage>De</TextPage>
          <ProfileContainer>
            <ProfileImage>
              <Image
                source={require("../../assets/images/default-photo.png")}
                size="50"
                borderRadius="100"
                alt="photo"
              />
            </ProfileImage>
            <ProfileContent>
              <TextPage fontWeight="medium">{authData?.user?.name}</TextPage>
              <TextPage>CPF: {authData?.user?.cpf_cnpj}</TextPage>
            </ProfileContent>
          </ProfileContainer>
        </ContainerBorder>
        <ContainerBorderless>
          <TextPage>Valor</TextPage>
          <TextPage fontWeight="medium">R$ {formatMoney(value)}</TextPage>
          <TextPage tiny>
            {authData?.user?.fee?.bank_slip_out &&
              "tarifa: R$" + authData?.user?.fee?.bank_slip_out}
          </TextPage>
        </ContainerBorderless>
        <GreyContainer style={{ marginBottom: 15 }}>
          <TextPage>Código de Barras</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            {barcode}
          </TextPage>
          <TextPage>Data de Pagamento</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            {formatDateShow(paymentDate)}
          </TextPage>
          <TextPage>Data de Vencimento</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            {formatDateShow(dueDate)}
          </TextPage>
          <TextPage>Tipo de transferência</TextPage>
          <TextPage style={{ marginBottom: 15 }} fontWeight="medium">
            Pagamento de Boleto
          </TextPage>
        </GreyContainer>
        {description != "" && (
          <ContainerPad>
            <TextPage>Descrição</TextPage>
            <TextPage fontWeight="medium">{description}</TextPage>
          </ContainerPad>
        )}
      </ContainerBase>
      <FooterBase>
        <ButtonBase
          onPress={() => setShowPassword(true)}
          title="CONFIRMAR"
          color="orange"
        />
      </FooterBase>
      <TransactionPassword
        modal={showPassword}
        setModal={setShowPassword}
        transaction={sendPayment}
        transactionData={{
          amount: value,
          description: description,
          barcode: barcode,
          due_date: formatDateShow(dueDate),
          transaction_type_id: 10,
          fee: authData?.user?.fee?.bank_slip_out,
          type: { id: 1, name: "Conta corrente" },
          transfer_type: "Boleto Saída",
          scheduled: scheduled,
        }}
      />
    </>
  );

  const pageContent = () => {
    if (confirmValue) return pageContentConfirm;

    return pageContentFields;
  };

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent()}
      backNavigate={PAYMENT}
      fullScreen={true}
      paddingResetMobile
    />
  );
};

export default barcodeData;
