import React, { useEffect, useState } from "react";
import { View } from "react-native";
import logoutUser from "../../actions/logoutUser";
import { Box, Image } from "native-base";
import { GlobalContext } from "../../contexts/Provider";
import { TextPage, TitleOrange } from "../../styles/typograph";
import { AntDesign } from "@expo/vector-icons";
import {
  SidebarContent,
  ProfileContainer,
  ProfileImage,
  ProfileContent,
  MenuItem,
  MenuText,
  MenuIcon,
  HeaderMenu,
} from "./styles";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import {
  CARDS,
  DEPOSIT,
  HISTORY,
  PAYMENT,
  TRANSFER,
  WITHDRAW,
} from "../../constants/routes";
import * as Linking from "expo-linking";

const SideMenu = ({ navigation, mobile }) => {
  const {
    authDispatch,
    authState: { authData, loading },
  } = React.useContext(GlobalContext);
  const { navigate } = navigation;

  const handleLogout = () => {
    logoutUser(authDispatch);
  };

  const menuItems = [
    {
      name: "Extrato",
      onPress: () => navigate(HISTORY),
    },
    {
      name: "Depositar",
      onPress: () => navigate(DEPOSIT),
    },
    {
      name: "PIX",
    },
    {
      name: "Transferir",
      onPress: () => navigate(TRANSFER),
    },
    {
      name: "Pagar",
      onPress: () => navigate(PAYMENT),
    },
    {
      name: "Cartão",
      onPress: () => navigate(CARDS),
    },
    {
      name: "Cobrar",
    },
    {
      name: "Recarregar",
    },
    {
      name: "Sacar",
      onPress: () => navigate(WITHDRAW),
    },
    {
      name: "Ajuda",
      onPress: () => Linking.openURL("https://centraldeajuda.timepay.com.br/"),
    },
    {
      name: "Perfil",
    },
  ];
  return (
    <Box style={{ flex: 1, backgroundColor: "#F9F9F9" }} safeArea>
      {mobile && (
        <HeaderMenu>
          <ButtonBack
            onPress={() => navigation.closeDrawer()}
            color="black"
            size={24}
          />
        </HeaderMenu>
      )}
      <SidebarContent>
        <ProfileContainer>
          <ProfileImage>
            <Image
              source={require("../../assets/images/default-photo.png")}
              size="50"
              borderRadius="100"
              alt="photo"
            />
          </ProfileImage>
          <ProfileContent>
            <TitleOrange>
              {authData?.user?.name.length > 15
                ? authData?.user?.name.substring(0, 15) + "..."
                : authData?.user?.name}
            </TitleOrange>
            <TextPage fontWeight="medium">Conta pessoa Física</TextPage>
            <TextPage>
              Cc.
              {" " +
                authData?.user?.account?.account +
                "-" +
                authData?.user?.account?.digit}
            </TextPage>
          </ProfileContent>
        </ProfileContainer>
        {menuItems.map(({ name, icon, onPress }) => (
          <MenuItem onPress={onPress} key={name}>
            <MenuText>{name}</MenuText>
            <MenuIcon>
              <AntDesign name="right" size={16} color="#FF671D" />
            </MenuIcon>
          </MenuItem>
        ))}
        <View style={{ marginTop: 15, marginBottom: 15 }}>
          <ButtonBase
            title="Sair do App"
            color="link-orange"
            onPress={handleLogout}
            loading={loading}
          />
        </View>
      </SidebarContent>
    </Box>
  );
};

export default SideMenu;
