import React from "react";
import { Modal } from "native-base";

export const ModalBase = ({
  header,
  children,
  type = "default",
  modal,
  setModal,
  maxWidth,
}) => {
  let styles;
  let size = "lg";

  if (type == "bottom") {
    size = "full";

    styles = {
      marginBottom: "0",
      marginTop: "auto",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      fontFamily: "Montserrat_400Regular",
    };
  }

  return (
    <>
      <Modal isOpen={modal} onClose={setModal} size={size}>
        <Modal.Content maxWidth={maxWidth} {...styles}>
          {header && (
            <>
              <Modal.CloseButton />
              <Modal.Header>{header}</Modal.Header>
            </>
          )}
          <Modal.Body style={{ paddingTop: 25, paddingBottom: 25 }}>
            {children}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};
