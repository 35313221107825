import styled from "styled-components/native";

export const DashboardHistoryList = styled.View``;

export const DashboardHistoryItem = styled.View`
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DashboardHistoryIconContainer = styled.View`
  width: 70px;
`;

export const DashboardHistoryIcon = styled.View`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border-width: 1px;
  border-color: #dddddd;
  justify-content: center;
  align-items: center;
`;

export const DashboardHistoryContent = styled.View`
  flex: 1;
`;

export const HistoryContainer = styled.View`
  margin-top: 25px;
`;

export const HistoryDayBox = styled.View`
  border-radius: 5px;
  border-width: 1px;
  border-color: #dddddd;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
`;

export const HistoryDay = styled.View``;

export const HistoryDayBalance = styled.View``;
