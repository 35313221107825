import React from "react";
import { Text, View } from "react-native";
import { ScrollView, Box, Button } from "native-base";
import { ButtonBase } from "../../components/buttons";
import { ButtonBack } from "../../components/buttons";
import { TitlePage } from "../../styles/typograph";

const RegisterTerm = ({ navigation }) => {
  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <View
        style={{
          paddingLeft: 10,
          height: 40,
          justifyContent: "center",
        }}
      >
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </View>
      <ScrollView style={{ padding: 15 }}>
        <TitlePage style={{ marginBottom: 15 }}>Termo de uso</TitlePage>
        <Text style={{ marginBottom: 10 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in
          lorem turpis. Vestibulum et nibh in ante tincidunt aliquet. Etiam
          volutpat ullamcorper arcu eget hendrerit. Maecenas vulputate convallis
          cursus. Duis id est at mi gravida blandit non a risus. Ut tincidunt
          mattis pretium. Morbi est neque, rhoncus vel venenatis in, porttitor
          sit amet erat. Pellentesque eget scelerisque ex, et vestibulum nibh.
          Phasellus tempus consectetur erat vel lobortis. Nam sit amet pulvinar
          eros, nec dapibus sem. Aenean ut vestibulum sapien. Nam fringilla,
          turpis a maximus auctor, est purus commodo libero, sit amet suscipit
          ex augue eu nisi. Sed vestibulum nisi a dolor fringilla, sed consequat
          nisl commodo. Nunc sapien lorem, iaculis at ante ac, scelerisque
          hendrerit ligula. Nunc pharetra neque id eros gravida hendrerit.
          Mauris nec ex semper odio viverra convallis. Morbi aliquet, risus id
          posuere venenatis, risus risus luctus justo, vel faucibus purus eros
          et magna. Aenean id diam egestas, viverra ex non, vestibulum leo.
          Proin velit elit, dignissim ac eros id, volutpat malesuada quam. Duis
          sed ex suscipit, posuere lorem at, varius metus. Ut id magna iaculis
          metus condimentum eleifend. Mauris vitae neque aliquam, consequat diam
          id, varius quam. Sed venenatis pharetra mi, sed sagittis eros pharetra
          ac. Nam eget magna sit amet dui
        </Text>
        <Text>
          Nam sit amet pulvinar eros, nec dapibus sem. Aenean ut vestibulum
          sapien. Nam fringilla, turpis a maximus auctor, est purus commodo
          libero, sit amet suscipit ex augue eu nisi. Sed vestibulum nisi a
          dolor fringilla, sed consequat nisl commodo. Nunc sapien lorem,
          iaculis at ante ac, scelerisque hendrerit ligula. Nunc pharetra neque
          id eros gravida hendrerit. Mauris nec ex semper odio viverra
          convallis. Morbi aliquet, risus id posuere venenatis, risus risus
          luctus justo, vel faucibus purus eros et magna. Aenean id diam
          egestas, viverra ex non, vestibulum leo. Proin velit elit, dignissim
          ac eros id, volutpat malesuada quam. Duis sed ex suscipit, posuere
          lorem at, varius metus. Ut id magna iaculis metus condimentum
          eleifend. Mauris vitae neque aliquam, consequat diam id, varius quam.
          Sed venenatis pharetra mi, sed sagittis eros pharetra ac. Nam eget
          magna sit amet
        </Text>
      </ScrollView>
      <View style={{ padding: 15 }}>
        <ButtonBase
          style={{ marginBottom: 10 }}
          title="BAIXAR TERMO"
          color="orange"
        />
        <ButtonBase
          onPress={navigation.goBack}
          title="FECHAR"
          color="outline-black"
        />
      </View>
    </Box>
  );
};

export default RegisterTerm;
