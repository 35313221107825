import React, { useEffect, useRef, useState } from "react";
import { Box } from "native-base";
import { TextPage, TitlePage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import { REGISTER } from "../../constants/routes";
import { TextInputBase } from "../../components/inputs";
import { View } from "react-native";
import { secondsToTime } from "../../helpers/format";
import { useToast } from "native-base";
import axiosInstance from "../../helpers/axiosInstance";
import AsyncStorage from "@react-native-async-storage/async-storage";

const RegisterPhoneConfirmation = ({ navigation, route }) => {
  const { navigate } = useNavigation();
  const [code, setCode] = useState("");
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(180);
  const toast = useToast();
  const customer = useRef();
  const phone = useRef();

  useEffect(() => {
    if (route.params?.phone) {
      phone.current = route.params?.phone;
      getCustomer();
    } else {
      navigate(REGISTER);
    }
  }, [route.params?.phone]);

  useEffect(() => {
    const timer =
      counter > 0
        ? setInterval(() => setCounter(counter - 1), 1000)
        : setResend(true);
    return () => clearInterval(timer);
  }, [counter]);

  const getCustomer = async () => {
    try {
      customer.current = JSON.parse(await AsyncStorage.getItem("customer"));
    } catch (error) {}
  };

  const sendSms = () => {
    setCounter(180);
    axiosInstance
      .post("customer/send-sms", {
        phone: phone.current,
      })
      .then((res) => {
        toast.show({
          title: "Sms reenviado",
          status: "success",
          placement: "top",
        });
      })
      .catch((err) => {
        toast.show({
          title: "Erro ao reenviar o sms",
          status: "error",
          placement: "top",
        });
      });
  };

  const validateSms = () => {
    setLoading(true);
    axiosInstance
      .post("customer/validate-sms", {
        phone: phone.current,
        code: code,
        customerId: customer.current.id,
      })
      .then((res) => {
        setLoading(false);
        navigate(REGISTER, {
          phoneValidated: phone.current,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          title: "Erro ao validar o sms",
          status: "error",
          placement: "top",
        });
      });
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Confirmação celular</TitlePage>
        <TextPage tiny style={{ marginBottom: 30 }}>
          Insira o código que enviamos para você por SMS
        </TextPage>
        <TextInputBase
          label={"Digite o código"}
          onChangeText={setCode}
          value={code}
          keyboardType="numeric"
        />
        <View style={{ marginTop: 30, marginBottom: 40 }}>
          <TextPage fontWeight="medium" style={{ textAlign: "center" }}>
            {secondsToTime(counter)}
          </TextPage>
          <ButtonBase
            disabled={!resend}
            tiny
            onPress={() => sendSms()}
            title="Não recebeu o código?"
            color={"link-grey"}
          />
        </View>
        <ButtonBase
          loading={loading}
          disabled={code === ""}
          title="CONFIMAR"
          onPress={() => validateSms()}
          color={"orange"}
        />
      </ContainerBase>
    </Box>
  );
};

export default RegisterPhoneConfirmation;
