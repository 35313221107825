import React, { useState, useEffect } from "react";
import { Box } from "native-base";
import { Image, TouchableOpacity } from "react-native";
import { TitlePage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { BENEFITS_DETAIL, LOGIN, REGISTER } from "../../constants/routes";
import { BenefitsButtons, BenefitsContainer, BenefitsImage } from "./styles";
import axiosInstance from "../../helpers/axiosInstance";
import { PlaceholderBenefits } from "./components";

const Benefits = ({ navigation }) => {
  const { navigate } = navigation;
  const [benefits, setBenefits] = useState();

  useEffect(() => {
    getBenefits();
  }, []);

  const getBenefits = () => {
    axiosInstance
      .get("benefits")
      .then((res) => {
        setBenefits(res.data);
      })
      .catch((err) => {
		  console.log(err);
	  });
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>Benefícios TimePay</TitlePage>
        <BenefitsButtons>
          <ButtonBase title="Pessoa física" color="orange" tiny />
          <ButtonBase
            style={{ marginLeft: 10 }}
            title="Mei"
            color="link"
            tiny
          />
          <ButtonBase
            style={{ marginLeft: 10 }}
            title="Empresas"
            color="link"
            tiny
          />
        </BenefitsButtons>

        {benefits ? (
          <BenefitsContainer>
            {benefits.map((item, index) => {
              return (
                <BenefitsImage
                  onPress={() => navigate(BENEFITS_DETAIL, { benefit: item })}
                  key={index}
                >
                  <Image
                    borderRadius={5}
                    alt={item.title}
                    style={{
                      resizeMode: "contain",
                      width: 160,
                      height: 160,
                    }}
                    source={{
                      uri: item.photo,
                    }}
                  />
                </BenefitsImage>
              );
            })}
          </BenefitsContainer>
        ) : (
          <PlaceholderBenefits />
        )}
        <ButtonBase
          title="ABRIR CONTA"
          color="orange"
          onPress={() => navigate(REGISTER)}
        />
        <ButtonBase
          title="JÁ TENHO CONTA"
          color="outline-black"
          onPress={() => navigate(LOGIN)}
          style={{ marginBottom: 50, marginTop: 10 }}
        />
      </ContainerBase>
    </Box>
  );
};

export default Benefits;
