import React, { useState, useEffect, useRef, useContext } from "react";
import { ActivityIndicator } from "react-native";
import { TextPage, TitlePage } from "../../styles/typograph";
import {
  ContainerBase,
  ContainerLoading,
  HistoryContent,
  HistoryExtra,
  HistoryIconContainer,
  HistoryItem,
} from "../../styles/default";
import { DASHBOARD } from "../../constants/routes";
import { TemplatePage } from "../../components/templates";
import axiosInstance from "../../helpers/axiosInstance";
import { Image, useToast } from "native-base";
import { getIcon, PlaceholderHistory } from "./components";
import { ButtonBase, ButtonFilter } from "../../components/buttons";
import BalanceBox from "../../components/balanceBox";
import {
  formatDateMonthYear,
  formatDateWeekDayMonth,
  formatDecimal,
  formatMoney,
} from "../../helpers/format";
import {
  HistoryContainer,
  HistoryDay,
  HistoryDayBalance,
  HistoryDayBox,
} from "./styles";
import { FontAwesome } from "@expo/vector-icons";
import { ButtonsGroup } from "../../styles/buttons";
import Filter from "./filter";
import GenerateVoucherButton from "../../components/generateVoucherButton";

const History = ({ navigation }) => {
  const { navigate } = navigation;
  const [historyList, setHistoryList] = useState([]);
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [endData, setEndData] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const historyListRef = useRef([]);
  const page = useRef(1);
  const toast = useToast();
  const firstTitleStyle = { marginBottom: 0 };
  const TitleStyle = { marginBottom: 0, marginTop: 25 };

  useEffect(() => {
    getHistory();
  }, [filter]);

  const getHistory = (next = false) => {
    if (next) {
      setLoadingPagination(true);
    } else {
      setHistoryList([]);
      historyListRef.current = [];
      setEndData(false);
      setLoading(true);
      page.current = 1;
    }
    axiosInstance
      .post("transaction/get?page=" + page.current, filter)
      .then((res) => {
        setLoading(false);
        setLoadingPagination(false);
        res.data.length < 15 && setEndData(true);
        historyListRef.current = [...historyListRef.current, ...res?.data];
        HistoryBuild(historyListRef.current);
        setHistoryList(historyListRef.current);
        page.current += 1;
      })
      .catch((err) => {
        setLoading(false);
        setLoadingPagination(false);
      });
  };

  const viewMore = () => {
    if (!endData && historyList.length >= 15) {
      return (
        <ButtonBase
          onPress={() => getHistory(true)}
          title="VER MAIS ..."
          color="link"
        />
      );
    }
  };

  const HistoryBuild = (data) => {
    let formatedDateWeek;
    let formatedDateWeekNext;

    for (let i = data.length - 1; i >= 0; i--) {
      formatedDateWeek = formatDateWeekDayMonth(data[i].created_at);
      formatedDateWeekNext = data[i - 1]?.created_at
        ? formatDateWeekDayMonth(data[i - 1].created_at)
        : formatDateWeekDayMonth(data[i].created_at);

      if (formatedDateWeek != formatedDateWeekNext) {
        data[i].balance_day = data[i].balance_after;
      }
    }
    data[0].balance_day = Number(data[0].balance_after);
  };

  const HistoryComponent = ({ item, index }) => {
    let formatedDate = formatDateMonthYear(item.created_at);
    let formatedDatePrevious =
      index != 0 ? formatDateMonthYear(historyList[index - 1].created_at) : "";

    let formatedDateWeek = formatDateWeekDayMonth(item.created_at);
    let formatedDateWeekPrevious =
      index != 0
        ? formatDateWeekDayMonth(historyList[index - 1].created_at)
        : "";

    return (
      <>
        {formatedDate != formatedDatePrevious && (
          <TitlePage style={index == 0 ? firstTitleStyle : TitleStyle}>
            {formatedDate}
          </TitlePage>
        )}
        {formatedDateWeek != formatedDateWeekPrevious && (
          <HistoryDayBox>
            <HistoryDay>
              <TextPage fontWeight="medium" tiny>
                {formatDateWeekDayMonth(item.created_at)}
              </TextPage>
            </HistoryDay>
            <HistoryDayBalance>
              <TextPage fontWeight="medium" tiny>
                {item.balance_day
                  ? "Saldo do dia: R$" + formatMoney(item.balance_day)
                  : "-"}
              </TextPage>
            </HistoryDayBalance>
          </HistoryDayBox>
        )}

        <HistoryItem>
          <HistoryIconContainer>
            <Image
              resizeMode="contain"
              alt="Icon pix"
              size={35}
              source={getIcon(item.transaction_type_id)}
            />
          </HistoryIconContainer>
          <HistoryContent>
            <TextPage fontWeight="medium">
              {item.transaction_type?.name}
            </TextPage>
            <TextPage tiny>{item.receiver ? item.receiver.name : "-"}</TextPage>
            {item.type == "D" ? (
              <TextPage color="red" tiny>
                {"-R$" + formatMoney(item.amount)}
              </TextPage>
            ) : (
              <TextPage color="green" tiny>
                {"R$" + formatMoney(item.amount)}
                {item.transaction_status_id == "6" ? " - Estornado" : ""}
              </TextPage>
            )}
          </HistoryContent>
          <HistoryExtra>
            <GenerateVoucherButton item={item} />
          </HistoryExtra>
        </HistoryItem>
      </>
    );
  };

  const pageContent = (
    <>
      <ContainerBase contentContainerStyle={{ paddingBottom: 25 }}>
        <TitlePage style={{ marginBottom: 25 }}>Extrato</TitlePage>
        <BalanceBox tinyBox />
        <ButtonsGroup style={{ marginTop: 15 }}>
          <ButtonFilter onPress={() => setShowFilter(true)}>
            <TextPage>
              <FontAwesome name="filter" size={24} color="black" /> Filtrar
            </TextPage>
          </ButtonFilter>
        </ButtonsGroup>

        <HistoryContainer>
          {!loading ? (
            historyList.length == 0 ? (
              <TextPage center>Não foi encontrado nenhuma transação</TextPage>
            ) : (
              <>
                {historyList.map((item, index) => {
                  return (
                    <HistoryComponent key={index} item={item} index={index} />
                  );
                })}
                <ContainerLoading>
                  {loadingPagination ? (
                    <ActivityIndicator color="#000" />
                  ) : (
                    viewMore()
                  )}
                </ContainerLoading>
              </>
            )
          ) : (
            <PlaceholderHistory />
          )}
        </HistoryContainer>
      </ContainerBase>
      <Filter
        modal={showFilter}
        setModal={setShowFilter}
        setFilter={setFilter}
      />
    </>
  );

  return (
    <TemplatePage
      navigation={navigation}
      content={pageContent}
      backNavigate={DASHBOARD}
      paddingResetMobile
      infiniteScroll={true}
    />
  );
};

export default History;
