import AsyncStorage from "@react-native-async-storage/async-storage";
import { LOGIN_LOADING, LOGOUT_USER } from "../constants/actionTypes";
import { API_BASE } from "@env";
import axios from "axios";

export default async (dispatch) => {
  const user = JSON.parse(await AsyncStorage.getItem("user"));
  const token = user?.access_token;
  dispatch({
    type: LOGIN_LOADING,
  });
  axios
    .post(
      API_BASE + "/user/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      await AsyncStorage.removeItem("user");
      dispatch({
        type: LOGOUT_USER,
      });
    })
    .catch(async (err) => {
      await AsyncStorage.removeItem("user");
      dispatch({
        type: LOGOUT_USER,
      });
    });
};
