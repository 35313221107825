import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { Text, View } from "react-native";
import { Camera } from "expo-camera";
import { CameraPreview } from "./photoPreview";
import { MaterialIcons } from "@expo/vector-icons";
import {
  PhotoArea,
  PhotoContainer,
  PhotoContent,
  PhotoFooter,
  PhotoBorders,
  PhotoType,
  ButtonContainer,
  PhotoHeader,
  ButtonHeaderContainer,
} from "./styles";
import { Box, useToast } from "native-base";
import { ButtonBack, ButtonPhoto } from "../../../components/buttons";
import { useNavigation } from "@react-navigation/core";
import {
  REGISTER_INSTRUCTIONS,
  REGISTER_INSTRUCTIONS_FACE,
} from "../../../constants/routes";
import { ButtonBaseContainer } from "../../../styles/buttons";
import { PhotoInstructions } from "./photoInstructions";
import axiosInstance from "../../../helpers/axiosInstance";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { manipulateAsync } from "expo-image-manipulator";

export default function RegisterPhoto() {
  const [hasPermission, setHasPermission] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [takePicture, setTakePicture] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
  const camera = useRef();
  const [flashMode, setFlashMode] = useState("off");
  const [typePhoto, setTypePhoto] = useState("CNH");
  const [photoInstructionsShow, setPhotoInstructionsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const customer = useRef();
  const photoCategory = useRef();
  const { navigate } = useNavigation();
  const toast = useToast();

  useEffect(() => {
    __startCamera();
    startPhotoInstructions();
    getData();
  }, []);

  const setDataOrder = (type, category) => {
    setTypePhoto(type);
    photoCategory.current = category;
    __retakePicture();
    startPhotoInstructions();
  };

  const nextStep = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    navigate(REGISTER_INSTRUCTIONS_FACE, { customer: customer.current });
  };

  const pjOrder = () => {
    if (customer.current.document_type === "cnh") {
      if (customer.current.document_cnh === undefined) {
        setDataOrder("CNH", 3);
      } else if (customer.current.document_extra === undefined) {
        setDataOrder("COMP", 2);
      } else {
        nextStep();
      }
    } else {
      if (customer.current.document_rg_front === undefined) {
        setDataOrder("RGF", 0);
      } else if (customer.current.document_rg_back === undefined) {
        setDataOrder("RGV", 7);
      } else if (customer.current.document_extra === undefined) {
        setDataOrder("COMP", 2);
      } else {
        nextStep();
      }
    }
  };

  const pfOrder = () => {
    if (customer.current.document_type === "cnh") {
      if (customer.current.document_cnh === undefined) {
        setDataOrder("CNH", 3);
      } else if (customer.current.document_extra === undefined) {
        setDataOrder("COMP", 2);
      } else {
        nextStep();
      }
    } else {
      if (customer.current.document_rg_front === undefined) {
        setDataOrder("RGF", 0);
      } else if (customer.current.document_rg_back === undefined) {
        setDataOrder("RGV", 7);
      } else if (customer.current.document_extra === undefined) {
        setDataOrder("COMP", 2);
      } else {
        nextStep();
      }
    }
  };

  const checkPhotoOrder = () => {
    customer.current.person_type === "pj" ? pjOrder() : pfOrder();
  };

  const setPhoto = (url) => {
    if (photoCategory.current === 0) {
      customer.current.document_rg_front = url;
    } else if (photoCategory.current === 3) {
      customer.current.document_cnh = url;
    } else if (photoCategory.current === 7) {
      customer.current.document_rg_back = url;
    } else if (photoCategory.current === 2 || photoCategory.current === 5) {
      customer.current.document_extra = url;
    }
    checkPhotoOrder();
  };

  const getTypePhotoTitle = (type) => {
    switch (type) {
      case "RGF":
        return "RG FRENTE";
      case "RGV":
        return "RG VERSO";
      case "COMP":
        return "COMPROVANTE";
      case "CNH":
        return "CNH";
      default:
        return "";
    }
  };

  const getData = async () => {
    customer.current = JSON.parse(await AsyncStorage.getItem("customer"));
    customer.current.person_type == "pf" ? pfOrder() : pjOrder();
  };

  const startPhotoInstructions = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setPhotoInstructionsShow(true);
  };

  const __startCamera = async () => {
    const { status } = await Camera.requestPermissionsAsync();

    setHasPermission(status === "granted");
  };

  const __takePicture = async () => {
    setTakePicture(true);
    const photo = await camera.current.takePictureAsync({
      base64: true,
    });

    setTakePicture(false);
    setPreviewVisible(true);
    setCapturedImage(photo);
  };

  const __savePhoto = async () => {
    setLoading(true);
    const photoResized = await manipulateAsync(
      capturedImage.uri,
      [{ resize: { height: 1100 } }],
      { base64: true }
    );

    axiosInstance
      .post("customer/documents", {
        customerId: customer.current.id,
        personType: customer.current.person_type,
        photoCategory: photoCategory.current,
        document: photoResized.base64,
      })
      .then((res) => {
        setLoading(false);
        setPhoto(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          description: err.message,
          placement: "top",
        });
      });
  };

  const __retakePicture = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    __startCamera();
  };
  const __handleFlashMode = () => {
    if (flashMode === "on") {
      setFlashMode("off");
    } else if (flashMode === "off") {
      setFlashMode("on");
    } else {
      setFlashMode("auto");
    }
  };
  const __switchCamera = () => {
    setCameraType(
      cameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  };

  const boxStyle = { flex: 1, width: "100%", backgroundColor: "transparent" };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <PhotoContainer>
      <PhotoArea>
        {previewVisible && capturedImage ? (
          <CameraPreview
            photo={capturedImage}
            savePhoto={__savePhoto}
            retakePicture={__retakePicture}
            loading={loading}
          />
        ) : (
          <Camera
            type={cameraType}
            flashMode={flashMode}
            style={{ flex: 1 }}
            ref={camera}
          >
            <Box style={boxStyle} safeArea>
              <PhotoHeader>
                <ButtonContainer>
                  <ButtonBack
                    onPress={() => navigate(REGISTER_INSTRUCTIONS)}
                    color="white"
                    size={24}
                  />
                </ButtonContainer>

                <ButtonHeaderContainer>
                  <ButtonBaseContainer
                    style={{ marginRight: 5 }}
                    onPress={__handleFlashMode}
                  >
                    {flashMode == "on" ? (
                      <MaterialIcons name="flash-on" size={24} color="white" />
                    ) : flashMode == "off" ? (
                      <MaterialIcons name="flash-off" size={24} color="white" />
                    ) : (
                      <MaterialIcons
                        name="flash-auto"
                        size={24}
                        color="white"
                      />
                    )}
                  </ButtonBaseContainer>
                  <ButtonBaseContainer onPress={__switchCamera}>
                    <MaterialIcons
                      name="camera-front"
                      size={24}
                      color="white"
                    />
                  </ButtonBaseContainer>
                </ButtonHeaderContainer>
              </PhotoHeader>
              <PhotoContent>
                <PhotoBorders></PhotoBorders>
              </PhotoContent>
              <PhotoFooter>
                <ButtonContainer>
                  <ButtonPhoto disabled={takePicture} onPress={__takePicture} />
                  <PhotoType>{getTypePhotoTitle(typePhoto)}</PhotoType>
                </ButtonContainer>
              </PhotoFooter>
              <PhotoInstructions
                modal={photoInstructionsShow}
                setModal={setPhotoInstructionsShow}
                typePhoto={typePhoto}
                getTypePhotoTitle={getTypePhotoTitle}
              />
            </Box>
          </Camera>
        )}
      </PhotoArea>
      <StatusBar style="auto" />
    </PhotoContainer>
  );
}
