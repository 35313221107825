import React from "react";
import {
  ChatBotBox,
  ChatBotContainer,
  ChatClientContainer,
  ChatClientBox,
  ChatEdit,
  InputChat,
  WrapChat,
  SendChat,
  TextBot,
  TextClient,
  DivisorMessage,
  DivisorIcon,
  TextEndMessage,
  ChatImageBox,
} from "./styles";
import { FadeInView } from "../../components/animations";
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import { ButtonBase } from "../../components/buttons";
import { REGISTER_TERM } from "../../constants/routes";
import { ActivityIndicator, Image, Text } from "react-native";
import { Feather } from "@expo/vector-icons";

export const ChatBot = ({ children, fadeTime }) => (
  <FadeInView fadeTime={fadeTime}>
    <ChatBotContainer>
      <ChatBotBox>{children}</ChatBotBox>
    </ChatBotContainer>
  </FadeInView>
);

export const ChatClient = ({ children, onPress, fadeTime, edit }) => (
  <FadeInView fadeTime={fadeTime}>
    <ChatClientContainer>
      {edit && (
        <ChatEdit onPress={onPress}>
          <MaterialCommunityIcons
            name="pencil-circle"
            size={20}
            color="white"
          />
        </ChatEdit>
      )}
      <ChatClientBox>{children}</ChatClientBox>
    </ChatClientContainer>
  </FadeInView>
);

export const ChatInput = ({
  onPress,
  onChangeText,
  value,
  placeholder,
  keyboardType,
  disabled,
  loading,
  maxLength,
}) => (
  <WrapChat>
    <InputChat
      placeholderTextColor="#FFF"
      placeholder={placeholder}
      onChangeText={onChangeText}
      value={value}
      keyboardType={keyboardType}
      editable={!loading}
      maxLength={maxLength}
    />
    {loading ? (
      <SendChat>
        <ActivityIndicator size="small" color="white" />
      </SendChat>
    ) : (
      <SendChat disabled={disabled} onPress={onPress}>
        <Ionicons name="send" size={16} color="white" />
      </SendChat>
    )}
  </WrapChat>
);

export const ChatItemComponent = ({ chatItem, navigate, setTermOpened }) => {
  return (
    <>
      {chatItem.type == "bot" && (
        <ChatBot fadeTime={chatItem.fade}>
          <TextBot>{chatItem.text}</TextBot>
        </ChatBot>
      )}

      {chatItem.type == "client" && (
        <ChatClient fadeTime={chatItem.fade}>
          <TextClient>{chatItem.text}</TextClient>
        </ChatClient>
      )}

      {chatItem.type == "term" && (
        <ChatBot fadeTime={chatItem.fade}>
          <TextBot>{chatItem.text}</TextBot>
          <ButtonBase
            style={{ marginTop: 10 }}
            onPress={() => {
              setTermOpened(true);
              navigate(REGISTER_TERM);
            }}
            title="ABRIR TERMOS DE USO"
            color="outline-orange"
          />
        </ChatBot>
      )}

      {chatItem.type == "documents" && (
        <ChatClient fadeTime={chatItem.fade}>
          <ChatImageBox>
            {chatItem.text.map((image, index) => {
              return (
                <Image
                  key={index}
                  style={{
                    resizeMode: "contain",
                    width: 75,
                    height: 125,
                    marginRight: 5,
                  }}
                  source={{
                    uri: image.url,
                  }}
                />
              );
            })}
          </ChatImageBox>
        </ChatClient>
      )}

      {chatItem.type == "end" && (
        <FadeInView fadeTime={1500}>
          <DivisorMessage>
            <DivisorIcon>
              <Feather name="check-circle" size={40} color="white" />
            </DivisorIcon>
          </DivisorMessage>
          <TextEndMessage>Obrigado pelo envio das informações</TextEndMessage>
          <TextClient style={{ marginBottom: 50 }}>
            Em breve você receberá o retorno da análise dos dados por e-mail ou
            SMS.
          </TextClient>
        </FadeInView>
      )}
    </>
  );
};
