import React from "react";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { View } from "react-native";
export const PlaceholderBenefits = () => (
  <>
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
        marginTop: 25,
      }}
    >
      <View style={{ width: 150 }}>
        <Placeholder Animation={Fade}>
          <PlaceholderLine
            style={{ height: 150, width: 150, borderRadius: 10 }}
          />
        </Placeholder>
      </View>
      <View style={{ width: 150 }}>
        <Placeholder Animation={Fade}>
          <PlaceholderLine
            style={{ height: 150, width: 150, borderRadius: 10 }}
          />
        </Placeholder>
      </View>
    </View>
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 50,
      }}
    >
      <View style={{ width: 150 }}>
        <Placeholder Animation={Fade}>
          <PlaceholderLine
            style={{ height: 150, width: 150, borderRadius: 10 }}
          />
        </Placeholder>
      </View>
      <View style={{ width: 150 }}>
        <Placeholder Animation={Fade}>
          <PlaceholderLine
            style={{ height: 150, width: 150, borderRadius: 10 }}
          />
        </Placeholder>
      </View>
    </View>
  </>
);
