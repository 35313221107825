export const cpfCnpjMask = (value) => {
  let v = value;

  v = v.replace(/\D/g, "");
  v =
    v.length > 11
      ? v
          .replace(/^(\d{2})(\d)/, "$1.$2")
          .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          .replace(/\.(\d{3})(\d)/, ".$1/$2")
          .replace(/(\d{4})(\d)/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1")
      : v
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2");

  return v;
};

export const cpfCnpjFilterMask = (value) => {
  let v = value;

  v = v.replace(/\.|-|\//g, "");
  v =
    v.length > 11
      ? v
          .replace(/^(\d{2})(\d)/, "$1.$2")
          .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          .replace(/\.(\d{3})(\d)/, ".$1/$2")
          .replace(/(\d{4})(\d)/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1")
      : v
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2");

  return v;
};

export const cpfMask = (value) => {
  let v = value;

  v = v.replace(/\D/g, "");
  v = v
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return v;
};

export const phoneMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1)$2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
};

export const cepMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{5})(\d)/, "$1-$2").replace(/(-\d{3})\d+?$/, "$1");
  return v;
};

export const rgMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return v;
};

export const barCodeMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v
    .replace(/(\d{11})(\d)/, "$1-$2")
    .replace(/(\d{11})-(\d)(\d)/, "$1-$2 $3")
    .replace(/(\d)\s(\d{11})(\d)/, "$1 $2-$3")
    .replace(/(\d)-(\d{1})(\d)/, "$1-$2 $3")
    .replace(/(\d)\s(\d{11})(\d)/, "$1 $2-$3")
    .replace(/(\d)-(\d{1})(\d)/, "$1-$2 $3")
    .replace(/(\d)\s(\d{11})(\d)/, "$1 $2-$3")
    .replace(/(-\d{1})\d+?$/, "$1");

  return v;
};

export const moneyMask = (value) => {
  let v = value;
  v = value.length > 10 ? v.substring(0, 10) : v;
  v = v
    .replace(/\D/g, "")
    .replace(/(\d)(\d{2})$/, "$1,$2")
    .replace(/(\d)(\d{3}),(\d{2})/, "$1.$2,$3");

  return v;
};

export const accountMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d)(\d{1})$/, "$1-$2");

  return v;
};

export const defaultMask = (value) => {
  return value;
};

export const maskRegex = (
  mask,
  name,
  value,
  setFieldValue,
  setFieldTouched
) => {
  let maskMethod;
  switch (mask) {
    case "cpf-cnpj":
      maskMethod = cpfCnpjMask;
      break;
    case "cpf":
      maskMethod = cpfMask;
      break;
    default:
      maskMethod = defaultMask;
  }
  setFieldValue(name, maskMethod(value));
  setFieldTouched(name, true, false);
};
