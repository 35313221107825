import React, { useState } from "react";
import { Box, useToast } from "native-base";
import { TextPage, TitleOrange, TitlePage } from "../../styles/typograph";
import { ContainerBase, HeaderBase } from "../../styles/default";
import { ButtonBack, ButtonBase } from "../../components/buttons";
import { FORGOT_PASSWORD_SUCCESS } from "../../constants/routes";
import { cpfCnpjMask } from "../../helpers/masks";
import { TextInputBase } from "../../components/inputs";
import axiosInstance from "../../helpers/axiosInstance";
import { clearCaracters } from "../../helpers/format";

const ForgotPassword = ({ navigation }) => {
  const { navigate } = navigation;
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const sendData = async () => {
    setLoading(true);

    axiosInstance
      .post("user/forgot-password", { cpf_cnpj: clearCaracters(cpfCnpj) })
      .then((res) => {
        setLoading(false);
        navigate(FORGOT_PASSWORD_SUCCESS);
      })
      .catch((err) => {
        setLoading(false);
        toast.show({
          placement: "top",
          description: err.message,
        });
      });
  };

  return (
    <Box style={{ flex: 1, backgroundColor: "#fff" }} safeArea>
      <HeaderBase>
        <ButtonBack onPress={navigation.goBack} color="black" size={24} />
      </HeaderBase>
      <ContainerBase>
        <TitlePage style={{ marginBottom: 30 }}>ESQUECI A SENHA</TitlePage>
        <TextPage style={{ marginBottom: 30 }}>
          Preencha o campo abaixo para recuperar sua senha de acesso.
        </TextPage>

        <TextInputBase
          name="cpfCnpj"
          label="CPF/CNPJ"
          keyboardType="numeric"
          onChangeText={(value) => {
            setCpfCnpj(cpfCnpjMask(value));
          }}
          value={cpfCnpj}
          bigText
        />

        <ButtonBase
          loading={loading}
          style={{ marginTop: 50, marginBottom: 25 }}
          title="RECUPERAR SENHA"
          color="outline-black"
          onPress={() => sendData()}
        />
      </ContainerBase>
    </Box>
  );
};

export default ForgotPassword;
