import { View } from "native-base";
import React from "react";

import { ModalBase } from "../../components/modals";
import { TextPage } from "../../styles/typograph";

const TransferAnotherBankAccountModal = ({ modal, setModal }) => {
  return (
    <ModalBase header="CONTA" modal={modal} setModal={setModal} type="bottom">
      <TextPage>Informe todos os dados da conta inclusive o dígito</TextPage>

      <View style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}>
        <TextPage center>
          <TextPage fontWeight="medium">Exemplo: </TextPage>
          conta 125415-22
        </TextPage>
        <TextPage center>
          você deve digitar
          <TextPage fontWeight="medium"> 12541522</TextPage>
        </TextPage>
      </View>
    </ModalBase>
  );
};

export default TransferAnotherBankAccountModal;
