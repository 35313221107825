import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Login from "../screens/Login";
import Register from "../screens/Register";
import RegisterTerm from "../screens/Register/term";
import RegisterInstructions from "../screens/Register/documents/instructions";
import RegisterPhoto from "../screens/Register/documents/photo";
import {
  REGISTER,
  LOGIN,
  REGISTER_TERM,
  REGISTER_INSTRUCTIONS,
  REGISTER_PHOTO,
  REGISTER_INSTRUCTIONS_FACE,
  REGISTER_PHOTO_FACE,
  REGISTER_PERSONAL_DATA,
  REGISTER_ADDRESS_DATA,
  REGISTER_PHONE_CONFIRMATION,
  REGISTER_PASSWORD,
  REGISTER_PASSWORD_CONFIRMATION,
  BENEFITS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  BENEFITS_DETAIL,
  ACCOUNT_LIST,
} from "../constants/routes";
import RegisterInstructionsFace from "../screens/Register/documents/instructionsFace";
import RegisterPhotoFace from "../screens/Register/documents/photoFace";
import RegisterPersonalData from "../screens/Register/documents/personalData";
import RegisterAddressData from "../screens/Register/adressData";
import RegisterPhoneConfirmation from "../screens/Register/phoneConfimation";
import RegisterPassword from "../screens/Register/password";
import RegisterPasswordConfirmation from "../screens/Register/passwordConfirmation";
import Benefits from "../screens/Benefits";
import BenefitsDetail from "../screens/Benefits/detail";

import ForgotPassword from "../screens/ForgotPassword";
import ForgotPasswordSuccess from "../screens/ForgotPassword/forgotPasswordSuccess";
import AccountList from "../screens/Login/accountList";

const AuthNavigator = () => {
  const AuthStack = createStackNavigator();
  return (
    <AuthStack.Navigator
      initialRouteName={LOGIN}
      screenOptions={{ headerShown: false }}
    >
      <AuthStack.Screen name={REGISTER} component={Register} />
      <AuthStack.Screen name={LOGIN} component={Login} />
      <AuthStack.Screen name={ACCOUNT_LIST} component={AccountList} />
      <AuthStack.Screen name={FORGOT_PASSWORD} component={ForgotPassword} />
      <AuthStack.Screen
        name={FORGOT_PASSWORD_SUCCESS}
        component={ForgotPasswordSuccess}
      />

      <AuthStack.Screen name={REGISTER_TERM} component={RegisterTerm} />
      <AuthStack.Screen name={REGISTER_PHOTO} component={RegisterPhoto} />
      <AuthStack.Screen
        name={REGISTER_INSTRUCTIONS}
        component={RegisterInstructions}
      />
      <AuthStack.Screen
        name={REGISTER_INSTRUCTIONS_FACE}
        component={RegisterInstructionsFace}
      />
      <AuthStack.Screen
        name={REGISTER_PHOTO_FACE}
        component={RegisterPhotoFace}
      />
      <AuthStack.Screen
        name={REGISTER_PERSONAL_DATA}
        component={RegisterPersonalData}
      />
      <AuthStack.Screen
        name={REGISTER_ADDRESS_DATA}
        component={RegisterAddressData}
      />
      <AuthStack.Screen
        name={REGISTER_PHONE_CONFIRMATION}
        component={RegisterPhoneConfirmation}
      />
      <AuthStack.Screen name={REGISTER_PASSWORD} component={RegisterPassword} />
      <AuthStack.Screen
        name={REGISTER_PASSWORD_CONFIRMATION}
        component={RegisterPasswordConfirmation}
      />
      <AuthStack.Screen name={BENEFITS} component={Benefits} />
      <AuthStack.Screen name={BENEFITS_DETAIL} component={BenefitsDetail} />
    </AuthStack.Navigator>
  );
};

export default AuthNavigator;
